import { SERVICES } from "Constants/Service.Constant";
import { action, thunk } from "easy-peasy";
import {
  createCategory,
  deleteOneCategory,
  getOneCategoryData,
  updateCategory,
} from "Services/Categories.Services";
import { getAwsUrl, sendFileToS3 } from "Services/Images.services";

import { Helpers } from "Store";
import { generatePath } from "Utils/GeneratePath.Util";
import { DEFAULT_PAGE_PARAMS } from "../ListCategories";
import { CategoryDetailModel, ICategoryState } from "./Type";

export const INITIAL_AUTH_STATE: ICategoryState = {
  loading: true,
  loadingImage: true,
  loadingSubmit: false,
  data: {
    name: "",
    status: "active",
    images_attributes: [],
    sub_categories_attributes: [],
    description: "",
  },
};

const detailCategory: CategoryDetailModel = {
  ...INITIAL_AUTH_STATE,

  setLoading: action((state, payload: boolean): void => {
    state.loading = payload;
  }),

  setLoadingImage: action((state, payload: boolean): void => {
    state.loadingImage = payload;
  }),

  setLoadingSubmit: action((state, payload: boolean): void => {
    state.loadingSubmit = payload;
  }),

  saveData: action((state, { data }): void => {
    state.data = data;
  }),

  reset: action((state, payload) => {
    return { ...payload, ...INITIAL_AUTH_STATE };
  }),

  getOneCategory: thunk(async (actions, { categoryId }, { getState }) => {
    const state = getState();
    if (!state.loading) {
      actions.setLoading(true);
    }
    const { data, success } = await getOneCategoryData(categoryId);

    if (success) {
      actions.saveData({ data });
      actions.setLoading(false);
    } else {
      actions.setLoading(false);
    }
    return { success };
  }),

  createCategory: thunk(async (actions, _payload, { getState }) => {
    const state = getState();
    const newCategory = _payload;
    if (!state.loadingSubmit) {
      actions.setLoadingSubmit(true);
    }
    const { success, error } = await createCategory(newCategory);
    if (success) {
      actions.setLoadingSubmit(false);
    } else {
      actions.setLoadingSubmit(false);
    }
    return { success, error };
  }),

  updateCategory: thunk(
    async (actions, { categoryId, values }, { getState }) => {
      const state = getState();
      if (!state.loadingSubmit) {
        actions.setLoadingSubmit(true);
      }
      const { success, error } = await updateCategory(categoryId, values);
      if (success) {
        actions.setLoadingSubmit(false);
      } else {
        actions.setLoadingSubmit(false);
      }
      return { success, error };
    }
  ),

  deleteCategory: thunk(
    async (
      actions,
      { categoryId },
      { getState, getStoreActions }: Helpers<CategoryDetailModel, any>
    ) => {
      const action = getStoreActions();
      const state = getState();
      if (!state.loadingSubmit) {
        actions.setLoadingSubmit(true);
      }
      const { success } = await deleteOneCategory(categoryId);
      if (success) {
        action.categories.listCategories.getCategoriesList({
          ...DEFAULT_PAGE_PARAMS,
        });
        actions.setLoadingSubmit(false);
      } else {
        actions.setLoadingSubmit(false);
      }
      return { success };
    }
  ),

  uploadImageCategory: thunk(async (actions, file, { getState }) => {
    const state = getState();
    if (!state.loadingImage) {
      actions.setLoadingImage(true);
    }
    const response = await getAwsUrl(
      generatePath(SERVICES.GET_AWS_URL, { folder: "categories" })
    );
    const { success, data } = response;
    if (success) {
      const res = await sendFileToS3(
        file.originFileObj,
        data.aws_signedRequestURL,
        data.object_key
      );
      const { success, object_key } = res;
      if (success) {
        return { success, object_key };
      } else {
        actions.setLoadingImage(false);
      }
    } else {
      actions.setLoadingImage(false);
    }
  }),
};

export default detailCategory;
