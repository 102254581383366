import React, { useEffect } from "react";
import { ErrorMessage, FormikHelpers } from "formik";

import SelectComponent from "CoreUI/Components/Common/Select";
import { useStoreActions, useStoreState } from "Store";
import { IDetailValues } from "Types/Product";
import { i18NextText } from "Utils/I18Next.Util";

interface IFieldBrand {
  values: IDetailValues;
  setFieldValue: FormikHelpers<IDetailValues>["setFieldValue"];
}

const FieldBrand = ({ values, setFieldValue }: IFieldBrand) => {
  const listBrands = useStoreState((state) => state.brands.listBrands.data);
  const getListBrands = useStoreActions(
    (action) => action.brands.listBrands.getBrandsList
  );

  useEffect(() => {
    const fetchData = async () => {
      await getListBrands({});
    };
    fetchData();
  }, [getListBrands]);

  const options = listBrands.map((brand) => ({
    label: brand.name,
    value: brand.id,
  }));

  return (
    <div className="flex flex-col gap-2">
      <label
        style={{
          color: "var(--secondary_text)",
          fontWeight: "var(--fw_semibold)",
        }}
      >
        {i18NextText("product.form_label_brand")}
      </label>
      <SelectComponent
        value={values.brand_id === 0 ? "" : values.brand_id}
        options={options}
        onChange={(value) => setFieldValue("brand_id", value)}
      />

      <p style={{ color: "var(--red_5)" }}>
        <ErrorMessage name="brand_id" />
      </p>
    </div>
  );
};

export default FieldBrand;
