const merchant = {
  merchants: "Merchants",
  btn_add_merchant: "Add Merchant",
  btn_filter: "Filter",
  column_name: "NAME",
  column_email: "EMAIL",
  column_domain: "DOMAIN",
  column_limit_rate: "LIMIT RATE",
  column_active: "ACTIVE",
  form_label_name: "Merchant Name",
  form_label_email: "Email",
  form_label_domain: "Domain",
  form_label_limit_rate: "Limit_rate",
  form_label_plan: "Plan",
  form_label_active: "Active",
  form_placeholder_name: "Enter name of merchant",
  form_placeholder_email: "Enter email",
  Title_form_edit: "Edit Merchant",
  btn_update: "UPDATE",
  Title_form_create: "Create Merchant",
  btn_create: "CREATE",
  validator_name: "Merchant's name is required",
  validator_not_email: "Please enter an email address",
  validator_email: "Email is required",
  validator_domain: " Domain is required",
  validator_limit_rate: "Limit rate is required",
  validator_plan: "Please enter the plan",
  validator_number: "Please enter the number",
  validator_url: "Please enter URL link",
};

export default merchant;
