import { Select, SelectProps } from "antd";

import { Image } from "antd";
import ArrowDownIcon from "Assets/Images/SVG/arrow_down.svg";

interface ISelect extends SelectProps<any> {
  children?: React.ReactNode;
}
const { Option } = Select;

const SelectComponent: React.FC<ISelect> = ({ children, ...restParams }) => {
  return (
    <Select
      suffixIcon={
        <Image
          src={ArrowDownIcon}
          preview={false}
          width={10}
          height={6}
          alt="arrow"
        />
      }
      {...restParams}
    >
      {children}
    </Select>
  );
};

export { Option };

export default SelectComponent;
