import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useStoreActions } from "Store";
import { _get } from "Utils/Lodash.Util";
import { notification } from "Utils/Notification.Util";

import HeaderPageOrder from "./HeaderPageOrder";
import InfoProductOrdered from "./InfoProductOrdered";
import InfoShipping from "./InfoShipping";

const OrderDetailPage = () => {
  const params = useParams();
  const orderId = Number(_get(params, "order_id"));
  const getOrderDetail = useStoreActions(
    (action) => action.orders.detailOrder.getOrderDetail
  );

  useEffect(() => {
    const fetchData = async () => {
      const response = await getOrderDetail({ orderId });
      if (!response.success) {
        notification.error({
          message: "",
          description: response.error,
        });
      }
    };
    fetchData();
  }, [orderId, getOrderDetail]);
  return (
    <>
      <HeaderPageOrder />
      <InfoShipping />
      <InfoProductOrdered />
    </>
  );
};

export default OrderDetailPage;
