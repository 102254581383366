import { LOCAL_STORAGE } from "Constants/LocalStorage.Constant";
import { action, thunk } from "easy-peasy";
import { getNewPassword, getTokenAdmin } from "Services/Auth.Services";
import { clearItems, setItem } from "Utils/LocalStorage.Util";
import { AuthModel } from "./Type";

export const INITIAL_AUTH_STATE = {
  loading: false,
  id: "",
  name: "",
  onboarded: false,
  token: "",
  email: "",
};

const auth: AuthModel = {
  ...INITIAL_AUTH_STATE,

  setLoading: action((state, payload: boolean): void => {
    state.loading = payload;
  }),
  saveLoginData: action((state, payload): void => {
    state.token = payload.token;
    state.loading = false;
  }),
  reset: action((_state, payload) => {
    return { ...INITIAL_AUTH_STATE, payload };
  }),

  login: thunk(async (actions, { email, password }, { getState }) => {
    const state = getState();
    if (!state.loading) {
      actions.setLoading(true);
    }
    const { token, success, error } = await getTokenAdmin({ email, password });
    if (success) {
      setItem(LOCAL_STORAGE.TOKEN, token);
      actions.saveLoginData({
        token,
      });
    } else {
      actions.setLoading(false);
    }
    return { success, error };
  }),

  logout: action((state): void => {
    state.loading = true;
    clearItems();
    state.loading = false;
  }),

  forgotPass: thunk(async (actions, { email }, { getState }) => {
    const state = getState();
    if (!state.loading) {
      actions.setLoading(true);
    }
    const { success, error, message } = await getNewPassword({ email });
    actions.setLoading(false);
    return { success, error, message };
  }),
};

export default auth;
