import { useEffect } from "react";
import { generatePath, useNavigate } from "react-router-dom";

import { useStoreActions } from "Store";
import { Icons } from "Components/Lib";
import { PATH_NAME } from "Constants/PathName.Constant";
import { i18NextText } from "Utils/I18Next.Util";
import ButtonComponent from "CoreUI/Components/Common/Button";
import { notification } from "Utils/Notification.Util";

import TableProductsList from "./TableProductsList";

const ProductsPage = () => {
  const navigate = useNavigate();

  const getData = useStoreActions(
    (action) => action.products.list.getProductsList
  );
  const getCategory = useStoreActions(
    (action) => action.categories.listCategories.getCategoriesList
  );

  useEffect(() => {
    const fetchdata = async () => {
      const response = await getData({});
      await getCategory({});
      if (!response.success) {
        notification.error({
          message: "",
          description: i18NextText("error.notification_error"),
        });
      }
    };
    fetchdata();
  }, [getData, getCategory]);

  const handleOnClickAddProduct = () => {
    navigate(
      generatePath(PATH_NAME.PRODUCT_DETAIL, {
        product_id: "new",
      })
    );
  };

  return (
    <>
      <div
        className="flex justify-between"
        style={{ padding: "var(--sp_lv_3) var(--sp_lv_3)" }}
      >
        <h1 className="font-semibold">{i18NextText("product.products")}</h1>
        <div className="flex gap-4">
          <ButtonComponent
            type="primary"
            icon={<Icons.PlusOutlined />}
            onClick={handleOnClickAddProduct}
          >
            {i18NextText("product.btn_add_product")}
          </ButtonComponent>
        </div>
      </div>
      <div>
        <TableProductsList />
      </div>
    </>
  );
};

export default ProductsPage;
