const brand = {
  brands: "Brands",
  title_table: "Brand List",
  btn_add_brand: "Add Brand",
  column_name: "BRAND",
  column_status: "ACTIVE",
  title_form_edit: "Edit brand",
  title_form_create: "Create brand",
  btn_update: "Update",
  btn_create: "Create",
  images: "Images",
  // FORM
  field_status_brand: "Status brand",
  field_name_brand: "Brand name",
  form_placeholder_name: "Enter brand name",
  images_brand: "Images",
  images_description: "Accept images with .PNG, .JPG, .JPEG",

  // Schema
  validator_name: "brand name is required",
};

export default brand;
