import { Switch } from "Components/Lib";
import { useStoreActions, useStoreState } from "Store";

const ChangeStatusHomeDisplay = ({
  status,
  id_review,
}: {
  status: string;
  id_review: number;
}) => {
  const loadingEdit = useStoreState(
    (action) => action.reviews.reviewsList.loadingEdit
  );

  const changeStatusReview = useStoreActions(
    (action) => action.reviews.reviewsList.changeStatusReview
  );

  const handleChangeStatus = async (value) => {
    changeStatusReview({
      id: id_review,
      status: value ? "home_display" : "active",
    });
  };

  return (
    <Switch
      checked={status === "home_display" ? true : false}
      onChange={handleChangeStatus}
      loading={loadingEdit}
    />
  );
};

export default ChangeStatusHomeDisplay;
