import { ErrorMessage, FormikHelpers } from "formik";

import SelectComponent from "CoreUI/Components/Common/Select";
import { IDetailValues } from "Types/Product";
import { i18NextText } from "Utils/I18Next.Util";

interface IProductStatus {
  values: IDetailValues;
  setFieldValue: FormikHelpers<IDetailValues>["setFieldValue"];
}

const ProductStatus = ({ values, setFieldValue }: IProductStatus) => {
  return (
    <div
      className="flex flex-col gap-5"
      style={{
        background: "var(--gray_1)",
        borderRadius: "var(--br_lg)",
        padding: "var(--sp_lv_6)",
      }}
    >
      <h2>{i18NextText("product.product_status")}</h2>
      <SelectComponent
        value={values.status}
        options={[
          { label: i18NextText("product.active"), value: "active" },
          { label: i18NextText("product.draft"), value: "draft" },
          { label: i18NextText("product.deactivate"), value: "deactivate" },
        ]}
        onChange={(value) => setFieldValue("status", value)}
      />

      <p style={{ color: "var(--red_5)" }}>
        <ErrorMessage name="status" />
      </p>
    </div>
  );
};

export default ProductStatus;
