import { useStoreState, useStoreActions } from "Store";
import { notification } from "Utils/Notification.Util";
import DataTable from "CoreUI/Components/Common/DataTable";
import { i18NextText } from "Utils/I18Next.Util";
import { Tag } from "Components/Lib";

const TableMerchantList = () => {
  const data = useStoreState((state) => state.merchants.list.data);
  const pagination = useStoreState((state) => state.merchants.list.pagination);
  const loading = useStoreState((state) => state.merchants.list.loading);

  const getMerchantsList = useStoreActions(
    (state) => state.merchants.list.getMerchantsList
  );
  const columns = [
    {
      title: `${i18NextText("merchant.column_name")}`,
      dataIndex: "name",
      width: 200,
    },
    {
      title: `${i18NextText("merchant.column_email")}`,
      dataIndex: "email",
      width: 200,
    },

    {
      title: `${i18NextText("merchant.column_domain")}`,
      dataIndex: "domain",
    },
    {
      title: `${i18NextText("merchant.column_limit_rate")}`,
      dataIndex: "limit_rate",
    },
    {
      title: `${i18NextText("merchant.column_active")}`,
      dataIndex: "active",
      render: (text, record) => {
        return (
          <Tag color={record.active ? "var(--blue_4)" : "var(--red_4)"}>
            {record.active ? "Active" : "Deactivate"}
          </Tag>
        );
      },
    },
  ];

  const onPageChange = async (tablePagination) => {
    const res = await getMerchantsList({ page: tablePagination.current });
    if (!res.success) {
      notification.error({
        message: "",
        description: i18NextText("error.notification_error"),
      });
    }
  };

  return (
    <div style={{ padding: "var(--sp_lv_8)" }}>
      <DataTable
        data={data}
        columns={columns}
        rowKey={(record: any) => {
          return record.id;
        }}
        loading={loading}
        pagination={{
          current: pagination.page,
          pageSize: pagination.per_page,
          total: pagination.total_records,
        }}
        onChange={onPageChange}
      />
    </div>
  );
};

export default TableMerchantList;
