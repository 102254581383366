import { i18NextText } from "Utils/I18Next.Util";
import ImageComponent from "CoreUI/Components/Common/Image";
import { _toLower, _trim, _upperFirst } from "Utils/Lodash.Util";

import DeleteButton from "./DeleteButton";
import EditButton from "./EditButton";
import StatusCategory from "./StatusCategory";
import SelectPriority from "./SelectPriortity";

const columnsCategory = [
  {
    title: "ID",
    dataIndex: "id",
    width: 50,
    render: (text: any) => text,
    align: "center",
  },
  {
    title: i18NextText("category.column_name"),
    dataIndex: "images_attributes",
    width: 180,
    render: (text, record) => {
      return (
        <div className="flex justify-start items-center gap-5">
          <ImageComponent
            src={record.images_attributes[0]?.public_url || ""}
            style={{
              borderRadius: "var(--br_DEFAULT)",
              width: "var(--sp_lv_14)",
              height: "var(--sp_lv_14)",
              minWidth: "var(--sp_lv_14)",
              marginRight: 20,
            }}
            preview={false}
          />
          <p>{record.name}</p>
        </div>
      );
    },
  },
  {
    title: i18NextText("category.column_sub_category"),
    dataIndex: "sub_categories_attributes",
    width: 150,
    render: (text: any) => {
      const subCategoryName = text?.map(({ name }) =>
        _upperFirst(_toLower(_trim(name)))
      );
      return subCategoryName.join(", ");
    },
  },
  {
    title: i18NextText("category.column_status"),
    dataIndex: "",
    width: 80,
    align: "center",
    render: (text, record) => {
      return <StatusCategory detailCategory={record} />;
    },
  },
  {
    title: i18NextText("category.column_priority"),
    dataIndex: "priority",
    width: 80,
    align: "center",
    render: (text, record) => {
      return <SelectPriority detailCategory={record} />;
    },
  },
  {
    title: "",
    dataIndex: "",
    width: 60,
    align: "right",
    render: (text, record) => {
      return <EditButton id={record.id} />;
    },
  },
  {
    title: "",
    dataIndex: "",
    width: 60,
    align: "right",
    render: (text, record) => {
      return <DeleteButton id={record.id} />;
    },
  },
];

export default columnsCategory;
