import { action, thunk } from "easy-peasy";

import { getCountOrderNew, getOrdersList } from "Services/Order.Services";

import { OrdersListModel } from "./Type";

export const DEFAULT_FILTER_PARAMS = {
  status: "",
  keyword: "",
  sort: "",
};

export const DEFAULT_PAGE_PARAMS = {
  page: 1,
  per_page: 9,
  total_records: 0,
};

export const INITIAL_ORDER_STATE = {
  loading: true,
  loadingCountOrderNew: true,
  data: [],
  count: 0,
  filterParams: DEFAULT_FILTER_PARAMS,
  pagination: DEFAULT_PAGE_PARAMS,
};

const ordersList: OrdersListModel = {
  ...INITIAL_ORDER_STATE,

  setLoading: action((state, payload: boolean): void => {
    state.loading = payload;
  }),

  setLoadingCountOrderNew: action((state, payload: boolean): void => {
    state.loadingCountOrderNew = payload;
  }),

  saveData: action((state, { data, params }): void => {
    const { status, keyword, sort, page, per_page, total_records } = params;
    state.data = data;
    state.pagination = { ...state.pagination, page, per_page, total_records };
    state.filterParams = { ...state.filterParams, status, keyword, sort };
    state.loading = false;
  }),

  saveDataCountNewOrder: action((state, { data }): void => {
    state.count = data;
    state.loadingCountOrderNew = false;
  }),

  getOrdersList: thunk(async (actions, { page, filter }, { getState }) => {
    const state = getState();
    if (!state.loading) actions.setLoading(true);
    state.pagination = { ...state.pagination };
    state.filterParams = { ...state.filterParams };
    const params = {
      ...state.pagination,
      ...state.filterParams,
      page: page ? page : 1,
      ...filter,
    };
    const { data, success, pagination } = await getOrdersList(params);
    if (success) {
      actions.saveData({ data, params: { ...params, ...pagination } });
    } else {
      actions.setLoading(false);
    }
    return { success };
  }),

  countNewOrder: thunk(async (actions, _payload, { getState }) => {
    const state = getState();
    if (!state.loadingCountOrderNew) actions.setLoadingCountOrderNew(true);
    const { data, success, error } = await getCountOrderNew();
    if (success) {
      actions.saveDataCountNewOrder({ data });
    } else {
      actions.setLoadingCountOrderNew(false);
    }
    return { success, error };
  }),
};

export default ordersList;
