import { useEffect, useState } from "react";
import { ErrorMessage, Form, Formik, FormikProps } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import classNames from "classnames";

import { useStoreActions, useStoreState } from "Store";
import { _get } from "Utils/Lodash.Util";
import { Icons, Skeleton, Divider, Switch } from "Components/Lib";
import { i18NextText } from "Utils/I18Next.Util";
import { IDetailBrand } from "Types/Brands";
import { PATH_NAME } from "Constants/PathName.Constant";
import ButtonComponent from "CoreUI/Components/Common/Button";
import InputField from "CoreUI/Components/Common/InputField";
import { notification } from "Utils/Notification.Util";

import BrandSchema from "./BrandSchema";
import ImagesBrand from "./ImagesBrand";

const BrandForm = () => {
  const params = useParams();
  const navigate = useNavigate();
  const brandId = _get(params, "brand_id");
  const loading = useStoreState((state) => state.brands.detailBrand.loading);
  const data = useStoreState((state) => state.brands.detailBrand.data);

  const reset = useStoreActions((action) => action.brands.detailBrand.reset);
  const getOneBrand = useStoreActions(
    (action) => action.brands.detailBrand.getOneBrand
  );
  const createBrand = useStoreActions(
    (action) => action.brands.detailBrand.createBrand
  );
  const uploadImage = useStoreActions(
    (action) => action.brands.detailBrand.uploadImageBrand
  );
  const updateBrand = useStoreActions(
    (action) => action.brands.detailBrand.updateBrand
  );

  useEffect(() => {
    if (brandId === "new") return;

    const fetchData = async () => {
      const response = await getOneBrand({ brandId });
      if (!response.success) {
        notification.error({
          message: "",
          description: i18NextText("error.notification_error"),
        });
      }
    };
    fetchData();
  }, [brandId, getOneBrand, reset]);

  useEffect(() => {
    return () => reset({});
  }, [reset]);

  const initialValues = {
    name: "",
    status: "active",
    ...data,
    images_attributes: _get(data, "images_attributes", []).map((image) => ({
      ...image,
      uid: image.id.toString(),
      name: "",
      status: "done",
      url: image.public_url,
      isValid: true,
      isUploaded: true,
    })),
  };

  const [loadingOnSubmit, setLoadingOnSubmit] = useState(false);

  const handleCreateBrand = async (values: any) => {
    setLoadingOnSubmit(true);
    const images = await handleUploadImages(values.images_attributes);

    const res = await createBrand({
      ...values,
      images_attributes: images,
    });
    if (res.success) {
      navigate(PATH_NAME.BRANDS);
    } else {
      notification.error({
        message: "",
        description: i18NextText("error.notification_error"),
      });
    }
    setLoadingOnSubmit(false);
  };

  const handleUpdateBrand = async (values) => {
    setLoadingOnSubmit(true);
    const images = await handleUploadImages(values.images_attributes);

    const res = await updateBrand({
      brandId,
      values: {
        ...values,
        images_attributes: images,
      },
    });
    if (res.success) {
      navigate(PATH_NAME.BRANDS);
    } else {
      notification.error({
        message: "",
        description: i18NextText("error.notification_error"),
      });
    }
    setLoadingOnSubmit(false);
  };

  const handleUploadImages = async (images_attributes) => {
    const uploadedImages = images_attributes.filter(
      (image) => image.isUploaded
    );
    const listUploadFileImages = images_attributes.filter(
      (image) => !image.isUploaded
    );

    const response = await Promise.all(
      listUploadFileImages.map((file) => {
        try {
          const res = uploadImage(file);
          return res;
        } catch (error) {
          return "Upload Fail";
        }
      })
    );
    if (!response.includes("Upload Fail")) {
      const images = [...uploadedImages, ...response].map(
        (image: any, index: number) => {
          if (image.id) {
            return {
              id: image.id,
              object_key: image.object_key,
              position: index + 1,
            };
          }
          return { object_key: image.object_key, position: index + 1 };
        }
      );
      return images;
    } else {
      return [];
    }
  };

  if (brandId !== "new" && loading) return <Skeleton />;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={BrandSchema}
      onSubmit={async (values: IDetailBrand) => {
        if (brandId !== "new") {
          await handleUpdateBrand(values);
        } else {
          await handleCreateBrand(values);
        }
      }}
    >
      {(props: FormikProps<IDetailBrand>) => {
        const { values, setFieldValue, dirty } = props;
        return (
          <Form
            className={classNames("flex flex-col gap-4")}
            style={{
              margin: "auto",
              borderRadius: "var(--sp_lv_1)",
              maxWidth: "970px",
            }}
          >
            <div
              className="flex justify-between bg-white"
              style={{ padding: "var(--sp_lv_8)" }}
            >
              <h1 className="font-semibold">
                {brandId !== "new"
                  ? i18NextText("brand.title_form_edit")
                  : i18NextText("brand.title_form_create")}
              </h1>
              <div className="flex gap-4">
                <ButtonComponent
                  type="primary"
                  htmlType="submit"
                  icon={<Icons.PlusOutlined />}
                  disabled={!dirty}
                  loading={loadingOnSubmit}
                >
                  {brandId !== "new"
                    ? i18NextText("brand.btn_update")
                    : i18NextText("brand.btn_create")}
                </ButtonComponent>
              </div>
            </div>

            <div
              className="flex flex-col justify-between gap-5"
              style={{
                background: "var(--gray_1)",
                borderRadius: "var(--br_lg)",
                padding: "var(--sp_lv_6)",
              }}
            >
              <div className="flex gap-5 items-center">
                <h2>{i18NextText("brand.field_status_brand")}</h2>
                <Switch
                  checked={values.status == "active" ? true : false}
                  onChange={(value) =>
                    setFieldValue("status", value ? "active" : "deactivate")
                  }
                />
              </div>
              <div className="flex flex-col gap-5">
                <h2>{i18NextText("brand.field_name_brand")}</h2>
                <InputField
                  name="name"
                  value={values.name}
                  type="text"
                  placeholder={i18NextText("brand.form_placeholder_name")}
                  onChange={(e) => setFieldValue("name", e.target.value)}
                />
                <p style={{ color: "var(--red_5}" }}>
                  <ErrorMessage name="name" />
                </p>
              </div>
              <ImagesBrand values={values} setFieldValue={setFieldValue} />
            </div>
            <Divider />
          </Form>
        );
      }}
    </Formik>
  );
};
export default BrandForm;
