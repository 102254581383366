import classNames from "classnames";
import { ErrorMessage, FormikHelpers } from "formik";

import InputNumber from "CoreUI/Components/Common/InputNumber";
import InputField from "CoreUI/Components/Common/InputField";
import { i18NextText } from "Utils/I18Next.Util";
import { IDetailValues, IVariantsCreate } from "Types/Product";

import classes from "./Inventory.module.scss";

interface IInventory {
  values: IDetailValues;
  setFieldValue: FormikHelpers<IDetailValues>["setFieldValue"];
}
const Inventory = ({ values, setFieldValue }: IInventory) => {
  const inventoryOfStock =
    (!!values.variants.length &&
      values.variants
        .map((variant: IVariantsCreate) => variant.quantity)
        .reduce((previous, current) => previous + current)) ||
    0;
  return (
    <div
      className={classNames(
        "flex flex-col gap-4",
        classes["inventory_container"]
      )}
    >
      <h2>{i18NextText("product.inventory")}</h2>
      <div className="flex flex-col gap-2">
        <label
          style={{
            color: "var(--secondary_text)",
            fontWeight: "var(--fw_semibold)",
          }}
        >
          {i18NextText("product.form_label_SKU")}
        </label>

        <InputField
          value={values.variants.length ? "" : values.sku}
          name="sku"
          onChange={(e) => setFieldValue("sku", e.target.value)}
          disabled={values.variants.length ? true : false}
        />
        <p className={classes["error_message"]}>
          <ErrorMessage name="sku" />
        </p>
      </div>
      <div className="flex flex-col gap-2">
        <label
          style={{
            color: "var(--secondary_text)",
            fontWeight: "var(--fw_semibold)",
          }}
        >
          {i18NextText("product.form_label_quantity")}
        </label>

        <InputNumber
          value={
            values.variants.length
              ? inventoryOfStock
              : values.quantity == 0
              ? null
              : values.quantity
          }
          name="quantity"
          placeholder="0"
          onChange={(value: number) => setFieldValue("quantity", value)}
          className={classes["input_number"]}
          disabled={values.variants.length ? true : false}
        />
        <p className={classes["error_message"]}>
          <ErrorMessage name="quantity" />
        </p>
      </div>
    </div>
  );
};

export default Inventory;
