import { useEffect } from "react";
import { ErrorMessage, Form, Formik, FormikProps } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import classNames from "classnames";

import { _get } from "Utils/Lodash.Util";
import { useStoreActions, useStoreState } from "Store";
import { Icons, Skeleton, Col, Row, Switch } from "Components/Lib";
import { i18NextText } from "Utils/I18Next.Util";
import { PATH_NAME } from "Constants/PathName.Constant";
import ButtonComponent from "CoreUI/Components/Common/Button";
import SelectComponent from "CoreUI/Components/Common/Select";
import InputField from "CoreUI/Components/Common/InputField";
import { IDetailValues } from "Types/Customer";
import { notification } from "Utils/Notification.Util";

import CustomerSchema from "./CustomerSchema";
import classes from "./FormCustomer.module.scss";

const UpdateCustomerPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const customerId = _get(params, "customer_id");
  const loading = useStoreState((state) => state.customers.detail.loading);
  const data = customerId
    ? useStoreState((state) => state.customers.detail.data)
    : null;
  const getOneCustomer = useStoreActions(
    (action) => action.customers.detail.getOneCustomer
  );

  const updateCustomerInfo = useStoreActions(
    (action) => action.customers.detail.updateCustomerInfo
  );

  useEffect(() => {
    if (!customerId) return;
    const fetchdata = async () => {
      const response = await getOneCustomer({ customerId });
      if (!response.success) {
        notification.error({
          message: "",
          description: i18NextText("error.notification_error"),
        });
      }
    };
    fetchdata();
  }, [customerId, getOneCustomer]);

  const initialValues = {
    first_name: "",
    last_name: "",
    gender: null,
    address: "",
    email: "",
    active: null,
    ...data,
  };

  const handleUpdateCustomersInfo = async (values) => {
    const response = await updateCustomerInfo({ customerId, values });
    if (response.success) {
      navigate(PATH_NAME.CUSTOMERS);
    }
  };
  if (customerId && loading) return <Skeleton />;
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={CustomerSchema}
      onSubmit={(values: IDetailValues) => {
        handleUpdateCustomersInfo(values);
      }}
    >
      {(props: FormikProps<IDetailValues>) => (
        <>
          <Form
            className={classNames(
              "flex flex-col gap-4",
              classes["form-customer-container"]
            )}
          >
            <div
              className="flex justify-between"
              style={{ padding: "var(--sp_lv_8)" }}
            >
              <h1 className="font-semibold">
                {i18NextText("customers.title_form")}
              </h1>
              <div className="flex gap-4">
                <ButtonComponent
                  type="primary"
                  htmlType="submit"
                  icon={<Icons.PlusOutlined />}
                  disabled={!props.dirty}
                >
                  {i18NextText("customers.btn_update")}
                </ButtonComponent>
              </div>
            </div>
            <div
              className="flex flex-col gap-6"
              style={{ padding: "var(--sp_lv_8)" }}
            >
              <Row gutter={16} className="flex justify-between">
                <Col span={12} className="flex flex-col gap-4">
                  <label>{i18NextText("customers.label_first_name")}</label>
                  <InputField
                    defaultValue={props.values.first_name}
                    type="text"
                    name="first_name"
                    placeholder="Enter first_name"
                    onChange={props.handleChange}
                  />
                  <p className={classNames(classes["error_message"])}>
                    <ErrorMessage name="first_name" />
                  </p>
                </Col>
                <Col span={12} className="flex flex-col gap-4">
                  <label>{i18NextText("customers.label_last_name")}</label>
                  <InputField
                    defaultValue={props.values.last_name}
                    type="text"
                    name="last_name"
                    placeholder="Enter last_name"
                    onChange={props.handleChange}
                  />
                  <p className={classNames(classes["error_message"])}>
                    <ErrorMessage name="last_name" />
                  </p>
                </Col>
              </Row>
              <Row gutter={16} className="flex justify-between">
                <Col span={12} className="flex flex-col gap-4">
                  <label>{i18NextText("customers.label_gender")}</label>
                  <SelectComponent
                    options={[
                      { label: "Male", value: "male" },
                      { label: "Female", value: "Female" },
                      { label: "Other", value: "Other" },
                    ]}
                    defaultValue={props.initialValues.gender}
                    onChange={(value) => props.setFieldValue("gender", value)}
                  />
                  <p className={classNames(classes["error_message"])}>
                    <ErrorMessage name="gender" />
                  </p>
                </Col>
                <Col span={12} className="flex flex-col gap-4">
                  <label>{i18NextText("customers.label_email")}</label>
                  <InputField
                    defaultValue={props.values.email}
                    type="text"
                    name="email"
                    placeholder="Enter email"
                    onChange={props.handleChange}
                  />
                  <p className={classNames(classes["error_message"])}>
                    <ErrorMessage name="email" />
                  </p>
                </Col>
              </Row>
              <Row className="flex flex-col">
                <div className="flex flex-col gap-4">
                  <label>{i18NextText("customers.label_address")}</label>
                  <InputField
                    defaultValue={props.values.address}
                    type="text"
                    name="address"
                    placeholder="Enter address"
                    onChange={props.handleChange}
                  />
                  <p className={classNames(classes["error_message"])}>
                    <ErrorMessage name="address" />
                  </p>
                </div>
                <div className="flex gap-4">
                  <label>{i18NextText("customers.label_active")}</label>
                  <Switch
                    checked={props.values.active}
                    onChange={(checked) =>
                      props.setFieldValue("active", checked)
                    }
                  />
                </div>
              </Row>
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
};
export default UpdateCustomerPage;
