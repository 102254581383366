import { action, thunk } from "easy-peasy";

import { SERVICES } from "Constants/Service.Constant";
import { getAwsUrl, sendFileToS3 } from "Services/Images.services";

import {
  createProductData,
  deleteOneProduct,
  getCategoryAndSubcategoryListService,
  getOneProductData,
  updateInfoProduct,
} from "Services/Products.Services";
import { Helpers } from "Store";
import { generatePath } from "Utils/GeneratePath.Util";
import { DEFAULT_FILTER_PARAMS, DEFAULT_PAGE_PARAMS } from "../List";

import { ProductDetailModel, IProductState } from "./Type";

export const INITIAL_AUTH_STATE: IProductState = {
  loading: true,
  loadingImage: true,
  loadingSubmit: false,
  data: {
    id: undefined,
    tag_list: [],
    title: "",
    description: "",
    product_details_attributes: [],
    brand_id: null,
    category_id: null,
    price: 0,
    original_price: 0,
    sku: "",
    status: "draft",
    quantity: 0,
    country_of_origin: "VN",
    weight: 0,
    weight_unit: "kg",
    variants: [],
    product_options_attributes: [],
    images_attributes: [],
  },
  dataSearchCategory: [],
};

const detail: ProductDetailModel = {
  ...INITIAL_AUTH_STATE,

  setLoading: action((state, payload) => {
    state.loading = payload;
  }),

  setDataSearchCategory: action((state, payload) => {
    state.dataSearchCategory = payload;
  }),

  setLoadingImage: action((state, payload) => {
    state.loadingImage = payload;
  }),

  setLoadingSubmit: action((state, payload) => {
    state.loadingSubmit = payload;
  }),

  saveData: action((state, { data }) => {
    state.data = data;
  }),

  reset: action((state, payload) => {
    return { ...payload, ...INITIAL_AUTH_STATE };
  }),

  getOneProduct: thunk(async (actions, { productId }, { getState }) => {
    const state = getState();
    if (!state.loading) {
      actions.setLoading(true);
    }
    const { data, success } = await getOneProductData(productId);
    const { category, brand } = data;
    delete data.category;
    delete data.brand;
    data["category_id"] = category.id;
    data["brand_id"] = brand ? brand.id : null;

    if (success) {
      actions.saveData({ data });
      actions.setLoading(false);
    } else {
      actions.setLoading(false);
    }
    return { success };
  }),

  createProduct: thunk(async (actions, _payload, { getState }) => {
    const state = getState();
    const newProduct = _payload;
    if (!state.loadingSubmit) {
      actions.setLoadingSubmit(true);
    }
    const { success, error } = await createProductData(newProduct);
    if (success) {
      actions.setLoadingSubmit(false);
    } else {
      actions.setLoadingSubmit(false);
    }
    return { success, error };
  }),

  updateInfoProduct: thunk(
    async (actions, { productId, values }, { getState }) => {
      const state = getState();
      if (!state.loadingSubmit) {
        actions.setLoadingSubmit(true);
      }
      const { data, success, error } = await updateInfoProduct(
        productId,
        values
      );
      if (success) {
        actions.saveData({ data: data });
        actions.setLoadingSubmit(false);
      } else {
        actions.setLoadingSubmit(false);
      }
      return { success, error };
    }
  ),

  deleteProduct: thunk(
    async (
      actions,
      { productId },
      { getState, getStoreActions }: Helpers<ProductDetailModel, any>
    ) => {
      const action = getStoreActions();
      const state = getState();
      if (!state.loadingSubmit) {
        actions.setLoadingSubmit(true);
      }
      const { success } = await deleteOneProduct(productId);
      if (success) {
        action.products.list.getProductsList({
          ...DEFAULT_PAGE_PARAMS,
          ...DEFAULT_FILTER_PARAMS,
        });
        actions.setLoadingSubmit(false);
      } else {
        actions.setLoadingSubmit(false);
      }
      return { success };
    }
  ),

  uploadImageProduct: thunk(async (actions, file, { getState }) => {
    const state = getState();
    if (!state.loadingImage) {
      actions.setLoadingImage(true);
    }
    const response = await getAwsUrl(
      generatePath(SERVICES.GET_AWS_URL, { folder: "products" })
    );
    const { success, data } = response;
    if (success) {
      const res = await sendFileToS3(
        file.originFileObj,
        data.aws_signedRequestURL,
        data.object_key
      );
      const { success, object_key } = res;
      if (success) {
        return { success, object_key };
      } else {
        actions.setLoadingImage(false);
      }
    } else {
      actions.setLoadingImage(false);
    }
  }),

  getCategoryAndSubcategoryList: thunk(async (actions, { keyword }) => {
    const { data, success } = await getCategoryAndSubcategoryListService(
      keyword
    );
    if (success) {
      actions.setDataSearchCategory(data);
    }

    return { success };
  }),
};

export default detail;
