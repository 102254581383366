import { action, thunk } from "easy-peasy";
import { changeStatusOrder, getOneOrderData } from "Services/Order.Services";

import { IOrderState, OrderDetailModel } from "./Type";

export const INITIAL_ORDER_STATE: IOrderState = {
  loading: true,
  loadingChangeStatus: false,
  data: {
    id: null,
    user_id: null,
    user_name: "",
    email: "",
    phone_number: "",
    shipping_address: "",
    province: { id: null, name: "" },
    district: { id: null, name: "" },
    ward: { id: null, name: "" },
    status: "",
    note: "",
    tax: null,
    shipping_method: "",
    payment_method: "",
    shipping_amount: 0,
    price_amount: 0,
    discount: 0,
    sub_total: 0,
    total: 0,
    order_details_attributes: [],
    created_at: "",
  },
};

const detailOrder: OrderDetailModel = {
  ...INITIAL_ORDER_STATE,

  setLoading: action((state, payload: boolean): void => {
    state.loading = payload;
  }),
  setLoadingChangeStatus: action((state, payload: boolean): void => {
    state.loading = payload;
  }),
  saveData: action((state, { data }): void => {
    state.data = data;
  }),

  getOrderDetail: thunk(async (actions, { orderId }, { getState }) => {
    const state = getState();
    if (!state.loading) {
      actions.setLoading(true);
    }
    const { data, success, error } = await getOneOrderData(orderId);

    if (success) {
      actions.saveData({ data });
      actions.setLoading(false);
    } else {
      actions.setLoading(false);
    }
    return { success, error };
  }),

  changeStatusOrder: thunk(async (actions, { dataOrder }, { getState }) => {
    const state = getState();
    if (state.loadingChangeStatus) {
      actions.setLoadingChangeStatus(true);
    }

    const { success, error } = await changeStatusOrder(dataOrder);
    if (success) {
      actions.setLoadingChangeStatus(false);
    } else {
      actions.setLoadingChangeStatus(false);
    }
    return { success, error };
  }),
};

export default detailOrder;
