import { action, thunk } from "easy-peasy";

import { editReview, getReviewsList } from "Services/Review.Services";
import { ReviewsListModel } from "./Type";

export const DEFAULT_FILTER_PARAMS = {
  sort: "",
};

export const DEFAULT_PAGE_PARAMS = {
  page: 1,
  per_page: 10,
  total_records: 0,
};

export const INITIAL_ORDER_STATE = {
  loading: true,
  loadingEdit: false,
  data: [],
  filterParams: DEFAULT_FILTER_PARAMS,
  pagination: DEFAULT_PAGE_PARAMS,
};

const reviewsList: ReviewsListModel = {
  ...INITIAL_ORDER_STATE,

  setLoading: action((state, payload: boolean): void => {
    state.loading = payload;
  }),

  setLoadingEdit: action((state, payload: boolean): void => {
    state.loading = payload;
  }),

  saveData: action((state, { data, params }): void => {
    const { sort, page, per_page, total_records } = params;
    state.data = data;
    state.pagination = { ...state.pagination, page, per_page, total_records };
    state.filterParams = { ...state.filterParams, sort };
    state.loading = false;
  }),

  getReviewsList: thunk(async (actions, { page, filter }, { getState }) => {
    const state = getState();
    if (!state.loading) actions.setLoading(true);
    state.pagination = { ...state.pagination };
    state.filterParams = { ...state.filterParams };
    const params = {
      ...state.pagination,
      ...state.filterParams,
      page: page ? page : 1,
      ...filter,
    };
    const { data, success, pagination } = await getReviewsList(params);
    if (success) {
      actions.saveData({ data, params: { ...params, ...pagination } });
    } else {
      actions.setLoading(false);
    }
    return { success };
  }),

  changeStatusReview: thunk(async (actions, { id, status }, { getState }) => {
    const state = getState();
    if (!state.loadingEdit) actions.setLoadingEdit(true);
    const data = state.data;
    const { success } = await editReview({ id, status });
    if (success) {
      const newData = data.map((review) => {
        if (review.id === id) {
          return { ...review, status: status };
        } else {
          return review;
        }
      });
      actions.saveData({
        data: newData,
        params: { ...state.filterParams, ...state.pagination },
      });
    } else {
      actions.setLoadingEdit(false);
    }
    return { success };
  }),
};

export default reviewsList;
