const fontHeight = {
  xs: "18px",
  sm: "20px",
  base: "22px",
  lg: "24px",
  xl: "26px",
  "2xl": "28px",
  "3xl": "32px",
  "4xl": "36px",
  "5xl": "44px",
  "6xl": "52px",
  "7xl": "60px",
  "8xl": "104px",
};

export default fontHeight;
