import {
  Image,
  Menu,
  Layout,
  Breadcrumb,
  Dropdown,
  Modal,
  Skeleton,
  Col,
  Row,
  Switch,
  Tag,
  Divider,
  Tooltip,
  Upload,
  Button,
  Empty,
  InputNumber,
  Table,
  Result,
  Select,
  Rate,
  Badge,
} from "antd";
import {
  TeamOutlined,
  PlusOutlined,
  DashOutlined,
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  ExclamationCircleOutlined,
  FilterOutlined,
  LogoutOutlined,
  CaretDownOutlined,
  SearchOutlined,
  CloseCircleFilled,
  PictureOutlined,
  UserOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from "@ant-design/icons";

const Icons = {
  TeamOutlined,
  PlusOutlined,
  DashOutlined,
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  ExclamationCircleOutlined,
  FilterOutlined,
  LogoutOutlined,
  CaretDownOutlined,
  SearchOutlined,
  CloseCircleFilled,
  PictureOutlined,
  UserOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
};

export {
  Button,
  Icons,
  Image,
  Menu,
  Layout,
  Breadcrumb,
  Dropdown,
  Modal,
  Skeleton,
  Col,
  Row,
  Switch,
  Tag,
  Divider,
  Tooltip,
  Upload,
  Empty,
  InputNumber,
  Table,
  Result,
  Select,
  Rate,
  Badge,
};
