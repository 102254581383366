import * as React from "react";
import { Image, ImageProps } from "antd";

import classes from "./ImageComponent.module.scss";
import LogoSFX from "Assets/Images/Homepage_seo.jpeg";

const ImageComponent = React.memo(
  ({ preview = false, ...rest }: ImageProps) => {
    return (
      <Image
        {...rest}
        preview={preview}
        src={rest.src === "" ? LogoSFX : rest.src}
        rootClassName={classes["image-container"]}
      />
    );
  }
);

export default ImageComponent;
