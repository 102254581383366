import classNames from "classnames";
import { FormikHelpers } from "formik";

import { IDetailValues, IVariantsCreate } from "Types/Product";
import { i18NextText } from "Utils/I18Next.Util";

import classes from "./VariantProduct.module.scss";
import VariantComponent from "./VariantComponent";
import HeaderVariant from "./HeaderVariant";

interface IVariantProduct {
  values: IDetailValues;
  setFieldValue: FormikHelpers<IDetailValues>["setFieldValue"];
}

const VariantProduct = ({ values, setFieldValue }: IVariantProduct) => {
  const { variants } = values;

  const inventoryOfStock =
    (!!values.variants.length &&
      values.variants
        .map((variant: IVariantsCreate) => variant.quantity)
        .reduce((previous, current) => previous + current)) ||
    0;
  return (
    <div className={classNames(classes["variants_product_container"])}>
      <div
        className={classNames(
          "border-b border-b-gray-border",
          classes["heading_variants"]
        )}
        style={{ padding: " var(--sp_lv_4) 0 " }}
      >
        <h2>{i18NextText("product.variant_title")}</h2>
        <HeaderVariant setFieldValue={setFieldValue} values={values} />
      </div>
      {variants &&
        variants.map((variant, indexVariant) => (
          <VariantComponent
            key={indexVariant}
            variant={variant}
            indexVariant={indexVariant}
            setFieldValue={setFieldValue}
          />
        ))}
      <div
        className="flex justify-between"
        style={{ padding: "var(--sp_lv_4) var(--sp_lv_6) var(--sp_lv_2)" }}
      >
        <p>{i18NextText("product.content_total_inventory")}</p>
        <p>
          {i18NextText("product.available", {
            number: inventoryOfStock,
          })}
        </p>
      </div>
    </div>
  );
};

export default VariantProduct;
