import classnames from "classnames";
import { Avatar } from "antd";

import classes from "./BoxContent.module.scss";
import Image from "CoreUI/Components/Common/Image";

import { IBoxContentProps } from "../types";

const BoxContent: React.FC<IBoxContentProps> = ({
  icon,
  iconSrc,
  iconSize,
  width,
  height,
  iconStyle,
  iconClass,
}) => {
  if (iconSrc) {
    return (
      <Image
        src={iconSrc}
        alt={iconSrc}
        className={iconClass}
        style={{
          ...iconStyle,
          width: "100%",
          maxWidth: width,
          height: "100%",
          maxHeight: height,
        }}
      />
    );
  }

  return (
    // <div
    //   style={{ fontSize: iconSize, ...iconStyle }}
    //   className={classnames(classes['box-content-text'], iconClass)}
    // >
    //   {icon}
    // </div>
    <Avatar
      style={{ fontSize: iconSize, ...iconStyle }}
      className={classnames(classes["box-content-text"], iconClass)}
    >
      {icon}
    </Avatar>
  );
};

export default BoxContent;
