import api from "Configs/Axios.Config";
import { SERVICES } from "Constants/Service.Constant";
import { _get } from "Utils/Lodash.Util";
import normalizeError from "Utils/NomalizeError.Util";
import { generatePath } from "Utils/GeneratePath.Util";
import { IProductFilterParams } from "Types/Product";

// LIST PRODUCT
export const getProductsDataService = async (params?: IProductFilterParams) => {
  try {
    const response = await api.get(SERVICES.GET_PRODUCTS_DATA, { params });
    const products = _get(response.data, "records", []);
    const page = _get(response.data, "pagination.page", 1);
    const per_page = _get(response.data, "pagination.per_page", 0);
    const total_records = _get(response.data, "pagination.total_count", 0);

    return {
      success: true,
      data: products,
      pagination: { page, per_page, total_records },
      error: null,
    };
  } catch (errors) {
    return {
      success: false,
      data: [],
      pagination: { page: 1, per_page: 10, total_records: 0 },
      error: normalizeError(errors),
    };
  }
};

// ONE PRODUCT
export const getOneProductData = async (productId: string) => {
  try {
    const response = await api.get(
      generatePath(SERVICES.GET_ONE_PRODUCTS, { productId })
    );
    return {
      success: true,
      data: response.data.product,
      error: null,
    };
  } catch (errors) {
    return {
      success: false,
      data: {},
      error: normalizeError(errors),
    };
  }
};

export const createProductData = async (newProduct: object) => {
  try {
    const response = await api.post(SERVICES.CREATE_NEW_PRODUCT, newProduct);
    return {
      success: true,
      data: response.data,
      error: null,
    };
  } catch (errors) {
    return {
      success: false,
      data: {},
      error: normalizeError(errors),
    };
  }
};

export const updateInfoProduct = async (productId, values) => {
  try {
    const response = await api.put(
      generatePath(SERVICES.EDIT_PRODUCT, { productId }),
      values
    );
    return {
      success: true,
      data: response.data,
      error: null,
    };
  } catch (errors) {
    return {
      success: false,
      data: {},
      error: normalizeError(errors),
    };
  }
};

export const deleteOneProduct = async (productId: string) => {
  try {
    const response = await api.delete(
      generatePath(SERVICES.DELETE_PRODUCT, { productId })
    );
    return {
      success: true,
      data: response.data,
      error: null,
    };
  } catch (errors) {
    return {
      success: false,
      data: {},
      error: normalizeError(errors),
    };
  }
};

export const getCategoryAndSubcategoryListService = async (
  keyword?: string
) => {
  try {
    const response = await api.get(SERVICES.GET_CATEGORY_AND_SUBCATEGORY, {
      params: { keyword },
    });
    const categories = _get(response.data, "records", []);

    return {
      success: true,
      data: categories,
      error: null,
    };
  } catch (errors) {
    return {
      success: false,
      data: [],
      error: normalizeError(errors),
    };
  }
};
