/* eslint-disable no-prototype-builtins */
import React, { useState } from "react";
import { FieldArray, FormikHelpers } from "formik";
import classNames from "classnames";

import { Icons, Divider } from "Components/Lib";
import ButtonComponent from "CoreUI/Components/Common/Button";
import CheckboxComponent from "CoreUI/Components/Common/Checkbox";
import { IDetailValues, IProductOptionAttributes } from "Types/Product";
import { i18NextText } from "Utils/I18Next.Util";

import classes from "./OptionAttributes.module.scss";
import OptionAttributesComponent from "./OptionAttributesComponent";
import { useStoreState } from "Store";

export interface IOptionAttributes {
  setFieldValue: FormikHelpers<IDetailValues>["setFieldValue"];
  product_options_attributes: IProductOptionAttributes[];
  // variants: IVariantsCreate[];
}

const OptionAttributesCopy = ({
  setFieldValue,
  product_options_attributes,
}: // variants,
IOptionAttributes) => {
  const [toggleOption, setToggleOption] = useState(
    !!product_options_attributes.length
  );

  const data = useStoreState((state) => state.products.detail.data);

  const initialValues = {
    id: null,
    option_attributes: {
      id: null,
      name: "",
      isNewOption: true,
      option_values_attributes: [
        {
          id: null,
          value: "",
        },
      ],
    },
  };

  const handleAddOption = () => {
    setFieldValue("product_options_attributes", [
      ...product_options_attributes,
      { ...initialValues },
    ]);
  };

  const handleCheckedOption = () => {
    if (toggleOption) {
      setFieldValue("product_options_attributes", []);
      setFieldValue("variants", []);
      setFieldValue("quantity", 0);
      setFieldValue("sku", "");
    } else {
      setFieldValue("product_options_attributes", [{ ...initialValues }]);
      setFieldValue("quantity", 0);
      setFieldValue("sku", "");
    }

    setToggleOption(!toggleOption);
  };

  return (
    <>
      <div className={classNames(classes["option_attributes_container"])}>
        <div className={classNames(classes["heading_option"])}>
          <h2>{i18NextText("product.option_title")}</h2>
          <div className="flex gap-2">
            <CheckboxComponent
              checked={toggleOption}
              onChange={handleCheckedOption}
            />
            <p>{i18NextText("product.checkbox_content")}</p>
          </div>
        </div>
        {toggleOption && (
          <>
            <Divider />
            <div>
              <>
                <FieldArray
                  name="product_options_attributes"
                  render={(props) => {
                    const { setFieldValue } = props.form;
                    return product_options_attributes.map(
                      (
                        product_options_attribute,
                        product_options_attributes_index
                      ) => {
                        return (
                          <OptionAttributesComponent
                            key={product_options_attributes_index}
                            product_options_attributes_index={
                              product_options_attributes_index
                            }
                            option_attributes={
                              product_options_attribute.option_attributes
                            }
                            setFieldValue={setFieldValue}
                            product_options_attributes={
                              product_options_attributes
                            }
                            variants={data.variants}
                          />
                        );
                      }
                    );
                  }}
                />
              </>
            </div>
            <div className={classNames(classes["btn_add_option_container"])}>
              <ButtonComponent
                className={classNames(classes["btn_add_option"])}
                onClick={handleAddOption}
              >
                <Icons.PlusOutlined />
                {i18NextText("product.btn_add_option")}
              </ButtonComponent>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default OptionAttributesCopy;
