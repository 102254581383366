import { SERVICES } from "Constants/Service.Constant";
import { action, thunk } from "easy-peasy";
import {
  createBrand,
  deleteOneBrand,
  getOneBrand,
  updateBrand,
} from "Services/Brands.Services";
import { getAwsUrl, sendFileToS3 } from "Services/Images.services";

import { Helpers } from "Store";
import { generatePath } from "Utils/GeneratePath.Util";
import { DEFAULT_PAGE_PARAMS } from "../ListBrands";
import { BrandDetailModel, IBrandState } from "./Type";

export const INITIAL_BRAND_STATE: IBrandState = {
  loading: true,
  loadingImage: true,
  loadingSubmit: false,
  data: {
    name: "",
    images_attributes: [],
    status: "active",
  },
};

const detailBrand: BrandDetailModel = {
  ...INITIAL_BRAND_STATE,

  setLoading: action((state, payload: boolean): void => {
    state.loading = payload;
  }),

  setLoadingImage: action((state, payload: boolean): void => {
    state.loadingImage = payload;
  }),

  setLoadingSubmit: action((state, payload: boolean): void => {
    state.loadingSubmit = payload;
  }),

  saveData: action((state, { data }): void => {
    state.data = data;
  }),

  reset: action((state, payload) => {
    return { ...payload, ...INITIAL_BRAND_STATE };
  }),

  getOneBrand: thunk(async (actions, { brandId }, { getState }) => {
    const state = getState();
    if (!state.loading) {
      actions.setLoading(true);
    }
    const { data, success } = await getOneBrand(brandId);

    if (success) {
      actions.saveData({ data });
      actions.setLoading(false);
    } else {
      actions.setLoading(false);
    }
    return { success };
  }),

  createBrand: thunk(async (actions, _payload, { getState }) => {
    const state = getState();
    const newBrand = _payload;
    if (!state.loadingSubmit) {
      actions.setLoadingSubmit(true);
    }
    const { success } = await createBrand(newBrand);
    if (success) {
      actions.setLoadingSubmit(false);
    } else {
      actions.setLoadingSubmit(false);
    }
    return { success };
  }),

  updateBrand: thunk(async (actions, { brandId, values }, { getState }) => {
    const state = getState();
    if (!state.loadingSubmit) {
      actions.setLoadingSubmit(true);
    }
    const { success } = await updateBrand(brandId, values);
    if (success) {
      actions.setLoadingSubmit(false);
    } else {
      actions.setLoadingSubmit(false);
    }
    return { success };
  }),

  deleteBrand: thunk(
    async (
      actions,
      { brandId },
      { getState, getStoreActions }: Helpers<BrandDetailModel, any>
    ) => {
      const action = getStoreActions();
      const state = getState();
      if (!state.loadingSubmit) {
        actions.setLoadingSubmit(true);
      }
      const { success } = await deleteOneBrand(brandId);
      if (success) {
        action.categories.listCategories.getCategoriesList({
          ...DEFAULT_PAGE_PARAMS,
        });
        actions.setLoadingSubmit(false);
      } else {
        actions.setLoadingSubmit(false);
      }
      return { success };
    }
  ),

  uploadImageBrand: thunk(async (actions, file, { getState }) => {
    const state = getState();
    if (!state.loadingImage) {
      actions.setLoadingImage(true);
    }
    const response = await getAwsUrl(
      generatePath(SERVICES.GET_AWS_URL, { folder: "brands" })
    );
    const { success, data } = response;
    if (success) {
      const res = await sendFileToS3(
        file.originFileObj,
        data.aws_signedRequestURL,
        data.object_key
      );
      const { success, object_key } = res;
      if (success) {
        return { success, object_key };
      } else {
        actions.setLoadingImage(false);
      }
    } else {
      actions.setLoadingImage(false);
    }
  }),
};

export default detailBrand;
