import { Button, Icons } from "Components/Lib";
import { i18NextText } from "Utils/I18Next.Util";
import { useModalContext } from "Context/Modal";
import ModalConfirm from "Components/Own/ModelConfirm";
import { notification } from "Utils/Notification.Util";
import { useStoreActions } from "Store";

import classes from "./DeleteButton.module.scss";

interface IDeleteButton {
  id: string;
}

const DeleteButton = ({ id }: IDeleteButton) => {
  const { updateModal, hideModal } = useModalContext();

  const deleteCategory = useStoreActions(
    (action) => action.categories.detailCategory.deleteCategory
  );

  const showConfirm = ({ modalContent }: { modalContent: React.ReactNode }) => {
    updateModal(modalContent, {
      showTitle: true,
      width: 400,
      height: "auto",
    });
  };

  const onDelete = async () => {
    const response = await deleteCategory({ categoryId: id });
    if (!response.success) {
      notification.error({
        message: "",
        description: i18NextText("error.notification_error"),
      });
    }
    hideModal();
  };

  return (
    <div className={classes["button_delete"]}>
      <Button
        style={{ color: "var(--red_5)" }}
        size="small"
        onClick={() =>
          showConfirm({
            modalContent: (
              <ModalConfirm
                onClick={onDelete}
                title={i18NextText("option.show_confirm_title")}
              />
            ),
          })
        }
      >
        <Icons.DeleteOutlined /> <p>{i18NextText("option.btn_delete")}</p>
      </Button>
    </div>
  );
};

export default DeleteButton;
