import { message } from "Utils/Notification.Util";
import { PLATFORM } from "Constants/Platform.Constant";
import { EMAIL_REGEX } from "Constants/Regex.Constant";

export const emailChecker = (email: string) => {
  return EMAIL_REGEX.test(email.toLowerCase());
};

export const removeSpace = (value: string) => {
  return (value || "").replace(/\s/g, "");
};

export const ellipsisText = (text: string, limit = 10) => {
  if (text && text.length > limit) {
    return `${text.substring(0, limit)}...`;
  } else {
    return text;
  }
};

export const formatCurrency = (n: number, currency?: string, code?: string) => {
  return new Intl.NumberFormat(code || "en-US", {
    style: "currency",
    currency: currency || "USD",
  }).format(n);
};

export const formatCurrencyVN = (n: number) => {
  return new Intl.NumberFormat("vi-VN", {
    style: "currency",
    currency: "VND",
  }).format(n);
};

export const checkOS = () => {
  const userAgent = window.navigator.userAgent,
    platform = window.navigator.platform;
  const macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];
  const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];
  const iosPlatforms = ["iPhone", "iPad", "iPod"];
  let os = "";

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = PLATFORM.MAC_OS;
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = PLATFORM.IOS;
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = PLATFORM.WINDOWS;
  } else if (/Android/.test(userAgent)) {
    os = PLATFORM.ANDROID;
  } else if (!os && /Linux/.test(platform)) {
    os = PLATFORM.LINUX;
  }

  return os;
};

export const getShortName = (name: string) => {
  if (!name?.length) return "";

  const _nameArr = name.split(" ");
  const length = _nameArr.length;
  const shortName =
    length === 1 ? _nameArr[0][0] : _nameArr[0][0] + _nameArr[length - 1][0];

  return shortName;
};

export const copyToClipBoard = (value: string) => {
  /* Get the text field */
  const copyText = document.getElementById(value) as HTMLInputElement;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (window.clipboardData && window.clipboardData.setData) {
    // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return window.clipboardData.setData("Text", copyText.value);
  } else if (
    document.queryCommandSupported &&
    document.queryCommandSupported("copy")
  ) {
    const textarea = document.createElement("textarea");
    textarea.textContent = copyText.value;
    textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
    document.body.appendChild(textarea);
    textarea.select();
    try {
      return document.execCommand("copy"); // Security exception may be thrown by some browsers.
    } catch (ex) {
      console.warn("Copy to clipboard failed.", ex);
      return prompt("Copy to clipboard: Ctrl+C, Enter", copyText.value);
    } finally {
      document.body.removeChild(textarea);
      message.success(`Copied ${copyText.value}`);
    }
  }
};

export const getUrlParam = (name: string) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  return urlParams.get(name);
};
