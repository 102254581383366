import common from "./Common";
import product from "./Product";
import customers from "./Customers";
import merchant from "./Merchant";
import option from "./OptionComponent";
import auth from "./Auth";
import category from "./Category";
import brand from "./Brand";
import order from "./Order";
import error from "./Error";
import review from "./Review";

const locale = {
  common,
  product,
  customers,
  merchant,
  auth,
  option,
  category,
  brand,
  order,
  error,
  review,
};

export default locale;
