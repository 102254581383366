import axios from "axios";

import api from "Configs/Axios.Config";

import normalizeError from "Utils/NomalizeError.Util";

export const getAwsUrl = async (url) => {
  try {
    const response = await api.get(url);
    return {
      success: true,
      data: response.data,
      error: null,
    };
  } catch (errors) {
    return {
      success: false,
      data: {},
      error: normalizeError(errors),
    };
  }
};

export const sendFileToS3 = async (
  file: any,
  url: string,
  object_key: string
) => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": file.type,
    },
  };

  try {
    await axios.put(url, file, config);
    return { success: true, object_key: object_key };
  } catch (error) {
    return { success: false, key: "" };
  }
};
