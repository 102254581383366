import api from "Configs/Axios.Config";
import { SERVICES } from "Constants/Service.Constant";
import { _get } from "Utils/Lodash.Util";
import normalizeError from "Utils/NomalizeError.Util";
import { IMerchantParams } from "Types/Merchant";
import { generatePath } from "Utils/GeneratePath.Util";

export const getMerchantsData = async (params?: IMerchantParams) => {
  try {
    const response = await api.get(SERVICES.GET_MERCHANTS_DATA, { params });
    const merchants = _get(response.data, "merchants", []);
    const page = _get(response.data, "pagination.current_page", 1);
    const next_page = _get(response.data, "pagination.next_page", null);
    const total_records = _get(response.data, "pagination.total_records", 0);

    return {
      success: true,
      data: merchants,
      pagination: { page, next_page, total_records },
      error: null,
    };
  } catch (errors) {
    const pagination = {
      page: 1,
      next_page: null,
      total_records: 0,
    };

    return {
      success: false,
      data: [],
      pagination,
      error: normalizeError(errors),
    };
  }
};

export const getOneMerchantData = async (merchantId: string) => {
  try {
    const response = await api.get(
      generatePath(SERVICES.GET_ONE_MERCHANTS, { merchantId })
    );
    return {
      success: true,
      data: response.data.merchants,
      error: null,
    };
  } catch (errors) {
    return {
      success: false,
      data: {},
      error: normalizeError(errors),
    };
  }
};

export const createMerchantData = async (newMerchant: object) => {
  try {
    const response = await api.post(SERVICES.CREATE_NEW_MERCHANT, newMerchant);
    return {
      success: true,
      data: response.data,
      error: null,
    };
  } catch (errors) {
    return {
      success: false,
      data: {},
      error: normalizeError(errors),
    };
  }
};

export const updateInforMerchant = async (merchantId, values) => {
  try {
    const response = await api.put(
      generatePath(SERVICES.EDIT_MERCHANT, { merchantId }),
      values
    );
    return {
      success: true,
      data: response.data,
      error: null,
    };
  } catch (errors) {
    return {
      success: false,
      data: {},
      error: normalizeError(errors),
    };
  }
};

export const deleteOneMerchant = async (merchantId: string) => {
  try {
    const response = await api.delete(
      generatePath(SERVICES.DELETE_MERCHANT, { merchantId })
    );
    return {
      success: true,
      data: response.data,
      error: null,
    };
  } catch (errors) {
    return {
      success: false,
      data: {},
      error: normalizeError(errors),
    };
  }
};
