import { memo, useEffect } from "react";

import { useStoreActions } from "Store";
import { i18NextText } from "Utils/I18Next.Util";
import { notification } from "Utils/Notification.Util";

import TableReviews from "./TableReviews";

const ReviewsPage = () => {
  const getReviewsList = useStoreActions(
    (action) => action.reviews.reviewsList.getReviewsList
  );

  useEffect(() => {
    const fetchData = async () => {
      const response = await getReviewsList({});
      if (!response.success) {
        notification.error({
          message: "",
          description: i18NextText("error.notification_error"),
        });
      }
    };
    fetchData();
  }, [getReviewsList]);

  return (
    <>
      <div
        className="flex justify-between"
        style={{ padding: "var(--sp_lv_2) var(--sp_lv_3) " }}
      >
        <h1 className="font-semibold">Reviews</h1>
      </div>
      <div>
        <TableReviews />
      </div>
    </>
  );
};

export default memo(ReviewsPage);
