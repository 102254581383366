import classNames from "classnames";
import { ErrorMessage, Form, Formik, FormikProps } from "formik";
import { useNavigate } from "react-router-dom";

import ButtonComponent from "CoreUI/Components/Common/Button";
import InputField from "CoreUI/Components/Common/InputField";
import { useStoreActions, useStoreState } from "Store";
import { IDetailValues } from "Types/SignIn";
import { i18NextText } from "Utils/I18Next.Util";
import { notification } from "Utils/Notification.Util";
import { PATH_NAME } from "Constants/PathName.Constant";

import classes from "./GetPassword.module.scss";
import GetPassSchema from "./GetPassSchema";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const getNewPass = useStoreActions((action) => action.auth.forgotPass);
  const loading = useStoreState((state) => state.auth.loading);

  const initialValues = {
    email: "",
  };

  const handleSignIn = async (email: string) => {
    const response = await getNewPass({ email });
    if (response.success) {
      notification.success({
        message: "",
        description: response.message,
      });
    }
    navigate(PATH_NAME.SIGN_IN);
  };
  return (
    <div
      className={classNames(
        "flex flex-col justify-center items-center",
        classes["forgotPassword-Page-container"]
      )}
    >
      <h1>{i18NextText("auth.getPass")}</h1>
      <Formik
        initialValues={initialValues}
        validationSchema={GetPassSchema}
        onSubmit={async (values: IDetailValues) => {
          const { email } = values;
          await handleSignIn(email.trim());
        }}
      >
        {(props: FormikProps<IDetailValues>) => {
          const { setFieldValue } = props;
          return (
            <>
              <Form
                className={classNames(
                  "flex flex-col gap-6",
                  classes["form-forgotPassword-container"]
                )}
              >
                <label>{i18NextText("auth.getPass_label_form")}</label>
                <InputField
                  type="text"
                  name="email"
                  placeholder={i18NextText("auth.getPass_place_holder_email")}
                  onChange={(e) =>
                    setFieldValue("email", e.target.value.trim())
                  }
                />
                <p className={classNames(classes["error_message"])}>
                  <ErrorMessage name="email" />
                </p>
                <ButtonComponent
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  {i18NextText("auth.btn_submit")}
                </ButtonComponent>
              </Form>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default ForgotPassword;
