import { useEffect, useState } from "react";
import { Form, Formik, FormikProps } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import classNames from "classnames";

import { useStoreActions, useStoreState } from "Store";
import { _get } from "Utils/Lodash.Util";
import { Icons, Skeleton, Divider } from "Components/Lib";
import { i18NextText } from "Utils/I18Next.Util";
import { PATH_NAME } from "Constants/PathName.Constant";
import ButtonComponent from "CoreUI/Components/Common/Button";
import { IDetailValues, IVariantsCreate } from "Types/Product";
import { notification } from "Utils/Notification.Util";

import classes from "./FormProduct.module.scss";
import ProductSchema from "./ProductSchema";
// import OptionAttributes from "./OptionAttributes";
import VariantProduct from "./VariantProduct";
import ProductInfo from "./ProductInfo";
import ImagesProduct from "./ImagesProduct";
import Pricing from "./Pricing";
import Inventory from "./Inventory";
// import Shipping from "./Shipping";
import ProductStatus from "./ProductStatus";
import ProductOrganization from "./ProductOrganization";
import NoExistProduct from "./NoExistProduct";
import OptionAttributesCopy from "./OptionAttributesCopy";

const ProductDetail = () => {
  const params = useParams();
  const navigate = useNavigate();
  const productId = _get(params, "product_id");

  const loading = useStoreState((state) => state.products.detail.loading);
  const data = useStoreState((state) => state.products.detail.data);

  const reset = useStoreActions((action) => action.products.detail.reset);
  const getOneProduct = useStoreActions(
    (action) => action.products.detail.getOneProduct
  );
  const createProduct = useStoreActions(
    (action) => action.products.detail.createProduct
  );
  const uploadImage = useStoreActions(
    (action) => action.products.detail.uploadImageProduct
  );
  const updateInfoProduct = useStoreActions(
    (action) => action.products.detail.updateInfoProduct
  );

  useEffect(() => {
    return () => reset({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!productId) return;

    const fetchData = async () => {
      const response = await getOneProduct({ productId });
      if (!response.success) {
        notification.error({
          message: "",
          description: i18NextText("error.notification_error"),
        });
      }
    };
    if (productId !== "new") {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  const initialValues = {
    id: undefined,
    tag_list: [],
    title: "",
    description: "",
    product_details_attributes: [],
    brand_id: null,
    category_id: null,
    sku: "",
    status: "draft",
    quantity: 0,
    country_of_origin: null,
    weight: 0,
    weight_unit: "kg",
    product_options_attributes: [],
    ...data,
    price: Number.isNaN(Number(data.price)) ? null : Number(data.price),
    original_price: Number.isNaN(Number(data.original_price))
      ? null
      : Number(data.original_price),
    images_attributes: _get(data, "images_attributes", []).map((image) => ({
      ...image,
      uid: image.id.toString(),
      name: "",
      status: "done",
      url: image.public_url,
      isValid: true,
      isUploaded: true,
    })),
    variants: _get(data, "variants", []).map((variant) => {
      const new_product_variant_options = variant.product_variant_options.map(
        (option) => ({
          id: option.id,
          option: option.option.name,
          value: option.value.name,
        })
      );
      const new_images_attribute =
        (!!variant.images_attributes.length &&
          variant.images_attributes.map((image) => ({
            ...image,
            uid: image.id.toString(),
            name: "",
            status: "done",
            url: image.public_url,
            isValid: true,
            isUploaded: true,
          }))) ||
        [];
      return {
        ...variant,
        product_variant_options: new_product_variant_options,
        price: Number(variant.price),
        original_price: Number(variant.original_price),
        images_attributes: new_images_attribute,
      };
    }),
  };
  const [loadingOnSubmit, setLoadingOnSubmit] = useState(false);
  const handleCreateProduct = async (values: IDetailValues) => {
    setLoadingOnSubmit(true);
    const { tag_list } = values;

    const valuesProduct = {
      ...values,
      tag_list: tag_list.join(","),
      quantity: values.variants.length
        ? values.variants
            .map((variant: IVariantsCreate) => variant.quantity)
            .reduce((previous, current) => previous + current)
        : values.quantity,
    };

    let images = [];
    if (values.images_attributes.length) {
      images = await handleUploadImages(values.images_attributes);
      if (!images.length) return;
    }
    let newVariants = [];
    if (values.variants.length) {
      newVariants = await Promise.all(
        values.variants.map(async (variant) => {
          let images = [];
          if (variant.images_attributes.length) {
            images = await handleUploadImages(variant.images_attributes);
            if (!images.length) return;
          }
          return { ...variant, images_attributes: images };
        })
      );
    }

    const res = await createProduct({
      ...valuesProduct,
      images_attributes: images,
      variants: newVariants,
    });

    if (res.success) {
      navigate(PATH_NAME.PRODUCTS);
    } else {
      const errors = res.error.split(",");
      notification.error({
        duration: null,
        message: "Unable to create product",
        description: (
          <div>
            {errors.map((error) => (
              <div> - {error}</div>
            ))}
          </div>
        ),
      });
    }
    setLoadingOnSubmit(false);
  };

  const handleUpdateProduct = async (values: IDetailValues) => {
    setLoadingOnSubmit(true);

    const { tag_list } = values;
    const valuesProduct = {
      ...values,
      tag_list: tag_list.join(","),
      quantity: values.variants.length
        ? values.variants
            .map((variant: IVariantsCreate) => variant.quantity)
            .reduce((previous, current) => previous + current)
        : values.quantity,
    };

    let images = [];
    if (values.images_attributes.length) {
      images = await handleUploadImages(values.images_attributes);
      if (!images.length) return;
    }

    let newVariants = [];
    if (values.variants.length) {
      newVariants = await Promise.all(
        values.variants.map(async (variant) => {
          let images = [];
          if (variant.images_attributes.length) {
            images = await handleUploadImages(variant.images_attributes);
            if (!images.length) return;
          }
          return { ...variant, images_attributes: images };
        })
      );
    }
    const res = await updateInfoProduct({
      productId,
      values: {
        ...valuesProduct,
        images_attributes: images,
        variants: newVariants,
      },
    });
    if (res.success) {
      navigate(PATH_NAME.PRODUCTS);
    } else {
      const errors = res.error.split(",");
      notification.error({
        duration: null,
        message: "Unable to edit product",
        description: errors.length ? (
          <div>
            {errors.map((error) => (
              <div> - {error}</div>
            ))}
          </div>
        ) : (
          i18NextText("error.notification_error")
        ),
      });
    }
    setLoadingOnSubmit(false);
  };

  const handleUploadImages = async (images_attributes) => {
    const uploadedImages = images_attributes.filter(
      (image) => image.isUploaded
    );
    const listUploadFileImages = images_attributes.filter(
      (image) => !image.isUploaded
    );

    const response = await Promise.all(
      listUploadFileImages.map((file) => {
        try {
          const res = uploadImage(file);
          return res;
        } catch (error) {
          return "Upload Fail";
        }
      })
    );
    if (!response.includes("Upload Fail")) {
      const images = [...uploadedImages, ...response].map(
        (image: any, index: number) => {
          if (image.id) {
            return {
              id: image.id,
              object_key: image.object_key,
              position: index + 1,
            };
          }
          return { object_key: image.object_key, position: index + 1 };
        }
      );
      return images;
    } else {
      return [];
    }
  };

  if (!productId) {
    return <NoExistProduct />;
  }

  if (productId !== "new" && loading) return <Skeleton />;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ProductSchema}
      onSubmit={async (values: IDetailValues) => {
        if (productId === "new") {
          await handleCreateProduct(values);
        } else {
          await handleUpdateProduct(values);
        }
      }}
    >
      {({ values, setFieldValue, dirty }: FormikProps<IDetailValues>) => {
        return (
          <Form
            className={classNames(
              "flex flex-col gap-4",
              classes["form-product-container"]
            )}
          >
            <div
              className="flex justify-between bg-white sticky top-0 z-50 shadow-[0_3px_10px_rgb(0,0,0,0.2)]"
              style={{ padding: "var(--sp_lv_8)" }}
            >
              <h1 className="font-semibold">
                {productId !== "new"
                  ? i18NextText("product.Title_form_edit")
                  : i18NextText("product.Title_form_create")}
              </h1>
              <div className="flex gap-4">
                <ButtonComponent
                  className={classNames([classes["edit-product-btn"]])}
                  type="primary"
                  htmlType="submit"
                  icon={<Icons.PlusOutlined />}
                  disabled={!dirty}
                  loading={loadingOnSubmit}
                >
                  {productId !== "new"
                    ? i18NextText("product.btn_update")
                    : i18NextText("product.btn_create")}
                </ButtonComponent>
              </div>
            </div>
            <div className="flex flex-col-reverse justify-between gap-5 md:flex-row">
              <div className="flex flex-col justify-between gap-5 md:w-[65%]">
                <ProductInfo values={values} setFieldValue={setFieldValue} />
                <ImagesProduct values={values} setFieldValue={setFieldValue} />
                <Pricing values={values} setFieldValue={setFieldValue} />
                <Inventory values={values} setFieldValue={setFieldValue} />
                {/* <Shipping values={values} setFieldValue={setFieldValue} /> */}
                <OptionAttributesCopy
                  product_options_attributes={values.product_options_attributes}
                  setFieldValue={setFieldValue}
                />
                {!!values.variants.length && (
                  <VariantProduct
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                )}
              </div>
              <div className="grow">
                <ProductStatus values={values} setFieldValue={setFieldValue} />
                <Divider />
                <ProductOrganization
                  values={values}
                  setFieldValue={setFieldValue}
                />
              </div>
            </div>
            <Divider />
          </Form>
        );
      }}
    </Formik>
  );
};
export default ProductDetail;
