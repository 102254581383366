import { Yup } from "Utils/Yup.Util";
import { i18NextText } from "Utils/I18Next.Util";

const ProductSchema = Yup.object().shape({
  title: Yup.string().required(i18NextText("product.validator_name")),

  category_id: Yup.number()
    .typeError(i18NextText("product.validator_category"))
    .required(i18NextText("product.validator_category")),
  // brand_id: Yup.number()
  //   .typeError(i18NextText("product.validator_brand"))
  //   .required(i18NextText("product.validator_brand")),
  price: Yup.number()
    .nullable()
    .typeError(i18NextText("product.validator_number")),
  original_price: Yup.number()
    .nullable()
    .typeError(i18NextText("product.validator_number")),
  status: Yup.string(),
  quantity: Yup.number()
    .nullable()
    .typeError(i18NextText("product.validator_number")),
  country_of_origin: Yup.string()
    .nullable()
    .required(i18NextText("product.validator_country")),
});

export default ProductSchema;
