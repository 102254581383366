import { Result } from "Components/Lib";
import { PATH_NAME } from "Constants/PathName.Constant";
import Button from "CoreUI/Components/Common/Button";

const NoExistProduct = () => {
  return (
    <Result
      status="warning"
      title="Product not found."
      extra={
        <Button type="primary" href={PATH_NAME.HOME}>
          Go home
        </Button>
      }
    />
  );
};

export default NoExistProduct;
