import classNames from "classnames";

import ButtonComponent from "CoreUI/Components/Common/Button";
import { useModalContext } from "Context/Modal";

import classes from "./VariantButton.module.scss";

interface IVariantButtonComponent {
  modalEditVariant: React.ReactNode;
  content: string;
}

const VariantButtonComponent = ({
  modalEditVariant,
  content,
}: IVariantButtonComponent) => {
  const { updateModal } = useModalContext();

  const showConfirm = () => {
    updateModal(modalEditVariant, {
      showTitle: true,
      width: 650,
      height: "auto",
      title: content,
    });
  };
  return (
    <ButtonComponent
      className={classNames(classes["btn_edit_variant"])}
      onClick={() => showConfirm()}
    >
      {content}
    </ButtonComponent>
  );
};

export default VariantButtonComponent;
