import { action, thunk } from "easy-peasy";

import { getCategoriesList } from "Services/Categories.Services";

import { ICategoriesState, ListCategoriesModel } from "./Type";

export const DEFAULT_PAGE_PARAMS = {
  page: 1,
  per_page: 5,
  total_records: 0,
  keyword: "",
  next_page: null,
};

export const INITIAL_AUTH_STATE = {
  loading: true,
  data: [],
  pagination: {
    page: 1,
    per_page: 5,
    total_records: 0,
    keyword: "",
    next_page: null,
  },
};

const listCategories: ListCategoriesModel = {
  ...INITIAL_AUTH_STATE,

  setLoading: action((state, payload: boolean): void => {
    state.loading = payload;
  }),

  saveData: action((state, { data, pagination }): void => {
    state.data = data;
    state.pagination = { ...state.pagination, ...pagination };
    state.loading = false;
  }),

  setKeyword: action((state: ICategoriesState, payload): void => {
    state.pagination.keyword = payload;
  }),

  getCategoriesList: thunk(async (actions, { page, keyword }, { getState }) => {
    const state = getState();
    if (!state.loading) actions.setLoading(true);
    state.pagination = { ...state.pagination, keyword };
    const params = {
      ...state.pagination,
      page: page ? page : 1,
      keyword,
    };
    const { data, success, pagination } = await getCategoriesList(params);
    if (success) {
      actions.saveData({ data, pagination: { ...params, ...pagination } });
    } else {
      actions.setLoading(false);
    }
    return { success };
  }),

  loadMoreCategoriesList: thunk(
    async (actions, { page, keyword }, { getState }) => {
      const state = getState();
      if (!state.loading) actions.setLoading(true);
      state.pagination = { ...state.pagination, keyword };
      const params = {
        ...state.pagination,
        page: page ? page : 1,
        keyword,
      };
      const { data, success, pagination } = await getCategoriesList(params);
      const dataLoadMore = state.data.concat(data);
      if (success) {
        actions.saveData({
          data: dataLoadMore,
          pagination: { ...params, ...pagination },
        });
      } else {
        actions.setLoading(false);
      }
      return { success };
    }
  ),
};

export default listCategories;
