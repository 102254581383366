import { i18NextText } from "Utils/I18Next.Util";
import { useModalContext } from "Context/Modal";
import ButtonComponent from "CoreUI/Components/Common/Button";

interface IModalConfirm {
  onClick: () => void;
  title: string;
  loading?: boolean;
}

const ModalConfirm = ({ onClick, title, loading }: IModalConfirm) => {
  const { hideModal } = useModalContext();

  return (
    <div className="px-12 pb-12">
      <div className="flex justify-center">
        <h3>{title}</h3>
      </div>
      <div className="flex items-center justify-between mt-12 gap-12">
        <ButtonComponent className="w-1/2" onClick={hideModal}>
          {i18NextText("option.btn_confirm_cancel")}
        </ButtonComponent>
        <ButtonComponent
          loading={loading === null ? false : loading}
          type="primary"
          onClick={onClick}
          className="w-1/2"
        >
          {i18NextText("option.btn_confirm_yes")}
        </ButtonComponent>
      </div>
    </div>
  );
};

export default ModalConfirm;
