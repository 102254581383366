import { useStoreState, useStoreActions } from "Store";
import DataTable from "CoreUI/Components/Common/DataTable";
import { i18NextText } from "Utils/I18Next.Util";
import { notification } from "Utils/Notification.Util";

import TitleComponent from "./Title.Component";
import columnsBrand from "./ColumnsBrand";

const TableListBrands = () => {
  const data = useStoreState((state) => state.brands.listBrands.data);
  const pagination = useStoreState(
    (state) => state.brands.listBrands.pagination
  );
  const loading = useStoreState((state) => state.brands.listBrands.loading);

  const getListBrands = useStoreActions(
    (action) => action.brands.listBrands.getBrandsList
  );

  const onPageChange = async (tablePagination) => {
    const response = await getListBrands({ page: tablePagination.current });
    if (!response.success) {
      notification.error({
        message: "",
        description: i18NextText("error.notification_error"),
      });
    }
  };

  return (
    <div style={{ padding: "var(--sp_lv_2) var(--sp_lv_3) var(--sp_lv_8)" }}>
      <DataTable
        data={data}
        columns={columnsBrand}
        rowKey={(record: any) => {
          return record.id;
        }}
        loading={loading}
        pagination={{
          current: pagination.page,
          pageSize: pagination.per_page,
          total: pagination.total_records,
        }}
        onChange={onPageChange}
        title={() => <TitleComponent />}
      />
    </div>
  );
};

export default TableListBrands;
