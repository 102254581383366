import reviewsList from "./ReviewList";
import { ReviewsListModel } from "./ReviewList/Type";

export interface ReviewsModel {
  reviewsList: ReviewsListModel;
}

const reviews = { reviewsList };

export default reviews;
