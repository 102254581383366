const category = {
  categories: "Categories",
  title_table: "Category List",
  btn_add_category: "Add Category",
  column_name: "CATEGORY",
  column_sub_category: "SUBCATEGORIES",
  column_status: "ACTIVE",
  column_priority: "PRIORITY",

  title_form_edit: "Edit Category",
  title_form_create: "Create Category",
  btn_update: "Update",
  btn_create: "Create",
  form_placeholder_name: "Enter category name",
  images: "Images",
  // FORM
  subcategories: "Subcategories",
  field_status_category: "Active",
  field_name_category: "Category name",
  field_status_subcategory: "Active",
  field_name_subcategory: "Name",
  form_placeholder_subcategory: "Enter subcategory name",
  images_description: "Accept images with .PNG, .JPG, .JPEG",
  btn_add_subcategory: "Add another subcategory",
  tooltip_delete_subcategory: "Delete this subcategory?",
  validator_duplicate_name:
    "The name of the subcategory is the same as the name of the category, please enter another name.",
  image_banner_category: "Images banner",
  // Schema
  validator_name: "Category name is required",
  validator_name_subcategory: "Subcategory name is required",
};

export default category;
