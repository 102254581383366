export const SERVICES = {
  //auth
  SIGN_IN: "/login",
  FORGOT_PASSWORD: "/fresh_site/passwords/forgot",

  //products
  GET_PRODUCTS_DATA: "/fresh_site/products",
  GET_ONE_PRODUCTS: "/fresh_site/products/:productId/edit",
  CREATE_NEW_PRODUCT: "/products",
  EDIT_PRODUCT: "/products/:productId/edit",
  DELETE_PRODUCT: "/products/:productId",

  // customers
  GET_CUSTOMERS_DATA: "/users",
  GET_ONE_CUSTOMER: "/users/:customerId",
  UPDATE_CUSTOMER: "/users/:customerId",
  DELETE_CUSTOMER: "/users/:customerId",

  // Merchants
  GET_MERCHANTS_DATA: "/merchants",
  GET_ONE_MERCHANTS: "/merchants/:merchantId",
  CREATE_NEW_MERCHANT: "/merchants",
  EDIT_MERCHANT: "/merchants/:merchantId",
  DELETE_MERCHANT: "/merchants/:merchantId",

  // Category
  GET_CATEGORIES_DATA: "/fresh_site/categories",
  GET_ONE_CATEGORY: "/categories/:id/edit",
  CREATE_NEW_CATEGORY: "/categories",
  EDIT_CATEGORY: "/categories/:category_id/edit",
  DELETE_CATEGORY: "/categories/:categoryId",
  GET_CATEGORY_AND_SUBCATEGORY: "/fresh_site/categories/create/product",

  // Brand
  GET_BRANDS_DATA: "/fresh_site/brands",
  GET_ONE_BRAND: "/brands/:id/edit",
  CREATE_NEW_BRAND: "/brands",
  EDIT_BRAND: "/brands/:brand_id/edit",
  DELETE_BRAND: "/brands/:brandId",

  // Order
  GET_ORDERS_DATA: "/fresh_site/orders",
  GET_ONE_ORDER: "/fresh_site/orders/:id/edit",
  GET_COUNT_ORDER_NEW: "/fresh_site/orders/count",
  CHANGE_STATUS_ORDER: "/orders/:id/edit",
  // Review
  GET_REVIEWS_DATA: "/fresh_site/comments",
  EDIT_REVIEW: "/fresh_site/comments/:id/edit",
  //
  GET_AWS_URL: "/fresh_site/images/signed_url/:folder",
};
