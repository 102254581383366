import classNames from "classnames";
import { ErrorMessage, FormikHelpers } from "formik";

import { Icons, Divider } from "Components/Lib";
import ButtonComponent from "CoreUI/Components/Common/Button";
import InputField, { TextArea } from "CoreUI/Components/Common/InputField";
import { IDetailValues } from "Types/Product";
import { i18NextText } from "Utils/I18Next.Util";

import classes from "./FormDescription.module.scss";

type PropsFormDescription = {
  setFieldValue: FormikHelpers<IDetailValues>["setFieldValue"];
  values: {
    title: string;
    description: string;
  };
  setFieldTitle: string;
  setFieldDescription: string;
  showDeleteButton?: boolean;
  handleDeleteOption?: () => void;
};

const FormDescription = ({
  setFieldValue,
  values,
  setFieldTitle,
  setFieldDescription,
  showDeleteButton,
  handleDeleteOption,
}: PropsFormDescription) => {
  const handleOnChangeTitle = (e: any) => {
    const value = e.target.value;
    setFieldValue(setFieldTitle, value);
  };

  const handleOnChangeDescription = (e: any) => {
    const value = e.target.value;

    setFieldValue(setFieldDescription, value);
  };
  return (
    <div className="flex">
      {showDeleteButton && (
        <div className={classNames("px-5", classes["btn_delete_container"])}>
          <ButtonComponent
            icon={
              <Icons.DeleteOutlined style={{ fontSize: "var(--fs_3xl)" }} />
            }
            onClick={handleDeleteOption}
          ></ButtonComponent>
        </div>
      )}
      <div className="flex flex-col gap-4 flex-grow">
        <div className="flex flex-col gap-2">
          <label
            style={{
              color: "var(--secondary_text)",
              fontWeight: "var(--fw_semibold)",
            }}
          >
            {i18NextText("product.form_label_name")}
          </label>
          <InputField
            value={values.title}
            type="text"
            name={setFieldTitle}
            placeholder={i18NextText("product.form_placeholder_name")}
            onChange={handleOnChangeTitle}
          />
          <p className={classes["error_message"]}>
            <ErrorMessage name={setFieldTitle} />
          </p>
        </div>
        <div className="flex flex-col gap-2">
          <label
            style={{
              color: "var(--secondary_text)",
              fontWeight: "var(--fw_semibold)",
            }}
          >
            {i18NextText("product.form_label_description")}
          </label>
          <TextArea
            autoSize={{ minRows: 2, maxRows: 10 }}
            value={values.description}
            name={setFieldDescription}
            placeholder={i18NextText("product.form_placeholder_description")}
            className={classes["description-textarea"]}
            onChange={handleOnChangeDescription}
          />
          <p className={classes["error_message"]}>
            <ErrorMessage name={setFieldDescription} />
          </p>
        </div>
        <Divider />
      </div>
    </div>
  );
};

export default FormDescription;
