import InfiniteScroll from "react-infinite-scroll-component";

import Collapse, { CustomPanel } from "CoreUI/Components/Common/Collapse";
import CheckboxComponent from "CoreUI/Components/Common/Checkbox";
import { PRODUCT_STATUS_DATA } from "Constants/Product.Constant";
import { i18NextText } from "Utils/I18Next.Util";
import { useStoreActions, useStoreState } from "Store";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import SelectComponent, { Option } from "CoreUI/Components/Common/Select";
import { Skeleton } from "antd";

const FilterContentComponent = () => {
  const filterParams = useStoreState(
    (state) => state.products.list.filterParams
  );

  const formatCategoryParams = () => {
    if (filterParams.category === "") {
      return [];
    } else {
      return filterParams.category.split(",");
    }
  };

  const categories = useStoreState(
    (state) => state.categories.listCategories.data
  );
  const loadingGetCate = useStoreState(
    (state) => state.categories.listCategories.loading
  );
  const pagination = useStoreState(
    (state) => state.categories.listCategories.pagination
  );

  const getProductsList = useStoreActions(
    (state) => state.products.list.getProductsList
  );

  const loadMoreCategoriesList = useStoreActions(
    (state) => state.categories.listCategories.loadMoreCategoriesList
  );

  const handleOnChangeFilterStatus = async (e: CheckboxChangeEvent) => {
    const value = e.target.value;
    const checked = e.target.checked;
    await getProductsList({ ...filterParams, status: checked ? value : "" });
  };

  const handleOnChangeFilterCategory = async (value: string[]) => {
    await getProductsList({ ...filterParams, category: value.join(",") });
  };

  const loadMoreCate = async () => {
    await loadMoreCategoriesList({ page: pagination.page + 1 });
  };

  return (
    <div>
      <Collapse expandIconPosition="right">
        <CustomPanel
          header={i18NextText("product.column_status")}
          key="date"
          style={{ minWidth: "303px" }}
        >
          <div className="flex items-center gap-4">
            {PRODUCT_STATUS_DATA.map((item, index) => (
              <CheckboxComponent
                checked={filterParams.status === item.value}
                onChange={handleOnChangeFilterStatus}
                className="m-0"
                key={index}
                value={item.value}
              >
                {item.label}
              </CheckboxComponent>
            ))}
          </div>
        </CustomPanel>
        <CustomPanel
          header={i18NextText("product.column_category")}
          key="payment_method"
          style={{ minWidth: "303px" }}
        >
          <SelectComponent
            mode="multiple"
            allowClear
            style={{ width: "100%", maxWidth: 271 }}
            placeholder="Select Category"
            value={formatCategoryParams()}
            onChange={handleOnChangeFilterCategory}
            dropdownRender={(children) => (
              <div
                id="scrollableDiv"
                style={{
                  height: 150,
                  overflow: "auto",
                }}
              >
                <InfiniteScroll
                  dataLength={categories.length}
                  next={loadMoreCate}
                  hasMore={
                    !!(
                      pagination.total_records >
                      pagination.page * pagination.per_page
                    ) && !loadingGetCate
                  }
                  loader={<Skeleton paragraph={{ rows: 1 }} active />}
                  scrollableTarget="scrollableDiv"
                >
                  {children}
                </InfiniteScroll>
              </div>
            )}
          >
            {categories.map((item, index) => (
              <Option key={index} value={item.name}>
                {item.name}
              </Option>
            ))}
          </SelectComponent>
        </CustomPanel>
      </Collapse>
    </div>
  );
};

export default FilterContentComponent;
