import { _get } from "Utils/Lodash.Util";
import { i18NextText } from "Utils/I18Next.Util";

import DeleteButton from "./DeleteButton";
import EditButton from "./EditButton";
import StatusActive from "./StatusActive";
import ImageComponent from "CoreUI/Components/Common/Image";

const columnsBrand = [
  {
    title: "ID",
    dataIndex: "id",
    width: 50,
    render: (text: any) => text,
    align: "center",
  },
  {
    title: i18NextText("brand.column_name"),
    dataIndex: "images_attributes",
    width: 250,
    render: (text, record) => {
      return (
        <div className="flex justify-start items-center gap-5">
          <ImageComponent
            src={record && _get(record, "images_attributes", "")[0]?.public_url}
            style={{
              borderRadius: "var(--br_DEFAULT)",
              width: "var(--sp_lv_14)",
              height: "var(--sp_lv_14)",
              minWidth: "var(--sp_lv_14)",
              marginRight: 20,
            }}
            preview={false}
          />
          <p>{record.name}</p>
        </div>
      );
    },
  },

  {
    title: i18NextText("brand.column_status"),
    dataIndex: "",
    width: 80,
    render: (text, record) => {
      return <StatusActive detailBrand={record} />;
    },
  },
  {
    title: "",
    dataIndex: "",
    align: "right",
    width: 100,
    render: (text, record) => {
      return <EditButton id={record.id} />;
    },
  },
  {
    title: "",
    dataIndex: "",
    width: 80,
    render: (text, record) => {
      return <DeleteButton id={record.id} />;
    },
  },
];

export default columnsBrand;
