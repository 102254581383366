// import { useNavigate } from "react-router-dom";

import { Icons } from "Components/Lib";
import { i18NextText } from "Utils/I18Next.Util";

const EditButtonComponent = ({ onEdit }) => {
  // const navigate = useNavigate();
  // const onEdit = () => {
  //   navigate(`${productId}/edit`);
  // };

  return (
    <div className="flex items-center gap-3" onClick={onEdit}>
      <Icons.EditOutlined /> <p>{i18NextText("option.btn_edit")}</p>
    </div>
  );
};

export default EditButtonComponent;
