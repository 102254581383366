import { Yup } from "Utils/Yup.Util";
import { i18NextText } from "Utils/I18Next.Util";

const MerchantSchema = Yup.object().shape({
  name: Yup.string().required(i18NextText("merchant.validator_name")),

  email: Yup.string()
    .email(i18NextText("merchant.validator_not_email"))
    .required(i18NextText("merchant.validator_email")),

  domain: Yup.string()
    .url(i18NextText("merchant.validator_url"))
    .required(i18NextText("merchant.validator_domain")),

  limit_rate: Yup.number()
    .typeError(i18NextText("merchant.validator_number"))
    .required(i18NextText("merchant.validator_limit_rate")),

  plan: Yup.string().required(i18NextText("merchant.validator_plan")),
});

export default MerchantSchema;
