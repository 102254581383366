import { useStoreState, useStoreActions } from "Store";
import DataTable from "CoreUI/Components/Common/DataTable";
import { i18NextText } from "Utils/I18Next.Util";
import { notification } from "Utils/Notification.Util";

import TitleComponent from "./Title.Component";
import columnsCategory from "./ColumnsCategory";

const TableCategoriesList = () => {
  const data = useStoreState((state) => state.categories.listCategories.data);
  const pagination = useStoreState(
    (state) => state.categories.listCategories.pagination
  );
  const loading = useStoreState(
    (state) => state.categories.listCategories.loading
  );

  const getCategoriesList = useStoreActions(
    (action) => action.categories.listCategories.getCategoriesList
  );

  const onPageChange = async (tablePagination) => {
    const response = await getCategoriesList({ page: tablePagination.current });
    if (!response.success) {
      notification.error({
        message: "",
        description: i18NextText("error.notification_error"),
      });
    }
  };

  return (
    <div style={{ padding: "var(--sp_lv_2) var(--sp_lv_3) var(--sp_lv_8)" }}>
      <DataTable
        data={data}
        columns={columnsCategory}
        rowKey={(record: any) => {
          return record.id;
        }}
        loading={loading}
        pagination={{
          current: pagination.page,
          pageSize: pagination.per_page,
          total: pagination.total_records,
        }}
        onChange={onPageChange}
        title={() => <TitleComponent />}
      />
    </div>
  );
};

export default TableCategoriesList;
