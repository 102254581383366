export const HTTP_CODE = {
  SERVER_NOT_RESPONSE: "ECONNABORTED",
  INTERNAL_SERVER_ERROR: 500,
  NO_INTERNET: 0,
  TOO_MANY_REQUESTS: 429,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
};

export const LOCAL_STORAGE_KEYS = {
  TOKEN: "token",
};

export const SIDE_BAR_SIZE = 225;
