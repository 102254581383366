import { useState } from "react";
import classNames from "classnames";
import {
  ErrorMessage,
  FieldArray,
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
} from "formik";

import { Divider, InputNumber } from "Components/Lib";
import { IDetailValues, IModalValues } from "Types/Product";
import { useModalContext } from "Context/Modal";
import ButtonComponent from "CoreUI/Components/Common/Button";
import InputField from "CoreUI/Components/Common/InputField";
import { i18NextText } from "Utils/I18Next.Util";
import { _cloneDeep } from "Utils/Lodash.Util";
// import EditVariantSchema from "./EditVariantSchema";

import classes from "./ModalEditVariant.module.scss";

interface IModalEditVariant {
  values: IDetailValues;
  nameVariant: string;
  setFieldVariant: FormikHelpers<IDetailValues>["setFieldValue"];
}

const ModalEditVariant = ({
  values,
  nameVariant,
  setFieldVariant,
}: IModalEditVariant) => {
  const { variants } = values;
  const { hideModal } = useModalContext();
  const [listVariantValue, setListVariantValue] = useState(variants);

  const [originalValue, setOriginalValue] = useState(values[nameVariant]);

  const AddAllVariant = (setFieldValue) => {
    const newListVariantValue = _cloneDeep(listVariantValue);
    newListVariantValue.forEach(
      (variant) => (variant[nameVariant] = originalValue)
    );
    setListVariantValue([...newListVariantValue]);
    setFieldValue("variants", newListVariantValue);
  };

  const onChangeValue = (value, variant_index, setFieldValue) => {
    const newListVariantValue = _cloneDeep(listVariantValue);
    newListVariantValue[variant_index][nameVariant] = value;

    setListVariantValue([...newListVariantValue]);
    setFieldValue(`variants[${variant_index}].${nameVariant}`, value);
  };

  const handleEditVariant = (values) => {
    setListVariantValue([...values.variants]);
    setFieldVariant("variants", values.variants);
    hideModal();
  };
  const initialValues = {
    variants: variants,
  };
  return (
    <Formik
      initialValues={initialValues}
      // validationSchema={EditVariantSchema}
      onSubmit={async (values: IModalValues) => {
        await handleEditVariant(values);
      }}
    >
      {(props: FormikProps<IModalValues>) => {
        const { setFieldValue, dirty } = props;
        return (
          <Form className={classNames(classes["form_edit_variant_container"])}>
            <div className={classNames(classes["edit_variant_fields"])}>
              <div className="space-y-5">
                <p>
                  {i18NextText("product.apply_a")}{" "}
                  {nameVariant === "sku"
                    ? "SKU"
                    : nameVariant === "original_price"
                    ? "original price"
                    : nameVariant}{" "}
                  {i18NextText("product.to_all_variants")}
                </p>
                <div className="flex justify-between gap-4">
                  {nameVariant === "sku" ? (
                    <>
                      <InputField
                        value={originalValue}
                        name="applyAllWithText"
                        onChange={(e) => setOriginalValue(e.target.value)}
                      />
                      <p className={classes["error_message"]}>
                        <ErrorMessage name="applyAllWithText" />
                      </p>
                    </>
                  ) : (
                    <>
                      <InputNumber
                        value={originalValue == 0 ? null : originalValue}
                        name="applyAllWithNumber"
                        placeholder="0.00"
                        onChange={(value: number) =>
                          setOriginalValue(Number(value))
                        }
                        className={classes["input_number_addAll"]}
                      />
                      <p className={classes["error_message"]}>
                        <ErrorMessage name="applyAllWithNumber" />
                      </p>
                    </>
                  )}
                  <ButtonComponent
                    onClick={() => AddAllVariant(setFieldValue)}
                    type="primary"
                  >
                    {i18NextText("product.apply_to_all")}
                  </ButtonComponent>
                </div>
              </div>
              <FieldArray
                name="variants"
                render={(props) => {
                  const { setFieldValue } = props.form;
                  return listVariantValue.map((variant, variant_index) => {
                    const name = variant.product_variant_options.map(
                      (ele) => ele.value
                    );
                    return (
                      <div key={variant_index}>
                        <Divider />
                        <div className="flex justify-between">
                          <p>{name.join("/")}</p>
                          <div className="max-w-[160px]">
                            {nameVariant === "title" ||
                            nameVariant === "sku" ? (
                              <InputField
                                value={variant[nameVariant]}
                                name={`variants[${variant_index}].${nameVariant}`}
                                onChange={(e) =>
                                  onChangeValue(
                                    e.target.value,
                                    variant_index,
                                    setFieldValue
                                  )
                                }
                              />
                            ) : (
                              <InputNumber
                                className={classes["input_number"]}
                                value={
                                  variant[nameVariant] == 0
                                    ? null
                                    : variant[nameVariant]
                                }
                                placeholder="0.00"
                                name={`variants[${variant_index}].${nameVariant}`}
                                onChange={(value: number) =>
                                  onChangeValue(
                                    Number(value),
                                    variant_index,
                                    setFieldValue
                                  )
                                }
                              />
                            )}

                            <p className={classes["error_message"]}>
                              <ErrorMessage
                                name={`variants[${variant_index}].${nameVariant}`}
                              />
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  });
                }}
              />
            </div>
            <Divider />
            <div
              className={classNames(
                "flex justify-end gap-6",
                classes["button_submit_form"]
              )}
            >
              <ButtonComponent onClick={() => hideModal()}>
                {i18NextText("product.cancel_btn")}
              </ButtonComponent>
              <ButtonComponent
                type="primary"
                htmlType="submit"
                disabled={!dirty}
              >
                {i18NextText("product.done_btn")}
              </ButtonComponent>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ModalEditVariant;
