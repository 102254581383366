import { useStoreState, useStoreActions } from "Store";
import { notification } from "Utils/Notification.Util";
import DataTable from "CoreUI/Components/Common/DataTable";
import { i18NextText } from "Utils/I18Next.Util";

import { columns } from "./ProductsColumn.Constants";
import TableTitleProduct from "./TableTitle";

const TableProductsListComponent = () => {
  const data = useStoreState((state) => state.products.list.data);
  const params = useStoreState((state) => state.products.list.pagination);
  const loading = useStoreState((state) => state.products.list.loading);

  const getProductsList = useStoreActions(
    (state) => state.products.list.getProductsList
  );

  const onPageChange = async (tablePagination) => {
    const response = await getProductsList({
      page: tablePagination.current,
    });
    if (!response.success) {
      notification.error({
        message: "",
        description: i18NextText("error.notification_error"),
      });
    }
  };

  return (
    <div style={{ padding: "var(--sp_lv_2) var(--sp_lv_3) var(--sp_lv_8)" }}>
      <DataTable
        data={data}
        columns={columns}
        rowKey={(record: any) => {
          return record.id;
        }}
        loading={loading}
        pagination={params}
        onChange={onPageChange}
        title={() => <TableTitleProduct />}
      />
    </div>
  );
};

export default TableProductsListComponent;
