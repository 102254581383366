import { FormikHelpers } from "formik";

import { Icons, Menu } from "Components/Lib";
import ButtonComponent from "CoreUI/Components/Common/Button";
import DropdownComponent from "CoreUI/Components/Common/DropDown";
import { i18NextText } from "Utils/I18Next.Util";
import VariantButtonComponent from "Components/Own/VariantButtonComponent";
import ModalEditVariant from "Components/Own/ModalEditVariant";
import { IDetailValues } from "Types/Product";

interface IHeaderVariant {
  setFieldValue: FormikHelpers<IDetailValues>["setFieldValue"];
  values: IDetailValues;
}

const HeaderVariant = ({ setFieldValue, values }: IHeaderVariant) => {
  const menu = (
    <Menu
      items={[
        {
          label: (
            <VariantButtonComponent
              content={i18NextText("product.content_price")}
              modalEditVariant={
                <ModalEditVariant
                  values={values}
                  nameVariant="price"
                  setFieldVariant={setFieldValue}
                />
              }
            />
          ),
          key: "0",
        },
        {
          label: (
            <VariantButtonComponent
              content={i18NextText("product.content_price_original")}
              modalEditVariant={
                <ModalEditVariant
                  values={values}
                  nameVariant="original_price"
                  setFieldVariant={setFieldValue}
                />
              }
            />
          ),
          key: "1",
        },
        {
          label: (
            <VariantButtonComponent
              content={i18NextText("product.content_quantity")}
              modalEditVariant={
                <ModalEditVariant
                  values={values}
                  nameVariant="quantity"
                  setFieldVariant={setFieldValue}
                />
              }
            />
          ),
          key: "2",
        },
        {
          label: (
            <VariantButtonComponent
              content={i18NextText("product.content_SKU")}
              modalEditVariant={
                <ModalEditVariant
                  values={values}
                  nameVariant="sku"
                  setFieldVariant={setFieldValue}
                />
              }
            />
          ),
          key: "3",
        },
        // {
        //   label: (
        //     <VariantButtonComponent
        //       content={i18NextText("product.content_weight")}
        //       modalEditVariant={
        //         <ModalEditVariant
        //           values={values}
        //           nameVariant="weight"
        //           setFieldVariant={setFieldValue}
        //         />
        //       }
        //     />
        //   ),
        //   key: "4",
        // },
      ]}
    />
  );
  return (
    <div className="flex justify-end">
      <DropdownComponent
        overlay={menu}
        trigger={["click"]}
        placement="bottomLeft"
        hideArrow
        overlayClassName="min-w-0"
      >
        <ButtonComponent>
          {i18NextText("product.btn_edit")}
          <Icons.CaretDownOutlined />
        </ButtonComponent>
      </DropdownComponent>
    </div>
  );
};

export default HeaderVariant;
