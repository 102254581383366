import { useEffect, useState } from "react";
import { ErrorMessage, FormikHelpers } from "formik";

import { useStoreActions, useStoreState } from "Store";
import { IDetailValues } from "Types/Product";
import { i18NextText } from "Utils/I18Next.Util";
import { Select } from "Components/Lib";

interface IFieldCategory {
  values: IDetailValues;
  setFieldValue: FormikHelpers<IDetailValues>["setFieldValue"];
}

const FieldCategory = ({ values, setFieldValue }: IFieldCategory) => {
  const listCategories = useStoreState(
    (state) => state.products.detail.dataSearchCategory
  );
  const getListCategories = useStoreActions(
    (action) => action.products.detail.getCategoryAndSubcategoryList
  );

  const [value, setValue] = useState(values.category_id || "");

  useEffect(() => {
    getListCategories({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = listCategories.map((category) => ({
    value: category.id,
    label: category.name,
  }));
  return (
    <div className="flex flex-col gap-2">
      <label
        style={{
          color: "var(--secondary_text)",
          fontWeight: "var(--fw_semibold)",
        }}
      >
        {i18NextText("product.form_label_category")}
      </label>
      <Select
        value={value}
        options={options}
        showSearch={true}
        onChange={(value) => setValue(value)}
        onSelect={(value) => setFieldValue("category_id", value)}
        placeholder="Input here"
        optionFilterProp="children"
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
      />

      <p style={{ color: "var(--red_5)" }}>
        <ErrorMessage name="category_id" />
      </p>
    </div>
  );
};

export default FieldCategory;
