import {
  Actions,
  createStore,
  createTypedHooks,
  Dispatch,
  Meta,
  State,
  AnyFunction,
} from "easy-peasy";

import storeModel, { StoreModel } from "./Models/storeModel";

const store = createStore(storeModel, { name: "FreshSiteVersion2" });

export type Helpers<Model extends object, Injections> = {
  dispatch: Dispatch<StoreModel>;
  fail: AnyFunction;
  getState: () => State<Model>;
  getStoreActions: () => Actions<StoreModel>;
  getStoreState: () => State<StoreModel>;
  injections: Injections;
  meta: Meta;
};

const { useStoreActions, useStoreState, useStoreDispatch, useStore } =
  createTypedHooks<StoreModel>();

export { useStoreActions, useStoreState, useStoreDispatch, useStore };

export default store;
