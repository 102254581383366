import { persist } from "easy-peasy";

//Store
import auth from "./Auth";
import products, { ProductsModel } from "./Product";
import customers, { CustomersModel } from "./Customer";
import merchants, { MerchantModel } from "./Merchant";
import categories, { CategoriesModel } from "./Category";
import brands, { BrandsModel } from "./Brand";
import orders, { OrdersModel } from "./Order";
import reviews, { ReviewsModel } from "./Review";

//Model
import { AuthModel } from "./Auth/Type";

export interface StoreModel {
  auth: AuthModel;
  products: ProductsModel;
  customers: CustomersModel;
  merchants: MerchantModel;
  categories: CategoriesModel;
  brands: BrandsModel;
  orders: OrdersModel;
  reviews: ReviewsModel;
}

const storeModel: StoreModel = {
  auth: persist(auth, { storage: "localStorage" }),
  products: products,
  customers: customers,
  merchants: merchants,
  categories: categories,
  brands: brands,
  orders: orders,
  reviews: reviews,
};

export default storeModel;
