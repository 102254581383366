import React from "react";

import { Layout } from "Components/Lib";

import Sider from "./Sider.Component";

const { Content } = Layout;

type PrivateLayoutProps = {
  children: React.ReactNode;
};

const PrivateLayout = ({ children }: PrivateLayoutProps) => {
  return (
    <Layout className="h-[100vh] overflow-hidden bg-[#F3F4F6]" hasSider>
      <Sider />

      <Layout className="overflow-hidden overflow-y-auto">
        <Content
          style={{
            flex: 1,
            marginLeft: 10,
            marginRight: 10,
            overflow: "auto",
            marginTop: "var(--sp_lv_6)",
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default PrivateLayout;
