import classNames from "classnames";
import { ErrorMessage, Form, Formik, FormikHelpers, FormikProps } from "formik";

import { Divider, InputNumber } from "Components/Lib";
import { IDetailValues, IVariantsCreate } from "Types/Product";
import { useModalContext } from "Context/Modal";
import ButtonComponent from "CoreUI/Components/Common/Button";
import InputField from "CoreUI/Components/Common/InputField";
import { i18NextText } from "Utils/I18Next.Util";
import { _get } from "Utils/Lodash.Util";
// import EditVariantSchema from "./EditVariantSchema";

import classes from "./EditVariant.module.scss";
import VariantImages from "./VariantImages";

interface IEditVariant {
  variant: IVariantsCreate;
  indexVariant: number;
  setFieldVariant: FormikHelpers<IDetailValues>["setFieldValue"];
}

const EditVariant = ({
  variant,
  indexVariant,
  setFieldVariant,
}: IEditVariant) => {
  const { hideModal } = useModalContext();

  const handleEditVariant = (values) => {
    setFieldVariant(`variants[${indexVariant}]`, values);
    hideModal();
  };
  const initialValues = {
    ...variant,
    images_attributes: _get(variant, "images_attributes", []).map((image) => ({
      ...image,
      uid: image.id?.toString(),
      name: "",
      status: "done",
      url: image.public_url,
      isValid: true,
      isUploaded: true,
    })),
  };
  return (
    <Formik
      initialValues={initialValues}
      // validationSchema={EditVariantSchema}
      onSubmit={async (values: IVariantsCreate) => {
        await handleEditVariant(values);
      }}
    >
      {(props: FormikProps<IVariantsCreate>) => {
        const { values, setFieldValue, dirty } = props;
        return (
          <Form className={classNames(classes["form_edit_variant_container"])}>
            <div
              className={classNames(
                "flex flex-col gap-5",
                classes["edit_variant_fields"]
              )}
            >
              <div className="flex flex-col gap-2">
                <label>{i18NextText("product.form_label_price")}</label>

                <InputNumber
                  value={values.price}
                  name="price"
                  placeholder="0.00"
                  onChange={(value) => setFieldValue("price", Number(value))}
                  className={classes["input_number"]}
                />
                <p className={classes["error_message"]}>
                  <ErrorMessage name="price" />
                </p>
              </div>
              <div className="flex flex-col gap-2">
                <label>
                  {i18NextText("product.form_label_original_price")}
                </label>

                <InputNumber
                  value={values.original_price}
                  name="original_price"
                  placeholder="0.00"
                  onChange={(value: number) =>
                    setFieldValue("original_price", Number(value))
                  }
                  className={classes["input_number"]}
                />
                <p className={classes["error_message"]}>
                  <ErrorMessage name="original_price" />
                </p>
              </div>

              <div className="flex flex-col gap-2">
                <label>{i18NextText("product.form_label_quantity")}</label>

                <InputNumber
                  value={values.quantity}
                  name="quantity"
                  placeholder="0.00"
                  onChange={(value: number) =>
                    setFieldValue("quantity", Number(value))
                  }
                  className={classes["input_number"]}
                />
                <p className={classes["error_message"]}>
                  <ErrorMessage name="quantity" />
                </p>
              </div>
              <div className="flex flex-col gap-2">
                <label>{i18NextText("product.form_label_SKU")}</label>

                <InputField
                  value={values.sku}
                  name="sku"
                  onChange={(e) => setFieldValue("sku", e.target.value)}
                />
                <p className={classes["error_message"]}>
                  <ErrorMessage name="sku" />
                </p>
              </div>
              <VariantImages
                images_attributes={values.images_attributes}
                setFieldValue={setFieldValue}
              />
            </div>
            <Divider />
            <div
              className={classNames(
                "flex justify-end gap-6",
                classes["button_submit_form"]
              )}
            >
              <ButtonComponent onClick={() => hideModal()}>
                {i18NextText("product.cancel_btn")}
              </ButtonComponent>
              <ButtonComponent
                type="primary"
                htmlType="submit"
                disabled={!dirty}
              >
                {i18NextText("product.done_btn")}
              </ButtonComponent>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditVariant;
