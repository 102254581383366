import React from "react";

import { i18NextText } from "Utils/I18Next.Util";

import ClearFilterButton from "./ClearFilterButton";
import FilterButton from "./FilterButton";
import SearchOrder from "./SearchOrder";

const TableTitleProduct = () => {
  return (
    <div className="flex justify-between items-center">
      <h4>{i18NextText("order.title_table")}</h4>
      <div className="flex justify-end flex-1 items-center gap-4">
        <SearchOrder />
        <FilterButton />
        <ClearFilterButton />
      </div>
    </div>
  );
};

export default React.memo(TableTitleProduct);
