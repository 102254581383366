import i18n from "i18next";
import { _toString } from "./Lodash.Util";

export const i18NextText = (key: string, params?: any) => {
  const text = i18n.t(key, params);

  if (typeof text !== "string") {
    return _toString(text);
  }
  return text;
};
