export const PRODUCT_STATUS = {
  ACTIVE: "active",
  DRAFT: "draft",
  DEACTIVATE: "deactivate",
};

export const PRODUCT_STATUS_DATA = [
  {
    label: "Active",
    value: PRODUCT_STATUS.ACTIVE,
  },
  {
    label: "Deactivate",
    value: PRODUCT_STATUS.DEACTIVATE,
  },
  {
    label: "Draft",
    value: PRODUCT_STATUS.DRAFT,
  },
];
