import { action, thunk } from "easy-peasy";

import { getMerchantsData } from "Services/Merchants.Services";

import { ListMerchantModel } from "./Type";

export const INITIAL_AUTH_STATE = {
  loading: true,
  data: [],
  pagination: {
    page: 1,
    per_page: 5,
    keyword: "",
    next_page: null,
    total_records: 0,
  },
};

const list: ListMerchantModel = {
  ...INITIAL_AUTH_STATE,

  setLoading: action((state, payload: boolean): void => {
    state.loading = payload;
  }),

  saveData: action((state, { data, pagination }): void => {
    state.data = data;
    state.pagination = { ...state.pagination, ...pagination };
    state.loading = false;
  }),

  getMerchantsList: thunk(async (actions, { page }, { getState }) => {
    const state = getState();
    if (!state.loading) actions.setLoading(true);

    const params = {
      ...state.pagination,
      page: page ? page : 1,
    };

    const { data, success, pagination } = await getMerchantsData(params);

    if (success) {
      actions.saveData({ data, pagination: { ...params, ...pagination } });
    } else {
      actions.setLoading(false);
    }
    return { success };
  }),
};

export default list;
