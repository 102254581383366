import moment from "moment";

const DEFAULT_BE_FORMAT = "YYYY-MM-DD";
const DEFAULT_FE_FORMAT = "DD-MM-YYYY";

export const formatDate = ({
  date,
  format,
}: {
  date: string | any;
  format: string;
}) => {
  if (!date) return null;
  return moment(date, DEFAULT_BE_FORMAT).format(format || DEFAULT_FE_FORMAT);
};

export const formatMoment = (date: string | any, format?: string) => {
  if (format) {
    return moment(date).format(format);
  }
  return moment(date).format("DD-MM-YYYY");
};

export default moment;
