import React from "react";
import { Icons } from "Components/Lib";
import { i18NextText } from "Utils/I18Next.Util";
import { useModalContext } from "Context/Modal";
import ModalConfirm from "Components/Own/ModelConfirm";

interface IDeleteButtonComponent {
  onDelete: () => void;
  loading?: boolean;
}

const DeleteButtonComponent = ({
  onDelete,
  loading,
}: IDeleteButtonComponent) => {
  const { updateModal } = useModalContext();

  const showConfirm = ({ modalContent }: { modalContent: React.ReactNode }) => {
    updateModal(modalContent, {
      showTitle: true,
      width: 400,
      height: "auto",
    });
  };

  return (
    <div
      className="flex items-center gap-3"
      onClick={() =>
        showConfirm({
          modalContent: (
            <ModalConfirm
              loading={loading}
              onClick={onDelete}
              title={i18NextText("option.show_confirm_title")}
            />
          ),
        })
      }
    >
      <Icons.DeleteOutlined />
      <p>{i18NextText("option.btn_delete")}</p>
    </div>
  );
};

export default DeleteButtonComponent;
