import React from "react";
import { Dropdown, DropDownProps } from "antd";

// import Image from "CoreUI/Components/Common/Image";

import { DropdownComponentProps } from "../types";

// import ArrowDownIcon from "Assets/svg/arrow_down.svg";
import classes from "./Dropdown.module.scss";
import classNames from "classnames";

const CustomDropdown: React.FC<DropDownProps & { children?: any }> = (
  props
) => {
  return <Dropdown {...props} />;
};

const DropdownComponent: React.FC<
  DropdownComponentProps & { hideArrow: boolean }
> = ({ icon, placement, overlay, hideArrow, children, ...rest }) => {
  return (
    <CustomDropdown
      className={classes["dropdown_box"]}
      overlay={overlay}
      trigger={["click"]}
      placement={placement}
      {...rest}
    >
      {icon ? (
        <div className="cursor-pointer flex items-center justify-center">
          {children}
          {icon}
        </div>
      ) : (
        <div className="cursor-pointer flex items-center justify-center">
          {children}
          <div
            className={classNames({
              hidden: hideArrow,
            })}
          >
            {/* <Image src={ArrowDownIcon} width={12} height={7} alt="arrow" /> */}
          </div>
        </div>
      )}
    </CustomDropdown>
  );
};
export default DropdownComponent;
