import React from "react";
import { FieldArray, FormikHelpers } from "formik";
import classNames from "classnames";

import { Icons, Tag, Divider } from "Components/Lib";
import ButtonComponent from "CoreUI/Components/Common/Button";
import InputField from "CoreUI/Components/Common/InputField";

import {
  IDetailValues,
  IOptionAttributes,
  IProductOptionAttributes,
  IVariants,
} from "Types/Product";
import { i18NextText } from "Utils/I18Next.Util";

import classes from "./OptionAttributesComponent.module.scss";
import useOptionAttributes from "./OptionAttributes.Hook";

interface IOptionVariant {
  option_attributes?: IOptionAttributes;
  product_options_attributes_index?: number;
  setFieldValue: FormikHelpers<IDetailValues>["setFieldValue"];
  product_options_attributes: IProductOptionAttributes[];
  variants: IVariants[];
}

const OptionAttributesComponent = ({
  option_attributes,
  product_options_attributes_index,
  setFieldValue,
  product_options_attributes,
  variants,
}: IOptionVariant) => {
  const {
    toggleEditOption,
    optionAttributes,
    onClickDone,
    handleOption,
    handleChangeOptionValue,
    handleDeleteOption,
    handleDeleteValueOption,
    handleEditOption,
    fieldError,
  } = useOptionAttributes({
    option_attributes,
    product_options_attributes_index,
    setFieldValue,
    product_options_attributes,
    variants,
  });

  return (
    <React.Fragment>
      <div className="space-y-5" style={{ padding: "0 var(--sp_lv_10) 0" }}>
        {toggleEditOption ? (
          <>
            <p
              style={{
                color: "var(--secondary_text)",
                fontWeight: "var(--fw_semibold)",
              }}
            >
              {i18NextText("product.option_name")}
            </p>
            <div className="flex justify-between items-center gap-9">
              <div className="w-[100%]">
                <InputField
                  autoComplete="off"
                  name={`product_option_attributes[${product_options_attributes_index}].option_attributes.name`}
                  value={optionAttributes.name}
                  type="text"
                  list="list"
                  onChange={(e) => handleOption(e)}
                />
                <datalist id="list">
                  <option>{i18NextText("product.select_size")}</option>
                  <option>{i18NextText("product.select_color")}</option>
                  <option>{i18NextText("product.select_material")}</option>
                  <option>{i18NextText("product.select_style")}</option>
                </datalist>
                <p className={classes["errorField"]}>{fieldError.name}</p>
              </div>
              <ButtonComponent
                className={classes["btn_delete"]}
                icon={
                  <Icons.DeleteOutlined
                    style={{ fontSize: "var(--fs_3xl)", color: "var(--red_5)" }}
                  />
                }
                onClick={handleDeleteOption}
              ></ButtonComponent>
            </div>
            <p
              style={{
                color: "var(--secondary_text)",
                fontWeight: "var(--fw_semibold)",
              }}
            >
              {i18NextText("product.option_values")}
            </p>
            <FieldArray
              name={`product_option_attributes[${product_options_attributes_index}].option_attributes.name`}
              render={() => {
                return optionAttributes.option_values_attributes.map(
                  (option_values_attribute, option_values_attribute_index) => (
                    <div
                      key={option_values_attribute_index}
                      className="flex justify-between items-center gap-9"
                    >
                      <div className="w-[100%]">
                        <InputField
                          name={`product_option_attributes[${product_options_attributes_index}].option_attributes.option_values_attributes[${option_values_attribute_index}].value`}
                          value={option_values_attribute.value}
                          onChange={(e) =>
                            handleChangeOptionValue(
                              e.target.value,
                              option_values_attribute_index
                            )
                          }
                        />
                        <p className={classes["errorField"]}>
                          {fieldError.option_values_attributes}
                        </p>
                      </div>
                      <ButtonComponent
                        className={classes["btn_delete"]}
                        icon={
                          <Icons.DeleteOutlined
                            style={{
                              fontSize: "var(--fs_3xl)",
                              color: "var(--red_5)",
                            }}
                          />
                        }
                        style={{
                          visibility: `${
                            optionAttributes.option_values_attributes.length ===
                              1 ||
                            optionAttributes.option_values_attributes.length -
                              1 ===
                              option_values_attribute_index
                              ? "hidden"
                              : "visible"
                          }`,
                        }}
                        onClick={() =>
                          handleDeleteValueOption(option_values_attribute_index)
                        }
                      ></ButtonComponent>
                    </div>
                  )
                );
              }}
            />
            <ButtonComponent onClick={onClickDone}>
              {i18NextText("product.btn_done")}
            </ButtonComponent>
          </>
        ) : (
          <>
            <div className="flex justify-between items-center">
              <div>
                <div style={{ fontWeight: "var(--fw_semibold)" }}>
                  {option_attributes.name}
                </div>
                <div>
                  {option_attributes.option_values_attributes.map(
                    (e, index) => (
                      <Tag key={index}>{e.value}</Tag>
                    )
                  )}
                </div>
              </div>
              <ButtonComponent onClick={handleEditOption}>
                {i18NextText("product.btn_edit")}
              </ButtonComponent>
            </div>
          </>
        )}
      </div>
      <Divider />
    </React.Fragment>
  );
};

export default OptionAttributesComponent;
