import classNames from "classnames";
import { FieldArray, FormikHelpers } from "formik";

import { Icons, Divider } from "Components/Lib";
import ButtonComponent from "CoreUI/Components/Common/Button";
import { i18NextText } from "Utils/I18Next.Util";
import { IDetailCategory, ISubCategory } from "Types/Category";

import SubcategoryComponent from "./SubcategoryComponent";
import classes from "./Subcategory.module.scss";

export interface ISubcategories {
  setFieldValue: FormikHelpers<IDetailCategory>["setFieldValue"];
  sub_categories_attributes: ISubCategory[];
}

const Subcategories = ({
  sub_categories_attributes,
  setFieldValue,
}: ISubcategories) => {
  const initialValues = {
    id: null,
    name: "",
    images_attributes: [],
    image_banners_attributes: [],
  };

  const handleAddSubcategory = () => {
    setFieldValue("sub_categories_attributes", [
      ...sub_categories_attributes,
      { ...initialValues },
    ]);
  };

  return (
    <>
      <div className={classNames(classes["subcategory_container"])}>
        <div className={classNames(classes["heading"])}>
          <h2>{i18NextText("category.subcategories")}</h2>
        </div>
        <Divider />
        <div>
          <>
            <FieldArray
              name="sub_categories_attributes"
              render={(props) => {
                const { setFieldValue, values } = props.form;
                return sub_categories_attributes.map(
                  (subcategory, sub_categories_attributes_index) => {
                    return (
                      <SubcategoryComponent
                        key={sub_categories_attributes_index}
                        values={values}
                        setFieldValue={setFieldValue}
                        index_subcategory={sub_categories_attributes_index}
                      />
                    );
                  }
                );
              }}
            />
          </>
        </div>
        <div className={classNames(classes["btn_add_subcategory_container"])}>
          <ButtonComponent
            className={classNames(classes["btn_add_subcategory"])}
            onClick={handleAddSubcategory}
          >
            <Icons.PlusOutlined />
            {i18NextText("category.btn_add_subcategory")}
          </ButtonComponent>
        </div>
      </div>
    </>
  );
};

export default Subcategories;
