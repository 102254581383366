import { action, thunk } from "easy-peasy";
import { getProductsDataService } from "Services/Products.Services";

import { IProductsState, ListProductModel } from "./Type";

export const DEFAULT_FILTER_PARAMS = {
  status: "",
  keyword: "",
  category: "",
};

export const DEFAULT_PAGE_PARAMS = { current: 1, pageSize: 6, total: 0 };

export const INITIAL_AUTH_STATE: IProductsState = {
  loading: true,
  data: [],
  filterParams: DEFAULT_FILTER_PARAMS,
  pagination: DEFAULT_PAGE_PARAMS,
};

const list: ListProductModel = {
  ...INITIAL_AUTH_STATE,

  setLoading: action((state, payload: boolean): void => {
    state.loading = payload;
  }),

  saveData: action((state, { data, params }): void => {
    state.data = data;
    state.pagination.current = params.page;
    state.pagination.pageSize = params.per_page;
    state.pagination.total = params.total_records;
    state.filterParams.category = params.category;
    state.filterParams.keyword = params.keyword;
    state.filterParams.status = params.status;
    state.loading = false;
  }),

  setKeyword: action((state: IProductsState, payload): void => {
    state.filterParams.keyword = payload;
  }),

  getProductsList: thunk(async (actions, args, { getState }) => {
    const state = getState();
    const params = state.pagination;
    if (!state.loading) actions.setLoading(true);

    const formatParams = {
      ...state.pagination,
      ...state.filterParams,
      page: params.current,
      per_page: params.pageSize,
      ...args,
    };

    const { data, success, pagination } = await getProductsDataService({
      ...formatParams,
      page: args?.page ? args?.page : params.current,
      per_page: params.pageSize,
    });

    if (success) {
      actions.saveData({ data, params: { ...formatParams, ...pagination } });
    } else {
      actions.setLoading(false);
    }
    return { success };
  }),
};

export default list;
