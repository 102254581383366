import { i18NextText } from "Utils/I18Next.Util";
import { formatMoment } from "Utils/Moment.Util";
import { formatCurrencyVN } from "Utils/Common.Util";
import TagComponent from "CoreUI/Components/Common/Tag";
import { _upperFirst } from "Utils/Lodash.Util";

import SortByStatus from "./SortByStatus";
import SelectAction from "./SelectAction";

const columnsOrder = [
  {
    title: i18NextText("order.column_order_no"),
    dataIndex: "id",
    width: 50,
    render: (text) => text,
    align: "center",

    fixed: "left",
  },
  {
    title: i18NextText("order.column_customer_name"),
    dataIndex: "user_name",
    width: 150,
    render: (name: string) => name,
  },
  {
    title: i18NextText("order.column_order_date"),
    dataIndex: "created_at",
    width: 120,
    render: (text: any) => formatMoment(text, "MMMM Do YYYY, h:mm a"),
  },
  {
    title: i18NextText("order.column_total"),
    dataIndex: "total",
    width: 80,
    render: (text: any) => formatCurrencyVN(text),
    align: "right",
  },
  {
    title: i18NextText("order.column_action"),
    dataIndex: "status",
    width: 80,
    render: (text: any, record) => (
      <div className="flex justify-center">
        <SelectAction data={record} />
      </div>
    ),
    align: "center",
  },
  {
    title: (
      <div className="flex gap-2 items-center">
        {i18NextText("order.column_status")} <SortByStatus />
      </div>
    ),
    dataIndex: "status",
    width: 60,
    align: "center",
    render: (text: any) =>
      text == null ? (
        <TagComponent color="red">
          {i18NextText("order.status_new")}
        </TagComponent>
      ) : (
        <TagComponent
          color={`${
            text == "cancelled"
              ? "gold"
              : text == "completed"
              ? "#87d068"
              : "#2db7f5"
          }`}
        >
          {_upperFirst(text)}
        </TagComponent>
      ),
  },
];

export default columnsOrder;
