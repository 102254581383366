import { Collapse, CollapseProps, CollapsePanelProps } from "antd";
import { RightOutlined } from "@ant-design/icons";
import classnames from "classnames";

import classes from "./Collapse.module.scss";

const { Panel } = Collapse;

const CustomPanel: React.FC<CollapsePanelProps & { children?: any }> = (
  props
) => {
  return <Panel {...props} />;
};

const CollapseComponent: React.FC<CollapseProps & { children?: any }> = (
  props
) => {
  return (
    <Collapse
      {...props}
      expandIcon={({ isActive }) => (
        <RightOutlined
          width={12}
          height={12}
          rotate={isActive ? 90 : 0}
          className={classnames(classes["collapse-dropdown-icon"], {
            [classes["collapse-dropdown-icon_active"]]: isActive,
          })}
        />
      )}
    />
  );
};

export { CustomPanel };
export default CollapseComponent;
