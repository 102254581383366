import { Yup } from "Utils/Yup.Util";
import { i18NextText } from "Utils/I18Next.Util";

const CustomerSchema = Yup.object().shape({
  first_name: Yup.string().required(
    i18NextText("customers.validator_first_name")
  ),
  last_name: Yup.string().required(
    i18NextText("customers.validator_last_name")
  ),
  gender: Yup.string().required(i18NextText("customers.validator_gender")),
  email: Yup.string()
    .email(i18NextText("customers.validator_not_email"))
    .required(i18NextText("customers.validator_email")),
  address: Yup.string().required(i18NextText("customers.validator_address")),
});

export default CustomerSchema;
