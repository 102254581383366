import listBrands from "./ListBrands";
import detailBrand from "./DetailBrand";
import { ListBrandsModel } from "./ListBrands/Type";
import { BrandDetailModel } from "./DetailBrand/Type";

export interface BrandsModel {
  listBrands: ListBrandsModel;
  detailBrand: BrandDetailModel;
}

const brands = { listBrands, detailBrand };

export default brands;
