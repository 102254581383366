import { ErrorMessage, FormikHelpers } from "formik";

import SelectComponent from "CoreUI/Components/Common/Select";
import { IDetailValues } from "Types/Product";
import { i18NextText } from "Utils/I18Next.Util";
import FieldCategory from "./FieldCategory";
import FieldBrand from "./FieldBrand";

interface IProductOrganization {
  values: IDetailValues;
  setFieldValue: FormikHelpers<IDetailValues>["setFieldValue"];
}

const ProductOrganization = ({
  values,
  setFieldValue,
}: IProductOrganization) => {
  return (
    <div
      className="flex flex-col gap-5"
      style={{
        background: "var(--gray_1)",
        borderRadius: "var(--br_lg)",
        padding: "var(--sp_lv_6)",
      }}
    >
      <h2>{i18NextText("product.organization")}</h2>

      <FieldCategory values={values} setFieldValue={setFieldValue} />
      <FieldBrand values={values} setFieldValue={setFieldValue} />
      <div className="flex flex-col gap-2">
        <label
          style={{
            color: "var(--secondary_text)",
            fontWeight: "var(--fw_semibold)",
          }}
        >
          {i18NextText("product.form_label_tags")}
        </label>
        <SelectComponent
          value={values.tag_list}
          mode="tags"
          style={{ width: "100%" }}
          placeholder="Tags Mode"
          onChange={(value) => setFieldValue("tag_list", value)}
        />
        <p style={{ color: "var(--red_5)" }}>
          <ErrorMessage name="tag_list" />
        </p>
      </div>
    </div>
  );
};

export default ProductOrganization;
