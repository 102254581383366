import classNames from "classnames";
import { PATH_NAME } from "Constants/PathName.Constant";
import ButtonComponent from "CoreUI/Components/Common/Button";
import InputField from "CoreUI/Components/Common/InputField";
import InputPassword from "CoreUI/Components/Common/InputPassword";
import { ErrorMessage, Form, Formik, FormikProps } from "formik";

import { useNavigate } from "react-router-dom";
import { useStoreActions, useStoreState } from "Store";
import { IDetailValues } from "Types/SignIn";
import { i18NextText } from "Utils/I18Next.Util";

import classes from "./SignIn.module.scss";
import SignInSchema from "./SignInSchema";

const SignIn = () => {
  const navigate = useNavigate();
  const login = useStoreActions((action) => action.auth.login);
  const loading = useStoreState((state) => state.auth.loading);

  const initialValues = {
    email: "",
    password: "",
    error: null,
  };

  return (
    <div
      className={classNames(
        "flex flex-col justify-center items-center",
        classes["signIn-Page-container"]
      )}
    >
      <h1>{i18NextText("auth.sign_In")}</h1>
      <Formik
        initialValues={initialValues}
        validationSchema={SignInSchema}
        onSubmit={async (values: IDetailValues, { setFieldValue }) => {
          const { email, password } = values;

          const response = await login({
            email: email,
            password: password,
          });
          if (response.success) {
            navigate(PATH_NAME.PRODUCTS);
          } else {
            setFieldValue("error", response.error);
          }
        }}
      >
        {(props: FormikProps<IDetailValues>) => {
          const { values, handleChange, setFieldValue } = props;
          return (
            <>
              <Form
                className={classNames(
                  "flex flex-col gap-6",
                  classes["form-signIn-container"]
                )}
              >
                <label>{i18NextText("auth.signIn_label_email")}</label>
                <InputField
                  type="text"
                  name="email"
                  placeholder={i18NextText("auth.signIn_place_holder_email")}
                  onChange={(e) =>
                    setFieldValue("email", e.target.value.trim())
                  }
                />
                <p className={classNames(classes["error_message"])}>
                  <ErrorMessage name="email" />
                </p>
                <label>{i18NextText("auth.signIn_label_pass")}</label>
                <InputPassword
                  type="password"
                  name="password"
                  placeholder={i18NextText("auth.signIn_place_holder_password")}
                  onChange={handleChange}
                  className={classNames(classes["input_password"])}
                />
                <p className={classNames(classes["error_message"])}>
                  <ErrorMessage name="password" />
                </p>

                {!!values.error && (
                  <p className={classNames(classes["error_message"])}>
                    {values.error}
                  </p>
                )}

                <a href={PATH_NAME.FORGOT_PASSWORD}>Forgot password?</a>
                <ButtonComponent
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  {i18NextText("auth.btn_login")}
                </ButtonComponent>
              </Form>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default SignIn;
