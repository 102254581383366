import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from "axios";

import { HTTP_CODE } from "Constants/Common.Constant";
import { LOCAL_STORAGE } from "Constants/LocalStorage.Constant";
import { PATH_NAME } from "Constants/PathName.Constant";
import { clearItems, getItem } from "Utils/LocalStorage.Util";
import normalizeError from "Utils/NomalizeError.Util";

export const createAxios = (requestOptions?: AxiosRequestConfig) => {
  const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_API_HOST}`,
    withCredentials: false,
  });

  const interceptorsRequest = (config: AxiosRequestConfig) => {
    const token = getItem(LOCAL_STORAGE.TOKEN);
    // const token = "helen";

    // Do something before request is sent
    config.headers = {
      ...config.headers,
      "Access-Control-Allow-Origin": "*",
      "RexyAdmin-Authorization": token,
    };
    config.timeout = 1000 * 60 * 10; // miliseconds - 10 mins

    const myConfigs = { ...config, ...requestOptions };

    return myConfigs;
  };
  const interceptorsResponse = (response: AxiosResponse) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    return response;
  };

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    interceptorsRequest,
    (error: AxiosError) => Promise.reject(handleDataError(error))
  );

  // Add a response interceptor
  axiosInstance.interceptors.response.use(
    interceptorsResponse,
    (error: AxiosError) => Promise.reject(handleDataError(error))
  );
  return axiosInstance;
};

export function handleDataError(error: AxiosError) {
  let message: any;

  if (error.response.status === HTTP_CODE.UNAUTHORIZED) {
    message = "Token expired. Please signin again!";
    clearItems();
    window.location.href = PATH_NAME.SIGN_IN;
  }

  if (error.response.status === HTTP_CODE.FORBIDDEN) {
    message = "You don't have permission to access";
    window.location.href = PATH_NAME.FORBIDDEN;
  }

  if (
    error?.code === HTTP_CODE.SERVER_NOT_RESPONSE ||
    error.response?.status === HTTP_CODE.INTERNAL_SERVER_ERROR
  ) {
    message = "Internal Server Error";
  }

  if (error.request?.status === HTTP_CODE.NO_INTERNET) {
    message = "No Internet Connection.";
  }
  if (error.response?.status === HTTP_CODE.TOO_MANY_REQUESTS) {
    message = "Too many requests. Please try again later.";
  }

  const normalized = normalizeError(error);
  message = normalized;

  return { message };
}

const instance = createAxios();

export default instance;
