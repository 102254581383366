import api from "Configs/Axios.Config";
import { _get } from "Utils/Lodash.Util";
import { SERVICES } from "Constants/Service.Constant";
import normalizeError from "Utils/NomalizeError.Util";
import { generatePath } from "react-router-dom";
import { IOrder, IOrderParams } from "Types/Order";

export const getOrdersList = async (params?: IOrderParams) => {
  try {
    const response = await api.get(SERVICES.GET_ORDERS_DATA, { params });
    const categories = _get(response.data, "records", []);
    const page = _get(response.data, "pagination.page", 1);
    const next_page = _get(response.data, "pagination.per_page", 9);
    const total_records = _get(response.data, "pagination.total_count", 0);
    return {
      success: true,
      data: categories,
      pagination: { page, next_page, total_records },
      error: null,
    };
  } catch (errors) {
    const pagination = {
      page: 1,
      next_page: 9,
      total_records: 0,
    };
    return {
      success: false,
      data: [],
      pagination,
      error: normalizeError(errors),
    };
  }
};

// ONE CATEGORY
export const getOneOrderData = async (orderId: number) => {
  try {
    const response = await api.get(
      generatePath(SERVICES.GET_ONE_ORDER, { id: orderId })
    );
    return {
      success: true,
      data: response.data.order,
      error: null,
    };
  } catch (errors) {
    return {
      success: false,
      data: {},
      error: normalizeError(errors),
    };
  }
};

//COUNT ORDER NEW

export const getCountOrderNew = async () => {
  try {
    const response = await api.get(SERVICES.GET_COUNT_ORDER_NEW, {
      params: { status: null },
    });
    return {
      success: true,
      data: response.data.total_records,
      error: null,
    };
  } catch (errors) {
    return {
      success: false,
      data: null,
      error: normalizeError(errors),
    };
  }
};

// CHANGE STATUS ORDER

export const changeStatusOrder = async (dataOrder: IOrder) => {
  try {
    const orderId = dataOrder.id;
    const province_id = dataOrder.province.id;
    const district_id = dataOrder.district.id;
    const ward_id = dataOrder.ward.id;
    const newDataOrder = { ...dataOrder, province_id, ward_id, district_id };
    delete newDataOrder.district;
    delete newDataOrder.province;
    delete newDataOrder.ward;
    const response = await api.put(
      generatePath(SERVICES.CHANGE_STATUS_ORDER, { id: orderId }),
      newDataOrder
    );
    return {
      success: true,
      data: response.data,
      error: null,
    };
  } catch (errors) {
    return {
      success: false,
      data: null,
      error: normalizeError(errors),
    };
  }
};
