import classNames from "classnames";
import { ErrorMessage, FormikHelpers } from "formik";

import { IDetailValues } from "Types/Product";
import { i18NextText } from "Utils/I18Next.Util";
import InputNumber from "CoreUI/Components/Common/InputNumber";

import classes from "./Pricing.module.scss";

interface IPricing {
  values: IDetailValues;
  setFieldValue: FormikHelpers<IDetailValues>["setFieldValue"];
}
const Pricing = ({ values, setFieldValue }: IPricing) => {
  const handleOnChangePrice = (value: number | string) => {
    setFieldValue("price", Number(value));
  };

  return (
    <div
      className={classNames(
        "flex flex-col gap-4",
        classes["pricing_container"]
      )}
    >
      <h2>{i18NextText("product.pricing")}</h2>
      <div className="flex flex-col gap-2">
        <label
          style={{
            color: "var(--secondary_text)",
            fontWeight: "var(--fw_semibold)",
          }}
        >
          {i18NextText("product.form_label_price")}
        </label>

        <InputNumber
          value={values.price == 0 ? null : values.price}
          name="price"
          placeholder="0.00"
          onChange={handleOnChangePrice}
          className={classes["input_number"]}
        />
        <p className={classes["error_message"]}>
          <ErrorMessage name="price" />
        </p>
      </div>
      <div className="flex flex-col gap-2">
        <label
          style={{
            color: "var(--secondary_text)",
            fontWeight: "var(--fw_semibold)",
          }}
        >
          {i18NextText("product.form_label_original_price")}
        </label>

        <InputNumber
          value={values.original_price == 0 ? null : values.original_price}
          name="original_price"
          placeholder="0.00"
          onChange={(value: number) =>
            setFieldValue("original_price", Number(value))
          }
          className={classes["input_number"]}
        />
        <p className={classes["error_message"]}>
          <ErrorMessage name="original_price" />
        </p>
      </div>
    </div>
  );
};

export default Pricing;
