import { FormikHelpers } from "formik";
import classNames from "classnames";

import { Icons, Upload } from "Components/Lib";
import { useModalContext } from "Context/Modal";
import { IDetailValues } from "Types/Product";
import { i18NextText } from "Utils/I18Next.Util";

import EditVariant from "./EditVariant";
import classes from "./VariantComponent.module.scss";

type VariantComponentProps = {
  variant: any;
  indexVariant: number;
  setFieldValue: FormikHelpers<IDetailValues>["setFieldValue"];
};

const VariantComponent = ({
  variant,
  indexVariant,
  setFieldValue,
}: VariantComponentProps) => {
  const { price, quantity, product_variant_options, images_attributes } =
    variant;

  const productVariantOptions = product_variant_options.map((ele) => ele.value);

  const { updateModal } = useModalContext();

  const showConfirm = () => {
    updateModal(
      <EditVariant
        indexVariant={indexVariant}
        variant={variant}
        setFieldVariant={setFieldValue}
      />,
      {
        showTitle: true,
        width: 800,
        height: "auto",
        title: productVariantOptions.join("/"),
      }
    );
  };

  return (
    <>
      <div
        className="flex justify-between hover:bg-gray-light border-b border-b-gray-border cursor-pointer"
        style={{ padding: " var(--sp_lv_4) var(--sp_lv_4) " }}
        onClick={() => showConfirm()}
      >
        <div className="flex items-center gap-6">
          {images_attributes?.length ? (
            <div className={classNames("h-20 w-20 ", classes["imageVariant"])}>
              <Upload
                listType="picture-card"
                accept="image/*"
                fileList={[{ ...images_attributes[0] }]}
                multiple={true}
                type="select"
              ></Upload>
            </div>
          ) : (
            <div className="flex justify-center items-center rounded-[2px] h-20 w-20 border-2 border-dashed border-gray-text">
              <Icons.PictureOutlined
                style={{ fontSize: "2rem", color: "rgb(127 129 134)" }}
              />
            </div>
          )}
          <p style={{ fontWeight: "var(--fw_extrabold)" }}>
            {productVariantOptions.join(" / ")}
          </p>
        </div>
        <div className="text-right">
          <div>{price}</div>
          <div>
            {quantity} {i18NextText("product.available_at_store")}
          </div>
        </div>
      </div>
    </>
  );
};

export default VariantComponent;
