const common = {
  hello_world: "Hello World",
  reload: "Reload",
  sidebar_tab_order: "Orders",
  sidebar_tab_product: "Products",
  sidebar_tab_category: "Categories",
  sidebar_tab_reviews: "Reviews",
  sidebar_tab_brands: "Brands",

  copyright: "© {{year}} Sofa Xinh Company",
};

export default common;
