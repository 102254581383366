import { Switch } from "Components/Lib";
import { useStoreActions, useStoreState } from "Store";
import { IDetailCategory, IRecordCategory } from "Types/Category";
import { notification } from "Utils/Notification.Util";
import { i18NextText } from "Utils/I18Next.Util";
import { useState } from "react";

type StatusCategoryProps = {
  detailCategory: IRecordCategory;
};

const StatusCategory = ({ detailCategory }: StatusCategoryProps) => {
  const { status } = detailCategory;
  const [isChecked, setIsChecked] = useState(status == "active" ? true : false);

  const loadingUpdateStatus = useStoreState(
    (state) => state.categories.detailCategory.loadingSubmit
  );

  const updateCategory = useStoreActions(
    (action) => action.categories.detailCategory.updateCategory
  );

  const handleUpdateStatusCategory = async (value) => {
    const newSubCateAttributes = detailCategory.sub_categories_attributes.map(
      (subCate) => ({ ...subCate, status: value ? "active" : "deactivate" })
    );
    const initValueUpdate: IDetailCategory = {
      name: detailCategory.name,
      status: value ? "active" : "deactivate",
      description: detailCategory.description,
      images_attributes: detailCategory.images_attributes,
      image_banners_attributes: detailCategory.image_banners_attributes,
      sub_categories_attributes: newSubCateAttributes,
    };
    const response = await updateCategory({
      categoryId: detailCategory.id,
      values: initValueUpdate,
    });
    if (!response.success) {
      notification.error({
        message: "",
        description: i18NextText("error.notification_error"),
      });
    } else {
      setIsChecked(!isChecked);
    }
  };
  return (
    <Switch
      loading={loadingUpdateStatus}
      checked={isChecked}
      onChange={handleUpdateStatusCategory}
    />
  );
};

export default StatusCategory;
