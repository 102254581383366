export const ORDER_STATUS = {
  NEW: "new",
  PENDING: "pending",
  PROCESSING: "processing",
  COMPLETED: "completed",
  CANCELLED: "cancelled",
};

export const ORDER_STATUS_DATA = [
  {
    label: "New",
    value: ORDER_STATUS.NEW,
  },
  {
    label: "Pending",
    value: ORDER_STATUS.PENDING,
  },
  {
    label: "Processing",
    value: ORDER_STATUS.PROCESSING,
  },
  {
    label: "Completed",
    value: ORDER_STATUS.COMPLETED,
  },
  {
    label: "Cancelled",
    value: ORDER_STATUS.CANCELLED,
  },
];
