import classNames from "classnames";
import { FormikHelpers } from "formik";

import { IDetailValues } from "Types/Product";

import FormDescription from "./FormDescription";
import NewOptionProductInfo from "./NewOptionProductInfo";

import classes from "./ProductDetail.module.scss";

interface IProductInfo {
  values: IDetailValues;
  setFieldValue: FormikHelpers<IDetailValues>["setFieldValue"];
}

const ProductInfo = ({ values, setFieldValue }: IProductInfo) => {
  return (
    <>
      <div
        className={classNames(
          "flex flex-col gap-4",
          classes["product_detail_container"]
        )}
      >
        <FormDescription
          setFieldValue={setFieldValue}
          setFieldTitle="title"
          setFieldDescription="description"
          values={values}
        />
        <NewOptionProductInfo
          setFieldValue={setFieldValue}
          product_detail_attribute={values.product_details_attributes}
        />
      </div>
    </>
  );
};

export default ProductInfo;
