import { FormikHelpers } from "formik";

import { IDetailCategory } from "Types/Category";
import { IDetailValues } from "Types/Product";
import { i18NextText } from "Utils/I18Next.Util";
import UploadImagesComponent from "Components/Own/UploadImagesComponent";

interface IImageBannersCategory {
  values: IDetailCategory;
  setFieldValue: FormikHelpers<IDetailValues>["setFieldValue"];
}

const ImageBannersCategory = ({
  values,
  setFieldValue,
}: IImageBannersCategory) => {
  return (
    <div className="flex flex-col gap-5">
      <h3
        style={{
          color: "var(--secondary_text)",
        }}
      >
        {i18NextText("category.image_banner_category")}
      </h3>
      <div
        style={{
          minHeight: "150px",
          border: "1px dashed var(--gray_7)",
          borderRadius: "1rem",
          padding: "var(--sp_lv_2)",
        }}
        className="flex flex-col justify-center items-center gap-2"
      >
        <UploadImagesComponent
          images_attributes={values.image_banners_attributes}
          setFieldValue={setFieldValue}
          nameFieldValue="image_banners_attributes"
          numberImagesLimit={1}
          isMultiple={false}
        />
        <div>
          <p>{i18NextText("category.images_description")}</p>
        </div>
      </div>
    </div>
  );
};

export default ImageBannersCategory;
