import { useMemo } from "react";
import { Table, ConfigProvider } from "antd";

import { DataTableProps } from "../types";
import EmptyData from "../EmptyData";
// import SkeletonTable from "../SkeletonTable";

import classes from "./DataTable.module.scss";

const DEFAULT_WIDTH = 180;
const DEFAULT_HEIGHT = 800;

const DataTable: React.FC<DataTableProps> = ({
  rowKey,
  columns,
  data,
  pagination = { pageSize: 10, current: 1, total: 10 },
  scroll,
  emptyDescription = "No data",
  loading,
  useScroll = true,
  customStyle,
  ...rest
}) => {
  const formatColumns = useMemo(() => {
    return columns.map((column) => ({
      ...column,
      width: column.width || DEFAULT_WIDTH,
    }));
  }, [columns]);

  const scrollDefault = useMemo(() => {
    let x = 0;
    columns.forEach((record) => {
      const width = record.width || DEFAULT_WIDTH;
      x += width;
    });
    return { x, y: DEFAULT_HEIGHT };
  }, [columns]);

  return (
    <div className={classes["table-container"]}>
      {/* {loading ? (
        <SkeletonTable columns={columns} />
      ) : ( */}
      <ConfigProvider
        renderEmpty={() => <EmptyData description={emptyDescription} />}
      >
        <Table
          scroll={useScroll ? { ...scrollDefault, ...scroll } : { ...scroll }}
          rowKey={rowKey}
          loading={loading}
          columns={formatColumns}
          dataSource={data}
          pagination={
            pagination?.total > 0
              ? {
                  ...pagination,
                  position: ["bottomRight"],
                  showSizeChanger: false,
                }
              : false
          }
          size="small"
          className={customStyle}
          {...rest}
        />
      </ConfigProvider>
      {/* )} */}
    </div>
  );
};

export default DataTable;
