import { Switch } from "Components/Lib";
import { useStoreActions, useStoreState } from "Store";
import { IDetailBrand, IRecordBrand } from "Types/Brands";
import { notification } from "Utils/Notification.Util";
import { i18NextText } from "Utils/I18Next.Util";
import { useState } from "react";

type StatusActiveProps = {
  detailBrand: IRecordBrand;
};

const StatusActive = ({ detailBrand }: StatusActiveProps) => {
  const { status } = detailBrand;
  const [isChecked, setIsChecked] = useState(status == "active" ? true : false);

  const loadingUpdateStatus = useStoreState(
    (state) => state.brands.detailBrand.loadingSubmit
  );

  const updateBrand = useStoreActions(
    (action) => action.brands.detailBrand.updateBrand
  );

  const handleUpdateStatusBrand = async (value) => {
    const initValueUpdate: IDetailBrand = {
      name: detailBrand.name,
      images_attributes: detailBrand.images_attributes,
      status: value ? "active" : "deactivate",
    };
    const response = await updateBrand({
      brandId: detailBrand.id,
      values: initValueUpdate,
    });
    if (!response.success) {
      notification.error({
        message: "",
        description: i18NextText("error.notification_error"),
      });
    } else {
      setIsChecked(!isChecked);
    }
  };
  return (
    <Switch
      loading={loadingUpdateStatus}
      checked={isChecked}
      onChange={handleUpdateStatusBrand}
    />
  );
};

export default StatusActive;
