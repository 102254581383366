import list from "./ListCustomers/index";
import detail from "./DetailCustomer";
import { ListCustomersModel } from "./ListCustomers/Type";
import { CustomerDetailModel } from "./DetailCustomer/Type";

export interface CustomersModel {
  list: ListCustomersModel;
  detail: CustomerDetailModel;
}

const customers = { list, detail };

export default customers;
