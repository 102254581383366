/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  useRef,
  useCallback,
  createContext,
  useContext,
  ReactNode,
} from "react";

import ContextModal from "CoreUI/Components/Common/ContextModal";
import {
  ContextModalProps,
  ContextModalHookProps,
} from "CoreUI/Components/Common/types";

const ModalContextProvider: React.FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const modalRef = useRef<any>(null);

  const showModal = useCallback(() => {
    modalRef && modalRef.current && modalRef.current?.show();
  }, []);

  const hideModal = useCallback(() => {
    modalRef && modalRef.current && modalRef.current?.hide();
  }, []);

  const showLoading = useCallback(() => {
    modalRef && modalRef.current && modalRef.current?.showLoading();
  }, []);

  const hideLoading = useCallback(() => {
    modalRef && modalRef.current && modalRef.current?.hideLoading();
  }, []);

  const updateModal = useCallback(
    (content: any, options: ContextModalProps["options"]) => {
      modalRef &&
        modalRef.current &&
        modalRef.current?.update(content, options);
    },
    []
  );

  return (
    <ModalContext.Provider
      value={{
        showModal,
        hideModal,
        updateModal,
        showLoading,
        hideLoading,
      }}
    >
      {children}

      <ContextModal modalRef={modalRef} />
    </ModalContext.Provider>
  );
};

const ModalContext = createContext<ContextModalHookProps>({
  showModal: () => null,
  hideModal: () => null,
  showLoading: () => null,
  hideLoading: () => null,
  updateModal: (
    // eslint-disable-next-line no-unused-vars
    content: any | ReactNode,
    options?: ContextModalProps["options"]
  ) => null,
});

export const useModalContext = (): ContextModalHookProps =>
  useContext(ModalContext);

export default ModalContextProvider;
