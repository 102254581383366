import { Space } from "antd";

import LoadingAnimation from "./LoadingAnimation";

const LoadingPage = () => {
  return (
    <Space
      size="middle"
      direction="vertical"
      align="center"
      className="justify-center h-full w-full"
    >
      <div style={{ height: "50px", width: "50px" }}>
        <LoadingAnimation />
      </div>
    </Space>
  );
};

export default LoadingPage;
