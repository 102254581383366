import { Yup } from "Utils/Yup.Util";
import { i18NextText } from "Utils/I18Next.Util";

const SignInSchema = Yup.object().shape({
  email: Yup.string()
    .email(i18NextText("auth.signIn_validator_invalid_email"))
    .required(i18NextText("auth.signIn_validator_email")),
  password: Yup.string()
    .required(i18NextText("auth.signIn_validator_password"))
    .min(8, i18NextText("auth.signIn_validator_min_password")),
});

export default SignInSchema;
