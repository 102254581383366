import { Routes, Route } from "react-router-dom";

import { PATH_NAME } from "Constants/PathName.Constant";
import {
  SignIn,
  NotFound,
  Forbidden,
  ProductDetail,
  ForgotPassword,
} from "Pages";
import { ErrorBoundary } from "Components/Layouts";
import {
  CategoriesPage,
  CreateCategoryPage,
  CreateMerchantPage,
  CustomersPage,
  EditCategory,
  EditMerchantPage,
  MerchantPage,
  ProductsDashboard,
  UpdateCustomerPage,
} from "Pages/Private";

import PrivateRoute from "./Private.Route";
import PublicRoute from "./Public.Route";
import {
  BrandsPage,
  CreateBrandPage,
  EditBrandPage,
} from "Pages/Private/Brands";
import OrdersPage from "Pages/Private/Orders/OrdersPage";
import OrderDetailPage from "Pages/Private/Orders/OrderDetailPage";
import { ReviewsPage } from "Pages/Private/Reviews";

const AppRoutes = () => {
  return (
    <ErrorBoundary>
      <Routes>
        {/* Public */}
        <Route element={<PublicRoute />}>
          <Route path={PATH_NAME.SIGN_IN} element={<SignIn />} />
          <Route
            path={PATH_NAME.FORGOT_PASSWORD}
            element={<ForgotPassword />}
          />
        </Route>

        {/* Private */}
        <Route path={PATH_NAME.HOME} element={<PrivateRoute />}>
          {/* Customer */}
          <Route path={PATH_NAME.CUSTOMERS}>
            <Route index element={<CustomersPage />} />
            <Route
              path={PATH_NAME.UPDATE_CUSTOMERS_INFO}
              element={<UpdateCustomerPage />}
            />
          </Route>

          {/* Product */}
          <Route path={PATH_NAME.PRODUCTS}>
            <Route index element={<ProductsDashboard />} />
            <Route
              path={PATH_NAME.PRODUCT_DETAIL}
              element={<ProductDetail />}
            />
          </Route>

          {/* Merchant */}
          <Route path={PATH_NAME.MERCHANT}>
            <Route index element={<MerchantPage />} />
            <Route
              path={PATH_NAME.CREATE_MERCHANT}
              element={<CreateMerchantPage />}
            />
            <Route
              path={PATH_NAME.EDIT_MERCHANT}
              element={<EditMerchantPage />}
            />
          </Route>

          {/* Categories */}
          <Route path={PATH_NAME.CATEGORIES}>
            <Route index element={<CategoriesPage />} />
            <Route
              path={PATH_NAME.CATEGORIES_DETAIL}
              element={<CreateCategoryPage />}
            />
            <Route
              path={PATH_NAME.CATEGORIES_DETAIL}
              element={<EditCategory />}
            />
          </Route>

          {/* Brands */}
          <Route path={PATH_NAME.BRANDS}>
            <Route index element={<BrandsPage />} />
            <Route
              path={PATH_NAME.BRANDS_DETAIL}
              element={<CreateBrandPage />}
            />
            <Route path={PATH_NAME.BRANDS_DETAIL} element={<EditBrandPage />} />
          </Route>

          {/* Orders */}
          <Route path={PATH_NAME.ORDERS}>
            <Route index element={<OrdersPage />} />
            <Route
              path={PATH_NAME.ORDER_DETAIL}
              element={<OrderDetailPage />}
            />
          </Route>
          {/* Reviews */}
          <Route path={PATH_NAME.REVIEWS}>
            <Route index element={<ReviewsPage />} />
          </Route>
        </Route>
        {/*Error Page */}
        <Route path={PATH_NAME.FORBIDDEN} element={<Forbidden />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </ErrorBoundary>
  );
};

export default AppRoutes;
