import React from "react";
import { ClearOutlined } from "@ant-design/icons";

import { i18NextText } from "Utils/I18Next.Util";
import ButtonComponent from "CoreUI/Components/Common/Button";
import { useStoreActions } from "Store";
import {
  DEFAULT_FILTER_PARAMS,
  DEFAULT_PAGE_PARAMS,
} from "Store/Models/Product/List";

const ClearFilterButton = () => {
  const getProductsList = useStoreActions(
    (state) => state.products.list.getProductsList
  );

  const handleOnClickClearFilter = () => {
    getProductsList({
      ...DEFAULT_PAGE_PARAMS,
      ...DEFAULT_FILTER_PARAMS,
    });
  };
  return (
    <ButtonComponent
      icon={<ClearOutlined />}
      onClick={handleOnClickClearFilter}
      type="default"
    >
      {i18NextText("product.btn_filter")}
    </ButtonComponent>
  );
};

export default ClearFilterButton;
