import listCategories from "./ListCategories";
import detailCategory from "./DetailCategory";
import { ListCategoriesModel } from "./ListCategories/Type";
import { CategoryDetailModel } from "./DetailCategory/Type";

export interface CategoriesModel {
  listCategories: ListCategoriesModel;
  detailCategory: CategoryDetailModel;
}

const categories = { listCategories, detailCategory };

export default categories;
