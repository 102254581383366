import { FormikHelpers } from "formik";

import { IDetailValues } from "Types/Product";
import { i18NextText } from "Utils/I18Next.Util";

import UploadImagesComponent from "Components/Own/UploadImagesComponent";
import { IDetailBrand } from "Types/Brands";

interface IImagesBrand {
  values: IDetailBrand;
  setFieldValue: FormikHelpers<IDetailValues>["setFieldValue"];
}

const ImagesBrand = ({ values, setFieldValue }: IImagesBrand) => {
  return (
    <div className="flex flex-col gap-5">
      <h2>{i18NextText("brand.images_brand")}</h2>
      <div
        style={{
          minHeight: "150px",
          border: "1px dashed var(--gray_7)",
          borderRadius: "1rem",
          padding: "var(--sp_lv_2)",
        }}
        className="flex flex-col justify-center items-center gap-2"
      >
        <UploadImagesComponent
          images_attributes={values.images_attributes}
          setFieldValue={setFieldValue}
          nameFieldValue="images_attributes"
        />
        <div>
          <p>{i18NextText("brand.images_description")}</p>
        </div>
      </div>
    </div>
  );
};

export default ImagesBrand;
