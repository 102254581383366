const customers = {
  customers: "Customers",
  btn_filter: "Filter",
  btn_update: "Update",
  col_first_name: "FIRST NAME",
  col_last_name: "LAST NAME",
  col_gender: "GENDER",
  col_email: "EMAIL",
  col_address: "ADDRESS",
  col_active: "ACTIVE",
  title_form: "Update Customer Information",
  label_first_name: "First Name",
  label_last_name: "Last Name",
  label_gender: "Gender",
  label_email: "Email",
  label_address: "Address",
  label_active: "Active",
  option_male: "Male",
  option_female: "Female",
  option_other: "Other",
  validator_first_name: "First Name is required",
  validator_last_name: "Last Name is required",
  validator_gender: "Gender is required",
  validator_not_email: "Please enter an email address",
  validator_email: "Email is required",
  validator_address: "Address is required",
};

export default customers;
