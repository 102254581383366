import { useEffect } from "react";

import ImageComponent from "CoreUI/Components/Common/Image";
import { useStoreActions, useStoreState } from "Store";
import { i18NextText } from "Utils/I18Next.Util";
import { notification } from "Utils/Notification.Util";
import { Badge } from "Components/Lib";
import OrderIcon from "Assets/Images/Icons/order-icon.png";

const CountOrderNew = () => {
  const count = useStoreState((state) => state.orders.ordersList.count);
  const getCountOrderNew = useStoreActions(
    (action) => action.orders.ordersList.countNewOrder
  );
  useEffect(() => {
    const fetchData = async () => {
      const response = await getCountOrderNew();
      if (!response.success) {
        notification.error({
          message: "",
          description: i18NextText("error.notification_error"),
        });
      }
    };
    fetchData();
  }, [getCountOrderNew]);

  return (
    <div className="flex justify-between items-center w-[100%]">
      <div>
        <ImageComponent src={OrderIcon} className="w-8 h-8 mr-5" />
        {i18NextText("common.sidebar_tab_order")}
      </div>
      <Badge color="#f50" className="ml-5" count={count} />
    </div>
  );
};

export default CountOrderNew;
