import { useStoreState } from "Store";
import { Icons } from "Components/Lib";
import { _split, _upperFirst } from "Utils/Lodash.Util";
import { i18NextText } from "Utils/I18Next.Util";

const InfoShipping = () => {
  const data = useStoreState((state) => state.orders.detailOrder.data);
  const {
    user_name,
    phone_number,
    email,
    shipping_address,
    province,
    ward,
    district,
    note,
    payment_method,
    shipping_method,
  } = data;
  const userName = _split(user_name, " ").map((name) => _upperFirst(name));

  return (
    <>
      <div
        className="bg-white mr-4  flex flex-col gap-6 rounded-lg"
        style={{ padding: "var(--sp_lv_7) " }}
      >
        <div className="flex items-center gap-3">
          <Icons.UserOutlined
            style={{
              fontSize: "3rem",
              background: "antiquewhite",
              padding: 4,
              borderRadius: 2,
            }}
          />
          {userName.join(" ")}
        </div>
        <div className="flex">
          <div className="w-1/2">
            <div style={{ color: "var(--secondary_text)" }}>
              {i18NextText("order.label_phone")}
            </div>
            <div>{phone_number}</div>
          </div>
          <div className="w-1/2">
            <div style={{ color: "var(--secondary_text)" }}>
              {i18NextText("order.label_email")}
            </div>
            <div>{email}</div>
          </div>
        </div>
        <div>
          <div style={{ color: "var(--secondary_text)" }}>
            {i18NextText("order.label_address")}
          </div>
          <div className="w-1/2">
            {shipping_address}, {ward.name}, {district.name}, {province.name}
          </div>
        </div>
        <div className="flex ">
          <div className="w-1/2">
            <div style={{ color: "var(--secondary_text)" }}>
              {i18NextText("order.label_payment_method")}
            </div>
            <div>{payment_method}</div>
          </div>
          <div className="w-1/2">
            <div style={{ color: "var(--secondary_text)" }}>
              {i18NextText("order.label_shipping_method")}
            </div>
            <div>{shipping_method}</div>
          </div>
        </div>
        <div>
          <div style={{ color: "var(--secondary_text)" }}>
            {i18NextText("order.label_note")}
          </div>
          <div className={`${!note && "italic text-gray-text"}`}>
            {note ? note : "No notes from customer"}
          </div>
        </div>
      </div>
    </>
  );
};

export default InfoShipping;
