import { Icons } from "Components/Lib";
import { useStoreActions, useStoreState } from "Store";

const SortByStatus = () => {
  const isSort = useStoreState(
    (state) => state.orders.ordersList.filterParams.sort
  );
  const getOrdersList = useStoreActions(
    (action) => action.orders.ordersList.getOrdersList
  );

  const handleSortDesc = async () => {
    await getOrdersList({ filter: { sort: "desc" } });
  };

  const handleSortAcs = async () => {
    await getOrdersList({ filter: { sort: "asc" } });
  };
  return (
    <div className="hover:text-blue">
      {isSort === "asc" ? (
        <Icons.SortAscendingOutlined onClick={handleSortDesc} />
      ) : (
        <Icons.SortDescendingOutlined onClick={handleSortAcs} />
      )}
    </div>
  );
};

export default SortByStatus;
