import { useStoreState } from "Store";
import { formatCurrencyVN } from "Utils/Common.Util";

import TableInfoProduct from "./TableInfoProduct";

const InfoProductOrdered = () => {
  const data = useStoreState((state) => state.orders.detailOrder.data);
  return (
    <div className="bg-white mr-4 mt-4 mb-10 flex flex-col gap-6 rounded-lg">
      <TableInfoProduct />
      <div className="flex justify-end">
        <div
          className="flex flex-col gap-2 w-[50%] md:w-[40%] lg:w-[30%]"
          style={{ padding: "0 var(--sp_lv_9) var(--sp_lv_7) 0 " }}
        >
          <div className="flex justify-between">
            <div style={{ color: "var(--secondary_text)" }}> Subtotal</div>
            <div>{formatCurrencyVN(data.sub_total)}</div>
          </div>
          <div className="flex justify-between">
            <div style={{ color: "var(--secondary_text)" }}> Shipping</div>
            <div>{formatCurrencyVN(data.shipping_amount)}</div>
          </div>
          <div className="flex justify-between">
            <div style={{ color: "var(--secondary_text)" }}> Total</div>
            <div>{formatCurrencyVN(data.total)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoProductOrdered;
