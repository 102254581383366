import api from "Configs/Axios.Config";
import { SERVICES } from "Constants/Service.Constant";
import { IProductParams } from "Types/Customer";
import { generatePath } from "Utils/GeneratePath.Util";
import { _get } from "Utils/Lodash.Util";
import normalizeError from "Utils/NomalizeError.Util";

export const getCustomersData = async (params?: IProductParams) => {
  try {
    const response = await api.get(SERVICES.GET_CUSTOMERS_DATA, { params });
    const customers = _get(response.data, "users", []);
    const page = _get(response.data, "pagination.current_page", 1);
    const next_page = _get(response.data, "pagination.next_page", null);
    const total_records = _get(response.data, "pagination.total_records", 0);
    return {
      success: true,
      data: customers,
      pagination: { page, next_page, total_records },
      error: null,
    };
  } catch (errors) {
    const pagination = {
      page: 1,
      next_page: null,
      total_records: 0,
    };

    return {
      success: false,
      data: [],
      pagination,
      error: normalizeError(errors),
    };
  }
};

export const getOneCustomer = async (customerId: string) => {
  try {
    const response = await api.get(
      generatePath(SERVICES.GET_ONE_CUSTOMER, { customerId })
    );
    return {
      success: true,
      data: response.data,
      error: null,
    };
  } catch (errors) {
    return {
      success: false,
      data: {},
      error: normalizeError(errors),
    };
  }
};

export const updateCustomerInfo = async (customerId, values) => {
  try {
    const response = await api.put(
      generatePath(SERVICES.UPDATE_CUSTOMER, { customerId }),
      values
    );
    return {
      success: true,
      data: response.data,
      error: null,
    };
  } catch (errors) {
    return {
      success: false,
      data: {},
      error: normalizeError(errors),
    };
  }
};

export const deleteOneCustomer = async (customerId: string) => {
  try {
    const response = await api.delete(
      generatePath(SERVICES.DELETE_CUSTOMER, { customerId })
    );
    return {
      success: true,
      data: response.data,
      error: null,
    };
  } catch (errors) {
    return {
      success: false,
      data: {},
      error: normalizeError(errors),
    };
  }
};
