import { Icons } from "Components/Lib";
import InputField from "CoreUI/Components/Common/InputField";
import { _debounce } from "Utils/Lodash.Util";

import classes from "./InputSearch.module.scss";
import { InputProps } from "antd";
import { useMemo } from "react";

interface IInputSearchComponent extends InputProps {
  getDataList: any;
  setKeyword: any;
}
const InputSearchComponent = ({
  getDataList,
  setKeyword,
  ...rest
}: IInputSearchComponent) => {
  const handleDebounce = useMemo(
    () =>
      _debounce(async (value: string) => {
        await getDataList({ keyword: value });
      }, 500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onChangeKeyWord = (e) => {
    const value = e.target?.value;
    handleDebounce(value);
    setKeyword(value);
  };

  return (
    <InputField
      prefix={<Icons.SearchOutlined style={{ fontSize: "1.8rem" }} />}
      onChange={onChangeKeyWord}
      className={classes["inputSearch"]}
      {...rest}
    />
  );
};

export default InputSearchComponent;
