import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";

import page_loading_lottie from "Assets/Lotties/page_loading.json";

const LoadingAnimation: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref && ref.current) {
      const animation = lottie.loadAnimation({
        container: ref.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        // path to your animation file, place it inside public folder
        animationData: page_loading_lottie,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      });

      return () => animation.destroy();
    }
  }, []);

  return <div ref={ref} />;
};

export default LoadingAnimation;
