import CountOrderNew from "Components/Layouts/Private/CountOrderNew";
import { i18NextText } from "Utils/I18Next.Util";
import { PATH_NAME } from "Constants/PathName.Constant";
import ImageComponent from "CoreUI/Components/Common/Image";

import CategoryIcon from "Assets/Images/Icons/category-icon.png";
import BrandIcon from "Assets/Images/Icons/brand-icon.png";
import ProductIcon from "Assets/Images/Icons/product-icon.png";
import ReviewIcon from "Assets/Images/Icons/rate-review-icon.png";

const MENU_CONSTANT = [
  {
    label: (
      <div>
        <ImageComponent src={ProductIcon} className="w-8 h-8 mr-5" />
        {i18NextText("common.sidebar_tab_product")}
      </div>
    ),
    key: "products",
    path: PATH_NAME.PRODUCTS,
  },
  {
    label: (
      <div>
        <ImageComponent src={CategoryIcon} className="w-8 h-8 mr-5" />
        {i18NextText("common.sidebar_tab_category")}
      </div>
    ),
    key: "categories",
    path: PATH_NAME.CATEGORIES,
  },
  {
    label: (
      <div>
        <ImageComponent src={BrandIcon} className="w-8 h-8 mr-5" />
        {i18NextText("common.sidebar_tab_brands")}
      </div>
    ),
    key: "brands",
    path: PATH_NAME.BRANDS,
  },
  {
    label: <CountOrderNew />,
    key: "orders",
    path: PATH_NAME.ORDERS,
  },
  {
    label: (
      <div>
        <ImageComponent src={ReviewIcon} className="w-8 h-8 mr-5" />
        {i18NextText("common.sidebar_tab_reviews")}
      </div>
    ),
    key: "reviews",
    path: PATH_NAME.REVIEWS,
  },
];

export default MENU_CONSTANT;
