import { useEffect } from "react";
// import { useNavigate } from "react-router-dom";

import { useStoreActions } from "Store";
import { Icons } from "Components/Lib";
import ButtonComponent from "CoreUI/Components/Common/Button";
import { i18NextText } from "Utils/I18Next.Util";
import { notification } from "Utils/Notification.Util";

import TableMerchantList from "./TableMerchantList";

const MerchantPage = () => {
  // const navigate = useNavigate();

  const getData = useStoreActions(
    (state) => state.merchants.list.getMerchantsList
  );

  useEffect(() => {
    const fetchdata = async () => {
      const response = await getData({});
      if (!response.success) {
        notification.error({
          message: "",
          description: i18NextText("error.notification_error"),
        });
      }
    };
    fetchdata();
  }, [getData]);

  // const handleAddProduct = () => {
  //   navigate(PATH_NAME.CREATE_PRODUCT);
  // };

  return (
    <>
      <div
        className="flex justify-between"
        style={{ padding: "var(--sp_lv_8) var(--sp_lv_8) 0" }}
      >
        <h1 className="font-semibold">{i18NextText("merchant.merchants")}</h1>
        <div className="flex gap-4">
          <ButtonComponent icon={<Icons.FilterOutlined />}>
            {i18NextText("merchant.btn_filter")}
          </ButtonComponent>
          <ButtonComponent
            type="primary"
            icon={<Icons.PlusOutlined />}
            // onClick={handleAddProduct}
          >
            {i18NextText("merchant.btn_add_merchant")}
          </ButtonComponent>
        </div>
      </div>
      <div>
        <TableMerchantList />
      </div>
    </>
  );
};

export default MerchantPage;
