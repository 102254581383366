import * as React from "react";
import { StoreProvider, Store } from "easy-peasy";

import { StoreModel } from "Store/Models/storeModel";

interface IStoreProvider {
  store: Store<StoreModel>;
  children?: any;
}

const MyStoreProvider: React.FC<IStoreProvider> = (props) => {
  return <StoreProvider {...props} />;
};

export default MyStoreProvider;
