import { generatePath, useNavigate } from "react-router-dom";

import { Icons, Menu } from "Components/Lib";
import { useStoreActions } from "Store";
import DropdownComponent from "CoreUI/Components/Common/DropDown";
import { i18NextText } from "Utils/I18Next.Util";
import { useModalContext } from "Context/Modal";
import { PATH_NAME } from "Constants/PathName.Constant";
import { notification } from "Utils/Notification.Util";

import DeleteButtonComponent from "./DeleteButtonComponent";
import EditButtonComponent from "./EditButtonComponent";

interface IOptionComponent {
  id: string;
}

const OptionComponent = ({ id }: IOptionComponent) => {
  const navigate = useNavigate();
  const { hideModal } = useModalContext();

  const deleteProduct = useStoreActions(
    (action) => action.products.detail.deleteProduct
  );

  const onEdit = () => {
    navigate(
      generatePath(PATH_NAME.PRODUCT_DETAIL, {
        product_id: id,
      })
    );
  };

  const onDelete = async () => {
    const response = await deleteProduct({ productId: id });
    if (!response.success) {
      notification.error({
        message: "",
        description: i18NextText("error.notification_error"),
      });
    }
    hideModal();
  };

  const menu = (
    <Menu
      items={[
        {
          label: <EditButtonComponent onEdit={onEdit} />,
          key: "0",
        },
        {
          label: <DeleteButtonComponent onDelete={onDelete} />,
          key: "1",
        },
      ]}
    />
  );

  return (
    <DropdownComponent
      overlay={menu}
      trigger={["click"]}
      placement="bottom"
      hideArrow
      overlayClassName="min-w-0"
    >
      <a onClick={(e) => e.preventDefault()}>
        <Icons.EllipsisOutlined
          style={{ color: "var(--gray_13)", fontSize: "var(--sp_lv_6)" }}
        />
      </a>
    </DropdownComponent>
  );
};

export default OptionComponent;
