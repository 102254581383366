import React, { useEffect, useState } from "react";
import { ErrorMessage, Form, Formik, FormikProps } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import classNames from "classnames";

import { useStoreActions, useStoreState } from "Store";
import { _get } from "Utils/Lodash.Util";
import { Icons, Skeleton, Divider, Switch } from "Components/Lib";
import { i18NextText } from "Utils/I18Next.Util";
import { PATH_NAME } from "Constants/PathName.Constant";
import ButtonComponent from "CoreUI/Components/Common/Button";
import { notification } from "Utils/Notification.Util";
import InputField, { TextArea } from "CoreUI/Components/Common/InputField";

import { IDetailCategory } from "Types/Category";
import ImagesCategory from "./ImagesCategory";
import Subcategories from "./Subcategories";
import CategorySchema from "./CategorySchema";
import classes from "./CategoryForm.module.scss";
import ImageBannersCategory from "./ImageBannersCategory";

const CategoryForm: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const categoryId = _get(params, "category_id");

  const loading = useStoreState(
    (state) => state.categories.detailCategory.loading
  );
  const data = useStoreState((state) => state.categories.detailCategory.data);

  const reset = useStoreActions(
    (action) => action.categories.detailCategory.reset
  );
  const getOneCategory = useStoreActions(
    (action) => action.categories.detailCategory.getOneCategory
  );
  const createCategory = useStoreActions(
    (action) => action.categories.detailCategory.createCategory
  );
  const uploadImage = useStoreActions(
    (action) => action.categories.detailCategory.uploadImageCategory
  );
  const updateCategory = useStoreActions(
    (action) => action.categories.detailCategory.updateCategory
  );

  useEffect(() => {
    if (categoryId === "new") return;

    const fetchdata = async () => {
      const response = await getOneCategory({ categoryId });
      if (!response.success) {
        notification.error({
          message: "",
          description: i18NextText("error.notification_error"),
        });
      }
    };
    fetchdata();
  }, [categoryId, getOneCategory, reset]);

  useEffect(() => {
    return () => reset({});
  }, [reset]);

  const initialValues = {
    name: "",
    status: "",
    ...data,
    images_attributes: _get(data, "images_attributes", []).map((image) => ({
      ...image,
      uid: image.id.toString(),
      name: "",
      description: "",
      status: "done",
      url: image.public_url,
      isValid: true,
      isUploaded: true,
    })),
    image_banners_attributes: _get(data, "image_banners_attributes", []).map(
      (image) => ({
        ...image,
        uid: image.id.toString(),
        name: "",
        description: "",
        status: "done",
        url: image.public_url,
        isValid: true,
        isUploaded: true,
      })
    ),
    sub_categories_attributes: _get(data, "sub_categories_attributes", []).map(
      (subcategory, index_subcategory) => ({
        ...subcategory,
        images_attributes: _get(
          data,
          `sub_categories_attributes[${index_subcategory}]images_attributes`,
          []
        ).map((image) => ({
          ...image,
          uid: image.id.toString(),
          name: "",
          status: "done",
          url: image.public_url,
          isValid: true,
          isUploaded: true,
        })),
        image_banners_attributes: _get(
          data,
          `sub_categories_attributes[${index_subcategory}]image_banners_attributes`,
          []
        ).map((image) => ({
          ...image,
          uid: image.id.toString(),
          name: "",
          status: "done",
          url: image.public_url,
          isValid: true,
          isUploaded: true,
        })),
      })
    ),
  };

  const [loadingOnSubmit, setLoadingOnSubmit] = useState(false);

  const handleCreateCategory = async (values: any) => {
    setLoadingOnSubmit(true);
    const images = await handleUploadImages(values.images_attributes);
    const image_banners = await handleUploadImages(
      values.image_banners_attributes
    );
    const { sub_categories_attributes } = values;
    const filteredSubcategory = sub_categories_attributes.filter(
      (subcategory) => !!subcategory.name
    );

    let newSubcategory = [];
    if (filteredSubcategory.length) {
      newSubcategory = await Promise.all(
        filteredSubcategory.map(async (subCategory) => {
          const imagesSubcategory = await handleUploadImages(
            subCategory.images_attributes
          );

          const imageBannersSubcategory = await handleUploadImages(
            subCategory.image_banners_attributes
          );
          return {
            ...subCategory,
            images_attributes: imagesSubcategory,
            image_banners_attributes: imageBannersSubcategory,
          };
        })
      );
    }

    const res = await createCategory({
      ...values,
      images_attributes: images,
      image_banners_attributes: image_banners,
      sub_categories_attributes: newSubcategory,
    });

    if (res.success) {
      navigate(PATH_NAME.CATEGORIES);
    } else {
      const errors = res.error.split(",");
      notification.error({
        duration: null,
        message: "Unable to create category",
        description: errors.length ? (
          <div>
            {errors.map((error) => (
              <div> - {error}</div>
            ))}
          </div>
        ) : (
          i18NextText("error.notification_error")
        ),
      });
    }
    setLoadingOnSubmit(false);
  };

  const handleUpdateCategory = async (values) => {
    setLoadingOnSubmit(true);
    const images = await handleUploadImages(values.images_attributes);
    const image_banners = await handleUploadImages(
      values.image_banners_attributes
    );

    const { sub_categories_attributes } = values;
    const filteredSubcategory = sub_categories_attributes.filter(
      (subcategory) => !!subcategory.name
    );

    let newSubcategory = [];
    if (filteredSubcategory.length) {
      newSubcategory = await Promise.all(
        filteredSubcategory.map(async (subCategory) => {
          const imagesSubcategory = await handleUploadImages(
            subCategory.images_attributes
          );
          const imageBannersSubcategory = await handleUploadImages(
            subCategory.image_banners_attributes
          );
          return {
            ...subCategory,
            images_attributes: imagesSubcategory,
            image_banners_attributes: imageBannersSubcategory,
          };
        })
      );
    }

    const res = await updateCategory({
      categoryId,
      values: {
        ...values,
        images_attributes: images,
        image_banners_attributes: image_banners,
        sub_categories_attributes: newSubcategory,
      },
    });
    if (res.success) {
      navigate(PATH_NAME.CATEGORIES);
    } else {
      const errors = res.error.split(",");
      notification.error({
        duration: null,
        message: "Unable to update category",
        description: errors.length ? (
          <div>
            {errors.map((error) => (
              <div> - {error}</div>
            ))}
          </div>
        ) : (
          i18NextText("error.notification_error")
        ),
      });
    }
    setLoadingOnSubmit(false);
  };

  const handleUploadImages = async (images_attributes) => {
    const uploadedImages = images_attributes.filter(
      (image) => image.isUploaded
    );
    const listUploadFileImages = images_attributes.filter(
      (image) => !image.isUploaded
    );

    const response = await Promise.all(
      listUploadFileImages.map((file) => {
        try {
          const res = uploadImage(file);
          return res;
        } catch (error) {
          return "Upload Fail";
        }
      })
    );
    if (!response.includes("Upload Fail")) {
      const images = [...uploadedImages, ...response].map(
        (image: any, index: number) => {
          if (image.id) {
            return {
              id: image.id,
              object_key: image.object_key,
              position: index + 1,
            };
          }
          return { object_key: image.object_key, position: index + 1 };
        }
      );
      return images;
    } else {
      return [];
    }
  };

  const handleOnChangeDescription = ({ e, setFieldValue }) => {
    const value = e.target.value;

    setFieldValue("description", value);
  };

  if (categoryId !== "new" && loading) return <Skeleton />;

  const handleSwitchStatusCate = (
    value: boolean,
    setFieldValue,
    values: IDetailCategory
  ) => {
    setFieldValue("status", value ? "active" : "deactivate");
    if (values.sub_categories_attributes.length) {
      values.sub_categories_attributes.forEach((subCate, index) =>
        setFieldValue(
          `sub_categories_attributes[${index}].status`,
          value ? "active" : "deactivate"
        )
      );
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={CategorySchema}
      onSubmit={async (values: IDetailCategory) => {
        if (categoryId !== "new") {
          await handleUpdateCategory(values);
        } else {
          await handleCreateCategory(values);
        }
      }}
    >
      {(props: FormikProps<IDetailCategory>) => {
        const { values, setFieldValue, dirty } = props;
        return (
          <Form
            className={classNames("flex flex-col gap-4")}
            style={{
              margin: "auto",
              borderRadius: "var(--sp_lv_1)",
              maxWidth: "970px",
            }}
          >
            <div
              className="flex justify-between bg-white sticky top-0 z-50 shadow-[0_3px_10px_rgb(0,0,0,0.2)]"
              style={{ padding: "var(--sp_lv_8)" }}
            >
              <h1 className="font-semibold">
                {categoryId !== "new"
                  ? i18NextText("category.title_form_edit")
                  : i18NextText("category.title_form_create")}
              </h1>
              <div className="flex gap-4">
                <ButtonComponent
                  type="primary"
                  htmlType="submit"
                  icon={<Icons.PlusOutlined />}
                  disabled={!dirty}
                  loading={loadingOnSubmit}
                >
                  {categoryId !== "new"
                    ? i18NextText("category.btn_update")
                    : i18NextText("category.btn_create")}
                </ButtonComponent>
              </div>
            </div>
            <div
              className="flex flex-col justify-between gap-5"
              style={{
                background: "var(--gray_1)",
                borderRadius: "var(--br_lg)",
                padding: "var(--sp_lv_6)",
              }}
            >
              <div className="flex gap-5 items-center self-end">
                <h3
                  style={{
                    color: "var(--secondary_text)",
                  }}
                >
                  {i18NextText("category.field_status_category")}
                </h3>
                <Switch
                  checked={values.status == "active" ? true : false}
                  onChange={(value) =>
                    handleSwitchStatusCate(value, setFieldValue, values)
                  }
                />
              </div>
              <div className="flex flex-col gap-5">
                <h3
                  style={{
                    color: "var(--secondary_text)",
                  }}
                >
                  {i18NextText("category.field_name_category")}
                </h3>
                <InputField
                  name="name"
                  value={values.name}
                  type="text"
                  placeholder={i18NextText("category.form_placeholder_name")}
                  onChange={(e) => setFieldValue("name", e.target.value)}
                />
                <p style={{ color: "var(--red_5}" }}>
                  <ErrorMessage name="name" />
                </p>
              </div>
              <div className="flex flex-col gap-3">
                <h3
                  style={{
                    color: "var(--secondary_text)",
                  }}
                >
                  {i18NextText("product.form_label_description")}
                </h3>
                <TextArea
                  value={values.description}
                  placeholder={i18NextText(
                    "product.form_placeholder_description"
                  )}
                  className={classes["description-textarea"]}
                  onChange={(e) =>
                    handleOnChangeDescription({ e, setFieldValue })
                  }
                  autoSize={{ minRows: 3, maxRows: 10 }}
                />
              </div>
              <ImagesCategory values={values} setFieldValue={setFieldValue} />
              <ImageBannersCategory
                values={values}
                setFieldValue={setFieldValue}
              />
            </div>
            <Subcategories
              setFieldValue={setFieldValue}
              sub_categories_attributes={values.sub_categories_attributes}
            />
            <Divider />
          </Form>
        );
      }}
    </Formik>
  );
};
export default CategoryForm;
