import classNames from "classnames";

import ButtonComponent from "CoreUI/Components/Common/Button";

import classes from "./NewOptionProductInfo.module.scss";
import { i18NextText } from "Utils/I18Next.Util";
import { Divider, Icons } from "Components/Lib";
import { FieldArray, FormikHelpers } from "formik";
import { IDetailValues, IProductDetailAttributes } from "Types/Product";
import FormDescription from "../FormDescription";
import { _cloneDeep } from "Utils/Lodash.Util";

type PropsNewOptionProductInfo = {
  setFieldValue: FormikHelpers<IDetailValues>["setFieldValue"];
  product_detail_attribute: IProductDetailAttributes[];
};

const NewOptionProductInfo = ({
  product_detail_attribute,
  setFieldValue,
}: PropsNewOptionProductInfo) => {
  const initialValues = {
    id: null,
    title: "",
    description: "",
  };
  const handleAddOption = () => {
    setFieldValue("product_details_attributes", [
      ...product_detail_attribute,
      { ...initialValues },
    ]);
  };

  const handelDeleteOption = (
    index,
    setFieldValue: FormikHelpers<IDetailValues>["setFieldValue"]
  ) => {
    const newProductDetailAttributes = _cloneDeep(product_detail_attribute);
    newProductDetailAttributes.splice(index, 1);
    setFieldValue("product_details_attributes", newProductDetailAttributes);
  };
  return (
    <>
      <div className={classNames(classes["btn_add_option_container"])}>
        <ButtonComponent
          className={classNames(classes["btn_add_option"])}
          onClick={handleAddOption}
        >
          <Icons.PlusOutlined />
          {i18NextText("product.btn_add_option")}
        </ButtonComponent>
        {!!product_detail_attribute.length && (
          <>
            <Divider />
            <div>
              <FieldArray
                name="product_detail_attributes"
                render={(props) => {
                  const { setFieldValue } = props.form;
                  return product_detail_attribute.map(
                    (productDetailAttribute, productDetailAttributes_index) => {
                      return (
                        <FormDescription
                          setFieldValue={setFieldValue}
                          setFieldTitle={`product_details_attributes[${productDetailAttributes_index}].title`}
                          setFieldDescription={`product_details_attributes[${productDetailAttributes_index}].description`}
                          values={productDetailAttribute}
                          showDeleteButton={true}
                          handleDeleteOption={() =>
                            handelDeleteOption(
                              productDetailAttributes_index,
                              setFieldValue
                            )
                          }
                        />
                      );
                    }
                  );
                }}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default NewOptionProductInfo;
