import { Input, InputProps } from "antd";
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons";

const InputPassword: React.FC<InputProps> = (props) => {
  return (
    <Input.Password
      iconRender={(visible) =>
        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
      }
      {...props}
    />
  );
};

export default InputPassword;
