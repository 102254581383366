import { useNavigate } from "react-router-dom";

import { Button, Result } from "Components/Lib";
import { PATH_NAME } from "Constants/PathName.Constant";

const Forbidden = () => {
  const navigate = useNavigate();

  return (
    <div className="h-full flex items-center justify-center">
      <Result
        status="403"
        title="403"
        subTitle="Sorry, you are not authorized to access this page."
        extra={
          <Button type="primary" onClick={() => navigate(PATH_NAME.HOME)}>
            Back Home
          </Button>
        }
      />
    </div>
  );
};

export default Forbidden;
