import { ErrorMessage, FormikHelpers } from "formik";

import { Icons, Divider, Tooltip, Switch } from "Components/Lib";
import ButtonComponent from "CoreUI/Components/Common/Button";
import InputField, { TextArea } from "CoreUI/Components/Common/InputField";
import { IDetailCategory } from "Types/Category";
import { IDetailValues } from "Types/Product";
import { i18NextText } from "Utils/I18Next.Util";

import UploadImagesComponent from "../../../../../../Components/Own/UploadImagesComponent";
import classes from "./SubcategoryComponent.module.scss";

interface ISubcategoryComponent {
  values: IDetailCategory;
  setFieldValue: FormikHelpers<IDetailValues>["setFieldValue"];
  index_subcategory: number;
}

const SubcategoryComponent = ({
  values,
  setFieldValue,
  index_subcategory,
}: ISubcategoryComponent) => {
  const { sub_categories_attributes } = values;
  const handleDeleteOption = () => {
    const newSubcategory = sub_categories_attributes;
    newSubcategory.splice(index_subcategory, 1);
    setFieldValue("sub_categories_attributes", newSubcategory);
  };

  const handleOnChangeDescription = ({
    e,
    setFieldValue,
    index_subcategory,
  }) => {
    const value = e.target.value;

    setFieldValue(
      `sub_categories_attributes[${index_subcategory}].description`,
      value
    );
  };
  return (
    <>
      <div
        className="flex justify-center gap-9"
        style={{ padding: "0 var(--sp_lv_8) var(--sp_lv_4)" }}
      >
        <div className={classes["btn_delete"]}>
          <Tooltip
            placement="bottom"
            title={i18NextText("category.tooltip_delete_subcategory")}
          >
            <ButtonComponent
              icon={
                <Icons.DeleteOutlined style={{ fontSize: "var(--fs_3xl)" }} />
              }
              onClick={handleDeleteOption}
            ></ButtonComponent>
          </Tooltip>
        </div>

        <div className="space-y-5 w-[100%]">
          <div className="flex flex-col gap-5">
            <div className="flex justify-between items-center">
              <h4
                style={{
                  color: "var(--secondary_text)",
                }}
              >
                {i18NextText("category.field_name_subcategory")}{" "}
              </h4>
              <div className="flex gap-5 items-center">
                <h4
                  style={{
                    color: "var(--secondary_text)",
                  }}
                >
                  {i18NextText("category.field_status_subcategory")}{" "}
                </h4>
                <Switch
                  checked={
                    sub_categories_attributes[index_subcategory].status ==
                    "active"
                      ? true
                      : false
                  }
                  onChange={(value) =>
                    setFieldValue(
                      `sub_categories_attributes[${index_subcategory}].status`,
                      value ? "active" : "deactivate"
                    )
                  }
                />
              </div>
            </div>
            <InputField
              name={`sub_categories_attributes[${index_subcategory}].name`}
              value={sub_categories_attributes[index_subcategory].name}
              type="text"
              placeholder={i18NextText("category.form_placeholder_subcategory")}
              onChange={(e) =>
                setFieldValue(
                  `sub_categories_attributes[${index_subcategory}].name`,
                  e.target.value
                )
              }
            />
            <p style={{ color: "var(--red_5}" }}>
              <ErrorMessage
                name={`sub_categories_attributes[${index_subcategory}].name`}
              />
            </p>
          </div>
          <div className="flex flex-col gap-2">
            <h4
              style={{
                color: "var(--secondary_text)",
              }}
            >
              {i18NextText("product.form_label_description")}
            </h4>
            <TextArea
              value={sub_categories_attributes[index_subcategory].description}
              placeholder={i18NextText("product.form_placeholder_description")}
              className={classes["description-textarea"]}
              onChange={(e) =>
                handleOnChangeDescription({
                  e,
                  setFieldValue,
                  index_subcategory,
                })
              }
              autoSize={{ minRows: 2, maxRows: 10 }}
            />
          </div>
          {/* Image field */}
          <div className="flex flex-col gap-5">
            <h4
              style={{
                color: "var(--secondary_text)",
              }}
            >
              {i18NextText("category.images")}
            </h4>
            <div
              style={{
                minHeight: "150px",
                border: "1px dashed var(--gray_7)",
                borderRadius: "1rem",
                padding: "var(--sp_lv_2)",
              }}
              className="flex flex-col justify-center items-center gap-2"
            >
              <UploadImagesComponent
                images_attributes={
                  sub_categories_attributes[index_subcategory].images_attributes
                }
                setFieldValue={setFieldValue}
                nameFieldValue={`sub_categories_attributes[${index_subcategory}].images_attributes`}
              />
              <div>
                <p>{i18NextText("category.images_description")}</p>
              </div>
            </div>
          </div>
          {/* Image Banner Field */}
          <div className="flex flex-col gap-5">
            <h4
              style={{
                color: "var(--secondary_text)",
              }}
            >
              {i18NextText("category.image_banner_category")}
            </h4>
            <div
              style={{
                minHeight: "150px",
                border: "1px dashed var(--gray_7)",
                borderRadius: "1rem",
                padding: "var(--sp_lv_2)",
              }}
              className="flex flex-col justify-center items-center gap-2"
            >
              <UploadImagesComponent
                images_attributes={
                  sub_categories_attributes[index_subcategory]
                    .image_banners_attributes
                }
                setFieldValue={setFieldValue}
                nameFieldValue={`sub_categories_attributes[${index_subcategory}].image_banners_attributes`}
                numberImagesLimit={1}
                isMultiple={false}
              />
              <div>
                <p>{i18NextText("category.images_description")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Divider />
    </>
  );
};

export default SubcategoryComponent;
