import { Navigate, Outlet } from "react-router-dom";

import { PATH_NAME } from "Constants/PathName.Constant";
import { PublicLayout } from "Components/Layouts";
import { getItem } from "Utils/LocalStorage.Util";
import { LOCAL_STORAGE } from "Constants/LocalStorage.Constant";

const PublicRoute: React.FC = () => {
  const token = getItem(LOCAL_STORAGE.TOKEN);

  if (token) {
    return <Navigate to={PATH_NAME.PRODUCTS} replace />;
  }

  return (
    <PublicLayout>
      <Outlet />
    </PublicLayout>
  );
};

export default PublicRoute;
