import { BrowserRouter } from "react-router-dom";

import store from "Store";
import "I18n";
import { generatePseudoClass } from "CoreUI/Styles/DesignSystem";
import StoreProvider from "Configs/Store.Configs";
import "CoreUI/Styles/globals.scss";
import { ModalProvider } from "Context/Modal";

import Routes from "./Router";

generatePseudoClass();

function App() {
  return (
    <BrowserRouter>
      <StoreProvider store={store}>
        <ModalProvider>
          <Routes />
        </ModalProvider>
      </StoreProvider>
    </BrowserRouter>
  );
}

export default App;
