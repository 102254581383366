import React from "react";
import { RouteProps } from "react-router-dom";

import { Button } from "Components/Lib";

import { Result } from "antd";
import { PATH_NAME } from "Constants/PathName.Constant";

interface IState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<RouteProps & any, IState> {
  constructor(props: RouteProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // componentDidCatch(error, errorInfo) {
  //   // Catch errors in any components below and re-render with error message
  // }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="h-full flex items-center justify-center">
          <Result
            status="500"
            title="Oops! Something Went Wrong"
            subTitle="Try to refresh this page or feel free to contact us if the problem persists."
            extra={
              <Button
                type="primary"
                onClick={() => (window.location.href = PATH_NAME.HOME)}
              >
                Back Home
              </Button>
            }
          />
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
