import React, { useState } from "react";

import { useStoreActions } from "Store";
import { IOrder } from "Types/Order";
import { i18NextText } from "Utils/I18Next.Util";
import { _cloneDeep } from "Utils/Lodash.Util";
import SelectComponent from "CoreUI/Components/Common/Select";
import { notification } from "Utils/Notification.Util";

type SelectActionProps = {
  data: IOrder;
};

const SelectAction = ({ data }: SelectActionProps) => {
  const [value, setValue] = useState(data?.status);
  const changeStatusOrder = useStoreActions(
    (action) => action.orders.detailOrder.changeStatusOrder
  );

  const options = [
    {
      label: i18NextText("order.label_new"),
      value: null,
      disabled: true,
    },
    {
      label: i18NextText("order.label_pending"),
      value: "pending",
      disabled: value !== null ? true : false,
    },
    {
      label: i18NextText("order.label_processing"),
      value: "processing",
      disabled: value == "completed" || value == "processing" ? true : false,
    },
    { label: i18NextText("order.label_completed"), value: "completed" },
    {
      label: i18NextText("order.label_cancelled"),
      value: "cancelled",
    },
  ];

  const handleSelectAction = async (value) => {
    const dataOrder = _cloneDeep(data);
    dataOrder.status = value;
    setValue(value);
    const response = await changeStatusOrder({ dataOrder });
    if (response.success) {
      location.reload();
    } else {
      notification.error({
        message: "",
        description: i18NextText("error.notification_error"),
      });
    }
  };

  return (
    <SelectComponent
      value={value}
      options={options}
      onChange={(value) => handleSelectAction(value)}
      className="w-[120px]"
      disabled={value == "cancelled" || value == "completed" ? true : false}
    />
  );
};

export default SelectAction;
