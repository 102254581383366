import { useState } from "react";
import { FormikHelpers } from "formik";

import { _cloneDeep, _isEmpty } from "Utils/Lodash.Util";
import {
  IDetailValues,
  IOptionAttributes,
  IProductOptionAttributes,
  IVariants,
} from "Types/Product";
import { Yup } from "Utils/Yup.Util";

interface IOptionVariant {
  option_attributes?: IOptionAttributes;
  product_options_attributes_index?: number;
  setFieldValue: FormikHelpers<IDetailValues>["setFieldValue"];
  product_options_attributes: IProductOptionAttributes[];
  variants: IVariants[];
}

interface IState extends IOptionAttributes {
  isNewOption: boolean;
}

const useOptionAtributes = ({
  option_attributes,
  product_options_attributes_index,
  setFieldValue,
  product_options_attributes,
  variants,
}: IOptionVariant) => {
  const [optionAttributes, setOptionAttributes] = useState(
    !_isEmpty(option_attributes)
      ? { ...option_attributes, isNewOption: false }
      : {
          id: null,
          name: "",
          isNewOption: true,
          option_values_attributes: [
            {
              id: null,
              value: "",
            },
          ],
        }
  );

  const [productOptionAttributes, setProductOptionAttributes] = useState(
    product_options_attributes
  );
  const [toggleEditOption, setToggleEditOption] = useState(
    !option_attributes?.name ? true : false
  );

  const [fieldError, setFieldError] = useState({
    name: undefined,
    option_values_attributes: undefined,
  });

  //ONCHANGE OPTION NAME
  const handleOption = (e) => {
    if (fieldError) {
      setFieldError({ ...fieldError, name: undefined });
    }
    const newOptionAttributes = _cloneDeep(optionAttributes);
    newOptionAttributes.name = e.target.value;
    setOptionAttributes((prev) => ({ ...prev, name: e.target.value }));

    const newProductOptionAttrs = _cloneDeep(productOptionAttributes);
    newProductOptionAttrs[product_options_attributes_index].option_attributes =
      newOptionAttributes;
    setProductOptionAttributes([...newProductOptionAttrs]);
  };

  // ONCHANGE OPTION VALUE
  const handleChangeOptionValue = (value, option_values_attribute_index) => {
    if (fieldError) {
      setFieldError({ ...fieldError, option_values_attributes: undefined });
    }
    const newOption = _cloneDeep(optionAttributes);
    newOption.option_values_attributes[option_values_attribute_index].value =
      value;

    if (
      newOption.option_values_attributes[
        newOption.option_values_attributes.length - 1
      ].value !== null
    ) {
      newOption.option_values_attributes.push({
        id: null,
        value: null,
      });
    }
    setOptionAttributes({ ...newOption });

    const filterNullAttribute = newOption.option_values_attributes.filter(
      (element) => !!element.value
    );

    newOption.option_values_attributes = filterNullAttribute;
    const newProductOptionAttrs = _cloneDeep(productOptionAttributes);
    newProductOptionAttrs[product_options_attributes_index].option_attributes =
      newOption;

    setProductOptionAttributes([...newProductOptionAttrs]);
  };

  // SET FIELD VALUE VARIANT
  const setFieldValueVariant = (productOptionAttributes) => {
    let listVariant = [];
    const attribute = [];
    productOptionAttributes.forEach(({ option_attributes }) => {
      attribute.push({
        option: option_attributes.name,
        optionValuesAttributes: option_attributes.option_values_attributes.map(
          (e) => ({ id: e.id, value: e.value })
        ),
      });
    });

    if (attribute.length) {
      const objectAttribute = attribute.reduce(
        (obj, item) =>
          Object.assign(obj, { [item.option]: item.optionValuesAttributes }),
        {}
      );

      for (const [key, values] of Object.entries<string[]>(objectAttribute))
        listVariant.push(values.map((v) => ({ [key]: v })));

      listVariant = listVariant.reduce((a, b) =>
        a.flatMap((d) => b.map((e) => ({ ...d, ...e })))
      );

      listVariant = listVariant.map((variant) => Object.entries(variant));

      listVariant = listVariant.map(
        (variant) =>
          (variant = variant.map((e) => ({
            id: e[1].id,
            option: e[0],
            value: e[1].value,
          })))
      );

      const initialVariant = {
        id: null,
        title: "this is title",
        price: 0,
        original_price: 0,
        sku: "",
        product_variant_options: [],
        rating: 5,
        quantity: 0,
        images_attributes: [],
      };
      const originalVariants = variants.map((variant) => ({
        ...variant,
        price: Number(variant.price),
        original_price: Number(variant.original_price),
        product_variant_options: variant.product_variant_options.map(
          (variantOption) => ({
            idProductVariantOption: variantOption.id,
            optionValue: variantOption.value.id,
          })
        ),
      }));
      let listVariantToSave;
      if (!originalVariants.length) {
        listVariantToSave = listVariant.map((variant) => ({
          ...initialVariant,
          product_variant_options: variant,
        }));
        setFieldValue("variants", listVariantToSave);
      } else {
        listVariantToSave = listVariant.map((variant) => {
          const arrayId = variant.map((e) => e.id);
          if (arrayId.includes(null)) {
            const newVariant = variant.map((item) => ({ ...item, id: null }));
            return {
              ...initialVariant,
              product_variant_options: newVariant,
            };
          } else {
            const compareIdOption = originalVariants.filter(
              (variant) =>
                // const arrayOptionValue = variant.product_variant_options.map(
                //   (e) => e.optionValue
                // );
                variant.product_variant_options
                  .map((e) => e.optionValue)
                  .toString() === arrayId.toString()
            );
            if (compareIdOption.length) {
              const indexVariant = originalVariants.findIndex(
                (variant) =>
                  variant.product_variant_options
                    .map((e) => e.optionValue)
                    .toString() === arrayId.toString()
              );
              const arrayIdProductVariantOption = [];
              originalVariants[indexVariant].product_variant_options.forEach(
                (item) =>
                  arrayIdProductVariantOption.push(item.idProductVariantOption)
              );
              const newProductVariantOption = variant.map((e, index) => ({
                ...e,
                id: arrayIdProductVariantOption[index],
              }));
              return {
                ...originalVariants[indexVariant],
                images_attributes:
                  (!!originalVariants[indexVariant].images_attributes.length &&
                    originalVariants[indexVariant].images_attributes.map(
                      (image) => ({
                        ...image,
                        uid: image.id.toString(),
                        name: "",
                        status: "done",
                        url: image.public_url,
                        isValid: true,
                        isUploaded: true,
                      })
                    )) ||
                  [],
                product_variant_options: newProductVariantOption,
              };
            } else {
              return { ...initialVariant, product_variant_options: variant };
            }
          }
        });
        setFieldValue("variants", listVariantToSave);
      }
    }
  };

  // VALIDATE OPTION FIELD
  const handleValidateOption = async (values: IState) => {
    const optionSchema = Yup.object({
      name: Yup.string().required("Option name is required"),
      option_values_attributes: Yup.array().min(
        2,
        "Option value is required at least 1 value"
      ),
    });

    let isHasError = false;

    await optionSchema
      .validate(values, { abortEarly: false })
      .catch(function (errors) {
        isHasError = true;
        const errorMessages: any = {};
        errors.inner.forEach((error: any) => {
          errorMessages[error.path] = error.message;
        });
        setFieldError(errorMessages);
      });
    // })
    return isHasError;
  };

  const onClickDone = async () => {
    const cloneOptionAttributes = _cloneDeep(optionAttributes);
    const isHasError = await handleValidateOption(cloneOptionAttributes);
    if (isHasError) return;
    cloneOptionAttributes.isNewOption = false;
    const filterNullAttribute =
      cloneOptionAttributes.option_values_attributes.filter(
        (element) => !!element.value
      );
    cloneOptionAttributes.option_values_attributes = filterNullAttribute;

    const newProductOptionAttributes = _cloneDeep(productOptionAttributes);
    newProductOptionAttributes[
      product_options_attributes_index
    ].option_attributes = cloneOptionAttributes;
    setOptionAttributes((prev) => ({
      ...prev,
      isNewOption: false,
      option_values_attributes: filterNullAttribute,
    }));

    setFieldValue(
      `product_options_attributes[${product_options_attributes_index}].option_attributes`,
      cloneOptionAttributes
    );

    // SET FIELD VALUE VARIANT
    setFieldValueVariant(newProductOptionAttributes);
    setToggleEditOption(false);
  };

  // DELETE OPTION
  const handleDeleteOption = () => {
    const newProductOptionAttributes = _cloneDeep(product_options_attributes);
    newProductOptionAttributes.splice(product_options_attributes_index, 1);
    if (
      product_options_attributes_index <=
      newProductOptionAttributes.length - 1
    ) {
      setOptionAttributes((prev) => ({
        ...prev,
        ...newProductOptionAttributes[product_options_attributes_index]
          .option_attributes,
      }));
      setToggleEditOption(!toggleEditOption);
    }
    setProductOptionAttributes([...newProductOptionAttributes]);
    if (!optionAttributes?.isNewOption) {
      setFieldValue("product_options_attributes", newProductOptionAttributes);
      setFieldValueVariant(newProductOptionAttributes);
    }
  };

  // DELETE VALUE OPTION
  const handleDeleteValueOption = (option_values_attribute_index) => {
    const newOptionAttributes = _cloneDeep(optionAttributes);
    newOptionAttributes.option_values_attributes.splice(
      option_values_attribute_index,
      1
    );
    setOptionAttributes({ ...newOptionAttributes });
  };

  // EDIT OPTION
  const handleEditOption = () => {
    const newOptionAttributes = _cloneDeep(optionAttributes);
    const lengthOptionValue =
      newOptionAttributes.option_values_attributes.length;
    if (
      newOptionAttributes.option_values_attributes[lengthOptionValue - 1]
        .value !== null
    ) {
      newOptionAttributes.option_values_attributes.push({
        id: null,
        value: null,
      });
    }
    setOptionAttributes({ ...newOptionAttributes });
    setToggleEditOption(true);
  };

  return {
    toggleEditOption,
    optionAttributes,
    onClickDone,
    handleOption,
    handleChangeOptionValue,
    handleDeleteOption,
    handleDeleteValueOption,
    handleEditOption,
    fieldError,
  };
};

export default useOptionAtributes;
