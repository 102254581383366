import { useStoreActions, useStoreState } from "Store";
import { Switch } from "Components/Lib";
import DataTable from "CoreUI/Components/Common/DataTable";
import { i18NextText } from "Utils/I18Next.Util";
import { notification } from "Utils/Notification.Util";

const TableCustomersListComponent = () => {
  const data = useStoreState((state) => state.customers.list.data);
  const pagination = useStoreState((state) => state.customers.list.pagination);
  const loading = useStoreState((state) => state.customers.list.loading);

  const getCustomersList = useStoreActions(
    (state) => state.customers.list.getCustomersList
  );

  const columns = [
    {
      title: `${i18NextText("customers.col_first_name")}`,
      dataIndex: "first_name",
      width: 200,
    },
    {
      title: `${i18NextText("customers.col_last_name")}`,
      dataIndex: "last_name",
      width: 200,
    },

    {
      title: `${i18NextText("customers.col_gender")}`,
      dataIndex: "gender",
    },
    {
      title: `${i18NextText("customers.col_email")}`,
      dataIndex: "email",
    },
    {
      title: `${i18NextText("customers.col_address")}`,
      dataIndex: "address",
    },
    {
      title: `${i18NextText("customers.col_active")}`,
      dataIndex: "active",
      render: (text, record) => {
        return (
          <Switch
            disabled={true}
            defaultChecked={record.active ? true : false}
          />
        );
      },
    },
  ];

  const onPageChange = async (tablePagination) => {
    const response = await getCustomersList({ page: tablePagination.current });
    if (!response.success) {
      notification.error({
        message: "",
        description: i18NextText("error.notification_error"),
      });
    }
  };

  return (
    <div style={{ padding: "var(--sp_lv_8)" }}>
      <DataTable
        data={data}
        columns={columns}
        rowKey={(record: any) => {
          return record.id;
        }}
        loading={loading}
        onChange={onPageChange}
        pagination={{
          current: pagination.page,
          pageSize: pagination.per_page,
          total: pagination.total_records,
        }}
      />
    </div>
  );
};

export default TableCustomersListComponent;
