import { ICategoriesParams } from "Types/Category";
import api from "Configs/Axios.Config";
import { _get } from "Utils/Lodash.Util";
import { SERVICES } from "Constants/Service.Constant";
import normalizeError from "Utils/NomalizeError.Util";
import { generatePath } from "react-router-dom";

export const getCategoriesList = async (params?: ICategoriesParams) => {
  try {
    const response = await api.get(SERVICES.GET_CATEGORIES_DATA, { params });
    const categories = _get(response.data, "records", []);
    const page = _get(response.data, "pagination.page", 1);
    const next_page = _get(response.data, "pagination.per_page", null);
    const total_records = _get(response.data, "pagination.total_count", 0);
    return {
      success: true,
      data: categories,
      pagination: { page, next_page, total_records },
      error: null,
    };
  } catch (errors) {
    const pagination = {
      page: 1,
      next_page: null,
      total_records: 0,
    };
    return {
      success: false,
      data: [],
      pagination,
      error: normalizeError(errors),
    };
  }
};

// ONE CATEGORY
export const getOneCategoryData = async (categoryId: string) => {
  try {
    const response = await api.get(
      generatePath(SERVICES.GET_ONE_CATEGORY, { id: categoryId })
    );
    return {
      success: true,
      data: response.data.record,
      error: null,
    };
  } catch (errors) {
    return {
      success: false,
      data: {},
      error: normalizeError(errors),
    };
  }
};

export const createCategory = async (newCategory: object) => {
  try {
    await api.post(SERVICES.CREATE_NEW_CATEGORY, newCategory);
    return {
      success: true,
      error: null,
    };
  } catch (errors) {
    return {
      success: false,
      error: normalizeError(errors),
    };
  }
};

export const deleteOneCategory = async (categoryId: string) => {
  try {
    await api.delete(generatePath(SERVICES.DELETE_CATEGORY, { categoryId }));
    return {
      success: true,
      error: null,
    };
  } catch (errors) {
    return {
      success: false,
      error: normalizeError(errors),
    };
  }
};

export const updateCategory = async (category_id: string, values) => {
  try {
    await api.put(
      generatePath(SERVICES.EDIT_CATEGORY, { category_id }),
      values
    );
    return {
      success: true,
      error: null,
    };
  } catch (errors) {
    return {
      success: false,
      error: normalizeError(errors),
    };
  }
};
