import { useImperativeHandle, useMemo, useState } from "react";
import { Modal, ModalProps } from "antd";
import classnames from "classnames";

import classes from "./Modal.module.scss";

import Image from "CoreUI/Components/Common/Image";

import closeIcon from "Assets/Images/SVG/icon_close.svg";

import {
  ContextModalHeaderProps,
  ContextModalProps,
  ModalTitle,
} from "../types";
import LoadingPage from "../PageLoading";

const ModalHeader: React.FC<ContextModalHeaderProps> = ({
  title,
  hideModal,
}) => {
  const computedTitle = useMemo(() => {
    if (typeof title === "string" && title.length) {
      return <p className={classes["modal_header--title"]}>{title}</p>;
    }
    if (typeof title === "object") {
      return title;
    }

    return "";
  }, [title]);

  return (
    <div
      className={classnames(classes["modal_header"], "mb-8", {
        [classes["modal_header_has_title"]]: !!title,
      })}
    >
      {!!computedTitle && computedTitle}
      <div className="cursor-pointer" onClick={hideModal}>
        <Image src={closeIcon} width={32} height={32} alt="icon_close" />
      </div>
    </div>
  );
};

const CustomModal: React.FC<ModalProps & { children?: any }> = (props) => {
  return <Modal {...props} />;
};

interface IState {
  loading: boolean;
  visible: boolean;
  content: null | React.ReactNode;
  title: ModalTitle["title"];
  showTitle: boolean;
  width: string | number;
  height: string | number;
}

const ModalComponent: React.FC<ContextModalProps> = ({ modalRef }) => {
  const [state, setState] = useState<IState>({
    loading: true,
    visible: false,
    content: null,
    title: "",
    showTitle: false,
    width: 480,
    height: 480,
  });

  const showModal = () => {
    setState((prevState) => ({ ...prevState, visible: true }));
  };

  const hideModal = () => {
    setState((prevState) => ({
      ...prevState,
      visible: false,
      content: null,
      loading: true,
      width: "500",
      height: "500",
    }));
  };

  useImperativeHandle(modalRef, () => ({
    show: () => showModal(),
    hide: () => hideModal(),
    update: (content: any, options: ContextModalProps["options"]) => {
      setState((prevState) => ({
        ...prevState,
        visible: true,
        loading: false,
        content,
        title: options?.title || "",
        showTitle: options?.showTitle || false,
        width: options?.width || 480,
        height: options?.height || 480,
      }));
    },
    showLoading: () =>
      setState((prevState) => ({
        ...prevState,
        loading: true,
        width: 300,
        height: 300,
      })),
    hideLoading: () =>
      setState((prevState) => ({ ...prevState, loading: false })),
  }));

  return (
    <CustomModal
      wrapClassName={classnames(classes["modal_box"])}
      title={
        state.showTitle && !state.loading ? (
          <ModalHeader title={state.title} hideModal={hideModal} />
        ) : null
      }
      open={state.visible}
      centered
      destroyOnClose
      closable={false}
      footer={null}
      width={state.width}
      style={{ height: state.height }}
    >
      {state.loading ? <LoadingPage /> : state.content}
    </CustomModal>
  );
};

export default ModalComponent;
