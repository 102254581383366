import { useNavigate } from "react-router-dom";

import { useStoreState, useStoreActions } from "Store";
import DataTable from "CoreUI/Components/Common/DataTable";
import { i18NextText } from "Utils/I18Next.Util";
import { notification } from "Utils/Notification.Util";

import { PATH_NAME } from "Constants/PathName.Constant";
import { generatePath } from "Utils/GeneratePath.Util";
import { _cloneDeep } from "Utils/Lodash.Util";

import TableTitle from "./TableTitle";
import columnsOrder from "./ColumnsOrderTable";
import classes from "./TableOrdersList.module.scss";

const TableOrdersList = () => {
  const navigate = useNavigate();
  const data = useStoreState((state) => state.orders.ordersList.data);
  const pagination = useStoreState(
    (state) => state.orders.ordersList.pagination
  );
  const loading = useStoreState((state) => state.orders.ordersList.loading);

  const getOrdersList = useStoreActions(
    (action) => action.orders.ordersList.getOrdersList
  );

  const changeStatusOrder = useStoreActions(
    (action) => action.orders.detailOrder.changeStatusOrder
  );

  const countNewOrder = useStoreActions(
    (action) => action.orders.ordersList.countNewOrder
  );

  const onPageChange = async (tablePagination) => {
    const response = await getOrdersList({ page: tablePagination.current });
    if (!response.success) {
      notification.error({
        message: "",
        description: i18NextText("error.notification_error"),
      });
    }
  };

  const handleViewOrderDetail = async (record: any) => {
    const dataOrder = _cloneDeep(record);
    if (dataOrder.status == null) {
      dataOrder.status = "pending";
      const response = await changeStatusOrder({ dataOrder });
      if (response.success) {
        await countNewOrder();
        navigate(generatePath(PATH_NAME.ORDER_DETAIL, { order_id: record.id }));
      } else {
        notification.error({
          message: "",
          description: i18NextText("error.notification_error"),
        });
      }
    } else {
      navigate(generatePath(PATH_NAME.ORDER_DETAIL, { order_id: record.id }));
    }
  };

  return (
    <div style={{ padding: "var(--sp_lv_2) var(--sp_lv_3) var(--sp_lv_8)" }}>
      <DataTable
        data={data}
        columns={columnsOrder}
        rowKey={(record: any) => {
          return record.id;
        }}
        loading={loading}
        pagination={{
          current: pagination.page,
          pageSize: pagination.per_page,
          total: pagination.total_records,
        }}
        onChange={onPageChange}
        title={() => <TableTitle />}
        onRow={(record) => {
          return {
            onDoubleClick: () => handleViewOrderDetail(record),
          };
        }}
        customStyle={classes["table-container"]}
      />
    </div>
  );
};

export default TableOrdersList;
