export const generatePath = (url: string, params?: any) => {
  if (typeof params !== "object") return url;

  const keys = Object.keys(params);

  if (keys.length) {
    keys.forEach((key) => {
      const replaceString = `:${key}`;
      const value = params[key];
      url = url.replace(replaceString, value);
    });
    return url;
  } else {
    return url;
  }
};
