import systemColors from "./Colors";
import spacing from "./Spacing";
import fontSize from "./FontSize";
import fontHeight from "./FontHeight";
import shadow from "./Shadow";
import fontWeight from "./FontWeight";
import rounded from "./Rounded";

const THEMES = {
  light: { colors: systemColors },
};

const getTheme = () => {
  return localStorage.getItem("fresh_site_theme") || "light";
};

const getColors = () => {
  const theme = getTheme();

  const colors = THEMES[theme].colors;
  if (!colors) return {};
  return colors;
};

const getColor = (color: string | number) => {
  const colors = getColors();
  return colors[color];
};

const generatePseudoClass = () => {
  const STYLE = document.documentElement.style;

  // Add pseudo color to root
  Object.keys(systemColors.colorPalettes).forEach((colorKey) => {
    STYLE.setProperty(`--${colorKey}`, systemColors.colorPalettes[colorKey]);
  });
  Object.keys(systemColors.productColor).forEach((colorKey) => {
    STYLE.setProperty(`--${colorKey}`, systemColors.productColor[colorKey]);
  });

  // Add pseudo spacing to root
  Object.keys(spacing).forEach((key) => {
    STYLE.setProperty(`--sp_${key}`, spacing[key]);
  });
  // Add pseudo fontsize to root
  Object.keys(fontSize).forEach((key) => {
    STYLE.setProperty(`--fs_${key}`, fontSize[key]);
  });

  // Add pseudo fontWeight to root
  Object.keys(fontHeight).forEach((key) => {
    STYLE.setProperty(`--fh_${key}`, fontHeight[key]);
  });

  // Add pseudo shadow to root
  Object.keys(shadow).forEach((key) => {
    STYLE.setProperty(`--${key}`, shadow[key]);
  });

  // Add pseudo font-weight to root
  Object.keys(fontWeight).forEach((key) => {
    STYLE.setProperty(`--fw_${key}`, fontWeight[key]);
  });

  // Add pseudo rounded to root
  Object.keys(rounded).forEach((key) => {
    STYLE.setProperty(`--br_${key}`, rounded[key]);
  });
};

export {
  shadow,
  rounded,
  spacing,
  fontSize,
  fontWeight,
  fontHeight,
  systemColors as colors,
  generatePseudoClass,
  getColor,
};
