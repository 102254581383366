const product = {
  products: "Products",
  btn_add_product: "Add Product",
  btn_filter: "Filter",
  btn_clear_filter: "Clear",
  column_name: "PRODUCT",
  column_status: "STATUS",
  column_category: "CATEGORY",
  column_quantity: "QUANTITY",
  column_description: "Description",
  column_price: "PRICE",
  form_label_name: "Title",
  form_label_description: "Description",
  form_placeholder_name: "Enter name of product",
  form_placeholder_category: "Enter category of product",
  form_placeholder_description: "Enter description of product",
  Title_form_edit: "Edit Product",
  btn_update: "UPDATE",
  Title_form_create: "Create Product",
  btn_create: "CREATE",
  validator_name: "Product's name is required",
  validator_category: "Category is required",
  validator_brand: "Brand is required",
  validator_number: "Please enter the number",
  // IMAGES PRODUCT
  images_product: "Images",
  images_description: "Accept images with .PNG, .JPG, .JPEG",

  //Pricing
  pricing: "Pricing",
  form_label_price: "Price",
  form_label_original_price: "Original price",
  validator_price: " Price is required",
  validator_ori_price: " Original price is required",

  // INVENTORY
  inventory: "Inventory",
  form_label_SKU: "SKU (Stock Keeping Unit)",
  form_label_quantity: "Quantity",
  validator_SKU: "SKU (Stock Keeping Unit) is required",
  validator_quantity: "Quantity is required",

  //SHIPPING
  shipping: "Shipping",
  form_label_weight: "Weight",
  weight_description:
    "Used to calculate shipping rates at checkout and label prices during fulfillment.",
  customs_information: "CUSTOMS INFORMATION",
  customs_information_description:
    "Customs authorities use this information to calculate duties when shipping internationally. Shown on printed customs forms.",
  form_label_country_of_origin: "Country/Region of origin",
  country_of_origin_description:
    "In most cases, where the product is manufactured.",
  placeholder_country: "Select country/region",

  // OPTION ATTRIBUTES
  option_title: "Options",
  checkbox_content: "This product has options, like size or color...",
  option_name: "Option name",
  option_values: "Option values",
  btn_add_option: "Add another description",
  btn_done: "Done",
  btn_edit: "Edit",
  select_size: "Size",
  select_color: "Color",
  select_material: "Material",
  select_style: "Style",

  // VARIANT PRODUCT
  variant_title: "Variants",
  content_show_variant: "Showing all variants",
  content_total_inventory: "Total inventory at store",
  available: "{{number}} available",
  available_at_store: "available at store",
  validator_option_name: "Option name is required.",
  validator_option_value: "Option value is required.",
  //---variant button edit
  content_title: "Edit titles",
  content_price: "Edit prices",
  content_price_original: "Edit original prices",
  content_quantity: "Edit quantities",
  content_SKU: "Edit SKUs",
  content_weight: "Edit weight",
  //----modal edit variant
  apply_a: "Apply a",
  to_all_variants: "to all variants",
  apply_to_all: "Apply to all",
  cancel_btn: "Cancel",
  done_btn: "Done",
  validator_field_number: "Please enter the number",
  validator_field_title: "Title is required",
  validator_field_quantity: "Quantity is required",
  validator_field_price: "Price is required",
  validator_original_price: "Original price is required",
  validator_field_sku: "SKU is required",
  validator_field_weight: "Weight is required",

  //PRODUCT STATUS
  product_status: "Product status",
  active: "Active",
  draft: "Draft",
  deactivate: "Deactivate",

  //PRODUCT ORGANIZATION
  organization: "Product organization",
  form_label_category: "Product Category",
  form_label_brand: "Product Brand",
  form_label_tags: "Tags",
  validator_country: "Country/Region of origin  is required",
};

export default product;
