import InputSearchComponent from "Components/Own/InputSearchComponent";
import { useStoreActions, useStoreState } from "Store";
import { memo } from "react";

const SearchProduct = () => {
  const filterParams = useStoreState(
    (state) => state.products.list.filterParams
  );

  const setKeyword = useStoreActions(
    (action) => action.products.list.setKeyword
  );
  const getProductsList = useStoreActions(
    (state) => state.products.list.getProductsList
  );

  return (
    <InputSearchComponent
      getDataList={getProductsList}
      setKeyword={setKeyword}
      value={filterParams.keyword}
      placeholder="Search products here...."
    />
  );
};

export default memo(SearchProduct);
