import { Yup } from "Utils/Yup.Util";
import { i18NextText } from "Utils/I18Next.Util";

const GetPassSchema = Yup.object().shape({
  email: Yup.string()
    .email(i18NextText("auth.getPass_validator_invalid_email"))
    .required(i18NextText("auth.getPass_validator_email")),
});

export default GetPassSchema;
