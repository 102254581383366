import api from "Configs/Axios.Config";
import { SERVICES } from "Constants/Service.Constant";
import { IReviewParams } from "Types/Review";
import { generatePath } from "Utils/GeneratePath.Util";
import { _get } from "Utils/Lodash.Util";
import normalizeError from "Utils/NomalizeError.Util";

export const getReviewsList = async (params?: IReviewParams) => {
  try {
    const response = await api.get(SERVICES.GET_REVIEWS_DATA, { params });
    const reviews = _get(response.data, "comments", []);
    const page = _get(response.data, "pagination.page", 1);
    const next_page = _get(response.data, "pagination.per_page", 10);
    const total_records = _get(response.data, "pagination.total_count", 0);
    return {
      success: true,
      data: reviews,
      pagination: { page, next_page, total_records },
      error: null,
    };
  } catch (errors) {
    const pagination = {
      page: 1,
      next_page: 10,
      total_records: 0,
    };
    return {
      success: false,
      data: [],
      pagination,
      error: normalizeError(errors),
    };
  }
};

export const editReview = async ({ id, status }) => {
  try {
    await api.put(generatePath(SERVICES.EDIT_REVIEW, { id }), { status });
    return {
      success: true,
      error: null,
    };
  } catch (errors) {
    return {
      success: false,
      error: normalizeError(errors),
    };
  }
};
