import { useEffect } from "react";
import { ErrorMessage, Form, Formik, FormikProps } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import classNames from "classnames";

import { useStoreActions, useStoreState } from "Store";
import { Icons, Skeleton, Col, Row, Switch } from "Components/Lib";
import { _get } from "Utils/Lodash.Util";
import { IDetailValues } from "Types/Merchant";
import { i18NextText } from "Utils/I18Next.Util";
import { PATH_NAME } from "Constants/PathName.Constant";
import ButtonComponent from "CoreUI/Components/Common/Button";
import InputField from "CoreUI/Components/Common/InputField";
import { notification } from "Utils/Notification.Util";

import classes from "./FormMerchant.module.scss";
import MerchantSchema from "./MerchantSchema";

const FormProduct = () => {
  const params = useParams();
  const navigate = useNavigate();
  const merchantId = _get(params, "merchant_id");
  const loading = useStoreState((state) => state.merchants.detail.loading);
  const data = merchantId
    ? useStoreState((state) => state.merchants.detail.data)
    : null;

  const getOneMerchant = useStoreActions(
    (action) => action.merchants.detail.getOneMerchant
  );
  const createMerchant = useStoreActions(
    (action) => action.merchants.detail.createMerchant
  );
  const updateInforMerchant = useStoreActions(
    (action) => action.merchants.detail.updateInforMerchant
  );

  useEffect(() => {
    if (!merchantId) return;
    const fetchdata = async () => {
      const response = await getOneMerchant({ merchantId });
      if (!response.success) {
        notification.error({
          message: "",
          description: i18NextText("error.notification_error"),
        });
      }
    };
    fetchdata();
  }, [merchantId, getOneMerchant]);

  const initialValues = {
    name: "",
    email: "",
    domain: "",
    limit_rate: "",
    active: false,
    plan: "",
    ...data,
  };

  const handleCreateMerchant = (values) => {
    const res = createMerchant(values);
    if (res.success) {
      navigate(PATH_NAME.MERCHANT);
    } else {
      notification.error({
        message: "",
        description: i18NextText("error.notification_error"),
      });
    }
  };

  const handleUpdateMerchant = (values) => {
    const res = updateInforMerchant({ merchantId, values });
    if (res.success) {
      navigate(PATH_NAME.MERCHANT);
    } else {
      notification.error({
        message: "",
        description: i18NextText("error.notification_error"),
      });
    }
  };

  if (merchantId && loading) return <Skeleton />;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={MerchantSchema}
      onSubmit={async (values: IDetailValues) => {
        if (merchantId) {
          handleUpdateMerchant(values);
        } else {
          handleCreateMerchant(values);
        }
      }}
    >
      {(props: FormikProps<IDetailValues>) => (
        <>
          <Form
            className={classNames(
              "flex flex-col gap-4",
              classes["form-merchant-container"]
            )}
          >
            <div
              className="flex justify-between"
              style={{ padding: "var(--sp_lv_8)" }}
            >
              <h1 className="font-semibold">
                {merchantId
                  ? i18NextText("merchant.Title_form_edit")
                  : i18NextText("merchant.Title_form_create")}
              </h1>
              <div className="flex gap-4">
                <ButtonComponent
                  className={classNames([classes["edit-merchant-btn"]])}
                  type="primary"
                  htmlType="submit"
                  icon={<Icons.PlusOutlined />}
                  disabled={!props.dirty}
                >
                  {merchantId
                    ? i18NextText("merchant.btn_update")
                    : i18NextText("merchant.btn_create")}
                </ButtonComponent>
              </div>
            </div>
            <div
              className="flex flex-col gap-6"
              style={{ padding: "var(--sp_lv_8)" }}
            >
              <Row gutter={16} className="flex justify-between">
                <Col span={12} className="flex flex-col gap-4">
                  <label>{i18NextText("merchant.form_label_name")}</label>
                  <InputField
                    defaultValue={props.values.name}
                    type="text"
                    name="name"
                    placeholder="Enter name"
                    onChange={props.handleChange}
                  />
                  <p className={classNames(classes["error_message"])}>
                    <ErrorMessage name="name" />
                  </p>
                </Col>
                <Col span={12} className="flex flex-col gap-4">
                  <label>{i18NextText("merchant.form_label_email")}</label>
                  <InputField
                    defaultValue={props.values.email}
                    type="text"
                    name="email"
                    placeholder="Enter email"
                    onChange={props.handleChange}
                  />
                  <p className={classNames(classes["error_message"])}>
                    <ErrorMessage name="email" />
                  </p>
                </Col>
              </Row>
              <Row gutter={16} className="flex justify-between">
                <Col span={12} className="flex flex-col gap-4">
                  <label>{i18NextText("merchant.form_label_domain")}</label>
                  <InputField
                    defaultValue={props.values.domain}
                    type="text"
                    name="domain"
                    placeholder="Enter domain"
                    onChange={props.handleChange}
                  />
                  <p className={classNames(classes["error_message"])}>
                    <ErrorMessage name="domain" />
                  </p>
                </Col>
                <Col span={12} className="flex flex-col gap-4">
                  <label>{i18NextText("merchant.form_label_limit_rate")}</label>
                  <InputField
                    defaultValue={props.values.limit_rate}
                    type="text"
                    name="limit_rate"
                    placeholder="Enter limit rate"
                    onChange={props.handleChange}
                  />
                  <p className={classNames(classes["error_message"])}>
                    <ErrorMessage name="limit_rate" />
                  </p>
                </Col>
              </Row>
              <Row className="flex flex-col gap-4">
                <label>{i18NextText("merchant.form_label_plan")}</label>
                <InputField
                  defaultValue={props.values.plan}
                  name="plan"
                  placeholder="Enter plan"
                  onChange={props.handleChange}
                />
                <p className={classNames(classes["error_message"])}>
                  <ErrorMessage name="plan" />
                </p>
              </Row>
              <div className="flex gap-4">
                <label>{i18NextText("customers.label_active")}</label>
                <Switch
                  checked={props.values.active}
                  onChange={(checked) => props.setFieldValue("active", checked)}
                />
              </div>
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
};
export default FormProduct;
