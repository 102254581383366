import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import laguages from "./laguages";

const resources = {
  "en-US": { translation: laguages["en-US"] },
};

i18next.use(initReactI18next).init({
  compatibilityJSON: "v3",
  resources,
  lng: "en-US",
  interpolation: {
    escapeValue: false,
  },
});

export default i18next;
