import { Yup } from "Utils/Yup.Util";
import { i18NextText } from "Utils/I18Next.Util";

const CategorySchema = Yup.object().shape({
  name: Yup.string().required(i18NextText("category.validator_name")),
  sub_category_attributes: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required(
        i18NextText("category.validator_name_subcategory")
      ),
    })
  ),
});

export default CategorySchema;
