import list from "./List";
import detail from "./Detail";
import { ListMerchantModel } from "./List/Type";
import { MerchantDetailModel } from "./Detail/Type";

export interface MerchantModel {
  list: ListMerchantModel;
  detail: MerchantDetailModel;
}

const merchant = { list, detail };

export default merchant;
