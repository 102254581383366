import ordersList from "./OrdersList";
import detailOrder from "./OrderDetail";
import { OrdersListModel } from "./OrdersList/Type";
import { OrderDetailModel } from "./OrderDetail/Type";

export interface OrdersModel {
  ordersList: OrdersListModel;
  detailOrder: OrderDetailModel;
}

const orders = { ordersList, detailOrder };

export default orders;
