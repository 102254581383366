const order = {
  title_Order: "Customer Orders",
  // Table orders list
  title_table: "Order List",
  column_order_no: "ID",
  column_customer_name: "CUSTOMER NAME",
  column_order_date: "ORDER DATE",
  column_total: "ORDER TOTAL",
  column_action: "ACTION",
  column_status: "STATUS",
  //Option Action

  // Table product ordered
  column_product_name: "PRODUCT",
  column_sku: "SKU",
  column_unit_price: "PRICE",
  column_quantity: "QUANTITY",
  column_order_total: "TOTAL AMOUNT",
  column_discount: "DISCOUNT",
  column_variant: "VARIANTS",
  status_new: "New",

  // Info shipping
  content_number: "No.",
  btn_cancel: "Cancel Order",
  label_phone: "Phone",
  label_email: "Email",
  label_address: "Address",
  label_payment_method: "Payment Method",
  label_shipping_method: "Shipping Method",
  label_note: "Note",

  // Select Action
  label_new: "New",
  label_pending: "Pending",
  label_processing: "Processing",
  label_completed: "Completed",
  label_cancelled: "Cancelled",
};

export default order;
