import React from "react";

import ClearFilterButton from "./ClearFilterButton";
import FilterButton from "./FilterButton";
import SearchProduct from "./SearchProduct";

const TableTitleProduct = () => {
  return (
    <div className="flex items-center justify-between">
      <h3>Product List</h3>
      <div className="flex flex-1 justify-end items-center gap-4">
        <SearchProduct />
        <FilterButton />
        <ClearFilterButton />
      </div>
    </div>
  );
};

export default React.memo(TableTitleProduct);
