import { useStoreActions, useStoreState } from "Store";
import Checkbox from "antd/lib/checkbox";
import { ORDER_STATUS_DATA } from "Constants/Order.Constants";
import { CheckboxValueType } from "antd/lib/checkbox/Group";

const FilterContentComponent = () => {
  const filterParams = useStoreState(
    (state) => state.orders.ordersList.filterParams
  );

  const getOrdersList = useStoreActions(
    (state) => state.orders.ordersList.getOrdersList
  );

  const handleOnChangeFilterStatus = async (
    checkedValues: CheckboxValueType[]
  ) => {
    await getOrdersList({
      filter: { ...filterParams, status: checkedValues.join(",") },
    });
  };

  return (
    <div>
      <Checkbox.Group
        className="p-8 flex flex-col gap-4"
        value={filterParams.status === "" ? [] : filterParams.status.split(",")}
        options={ORDER_STATUS_DATA}
        onChange={handleOnChangeFilterStatus}
      />
    </div>
  );
};

export default FilterContentComponent;
