import { Icons } from "Components/Lib";
import InputField from "CoreUI/Components/Common/InputField";
import React, { useEffect, useState } from "react";
import { useStoreActions, useStoreState } from "Store";

import classes from "./Search.module.scss";

const SearchOrder = () => {
  const [state, setState] = useState({ value: "" });

  const filterParams = useStoreState(
    (state) => state.orders.ordersList.filterParams
  );

  const getOrdersList = useStoreActions(
    (state) => state.orders.ordersList.getOrdersList
  );

  useEffect(() => {
    if (!state.value && !filterParams.keyword) {
      return;
    }
    const delayDebounceFn = setTimeout(() => {
      getOrdersList({
        filter: { ...filterParams, keyword: state.value.toLowerCase() },
      });
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.value, filterParams.keyword]);

  useEffect(() => {
    if (!filterParams.keyword) {
      setState((prevState) => ({ ...prevState, value: "" }));
    }
  }, [filterParams.keyword]);

  const onChangeKeyWord = (e: any) => {
    const value = e.target?.value;
    setState((prevState) => ({ ...prevState, value }));
  };

  return (
    <InputField
      value={state.value}
      prefix={<Icons.SearchOutlined style={{ fontSize: "1.8rem" }} />}
      placeholder="Search orders here..."
      onChange={onChangeKeyWord}
      className={classes["inputSearch"]}
    />
  );
};

export default React.memo(SearchOrder);
