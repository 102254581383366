import { Rate } from "Components/Lib";
import { i18NextText } from "Utils/I18Next.Util";
import { formatMoment } from "Utils/Moment.Util";
import { _toNumber } from "Utils/Lodash.Util";

import ChangeStatusActive from "./ChangeStatusActive";
import ChangeStatusHomeDisplay from "./ChangeStatusHomeDisplay";

const columnsReview = [
  {
    title: i18NextText("review.column_customer_name"),
    dataIndex: "name",
    width: 150,
    render: (name: any) => name,
  },
  {
    title: i18NextText("review.column_product"),
    dataIndex: "product",
    width: 150,
    render: (product) => product.title,
  },
  {
    title: i18NextText("review.column_date"),
    dataIndex: "created_at",
    width: 150,
    render: (text: any) => formatMoment(text, "MMMM Do YYYY, h:mm a"),
  },
  {
    title: i18NextText("review.column_rate"),
    dataIndex: "rate",
    width: 100,
    render: (rate: number) => <Rate disabled value={_toNumber(rate)} />,
  },
  {
    title: i18NextText("review.column_active"),
    dataIndex: "status",
    width: 80,
    align: "center",

    render: (status: string, record) => (
      <ChangeStatusActive status={status} id_review={record.id} />
    ),
  },
  {
    title: i18NextText("review.column_display_home"),
    dataIndex: "status",
    width: 80,
    align: "center",
    render: (status: string, record) => {
      return <ChangeStatusHomeDisplay status={status} id_review={record.id} />;
    },
  },
];

export default columnsReview;
