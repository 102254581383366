import { action, thunk } from "easy-peasy";
import {
  deleteOneCustomer,
  getOneCustomer,
  updateCustomerInfo,
} from "Services/Customers.Services";

import { CustomerDetailModel, ICustomersState } from "./Type";

export const INITIAL_AUTH_STATE: ICustomersState = {
  loading: true,
  data: {
    id: 0,
    first_name: "",
    last_name: "",
    gender: "",
    email: "",
    address: "",
    active: false,
  },
};

const detail: CustomerDetailModel = {
  ...INITIAL_AUTH_STATE,

  setLoading: action((state, payload: boolean): void => {
    state.loading = payload;
  }),

  saveData: action((state, { data }): void => {
    state.data = data;
    state.loading = false;
  }),

  getOneCustomer: thunk(async (actions, { customerId }, { getState }) => {
    const state = getState();
    if (!state.loading) actions.setLoading(true);
    const { data, success } = await getOneCustomer(customerId);
    if (success) {
      actions.saveData({
        data: data.user,
      });
    } else {
      actions.setLoading(false);
    }
    return { success };
  }),

  updateCustomerInfo: thunk(
    async (actions, { customerId, values }, { getState }) => {
      const state = getState();
      if (!state.loading) actions.setLoading(true);
      const { data, success } = await updateCustomerInfo(customerId, values);
      if (success) {
        actions.saveData({
          data: data,
        });
      } else {
        actions.setLoading(false);
      }
      return { success };
    }
  ),

  deleteCustomer: thunk(async (actions, { customerId }, { getState }) => {
    const state = getState();
    if (!state.loading) actions.setLoading(true);
    const { data, success } = await deleteOneCustomer(customerId);
    if (success) {
      actions.saveData({
        data: data,
      });
    } else {
      actions.setLoading(false);
    }
    return { success };
  }),
};

export default detail;
