import { useState } from "react";
import { FormikHelpers } from "formik";
import { PlusOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd/es/upload";
import type { RcFile, UploadFile } from "antd/es/upload/interface";

import { Modal, Upload } from "Components/Lib";
import { message } from "Utils/Notification.Util";
import { IVariantsCreate } from "Types/Product";

import "./UploadImage.scss";

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
interface IUploadImagesComponent {
  images_attributes: any[];
  setFieldValue: FormikHelpers<IVariantsCreate>["setFieldValue"];
}
const UploadImagesComponent = ({
  images_attributes,
  setFieldValue,
}: IUploadImagesComponent) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  const handleCancel = () => setPreviewOpen(false);

  const beforeUpload = (file: any) => {
    const isJpgPngJpeg =
      file.type === "image/jpeg" ||
      file.type === "image/jpg" ||
      file.type === "image/png";
    if (!isJpgPngJpeg) {
      message.error("Just accept PNG, JPG or JPEG file");
    }

    const isSmallerThan15M = file.size / 1024 / 1024 < 15;
    if (!isSmallerThan15M) {
      message.error("Image must smaller than 8MB!");
    }

    if (isJpgPngJpeg && isSmallerThan15M) {
      file.status = "done";
    }

    file.isValid = isJpgPngJpeg && isSmallerThan15M;
    return isJpgPngJpeg;
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    const filteredFiles = newFileList.filter((file: any) => file.isValid);
    setFieldValue("images_attributes", filteredFiles);
  };

  const uploadImage = async (options: any) => {
    const { onSuccess, file, onProgress } = options;
    await onProgress({ percent: 100 });
    onSuccess(file);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  return (
    <>
      <Upload
        listType="picture-card"
        accept="image/*"
        fileList={images_attributes}
        beforeUpload={beforeUpload}
        onPreview={handlePreview}
        onChange={handleChange}
        multiple={true}
        type="select"
        customRequest={uploadImage}
        className="upload_image"
      >
        {images_attributes.length >= 8 ? null : uploadButton}
      </Upload>
      <Modal open={previewOpen} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};

export default UploadImagesComponent;
