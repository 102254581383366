import ButtonComponent from "CoreUI/Components/Common/Button";
import TagComponent from "CoreUI/Components/Common/Tag";
import { useStoreActions, useStoreState } from "Store";
import { i18NextText } from "Utils/I18Next.Util";
import { _cloneDeep, _upperFirst } from "Utils/Lodash.Util";
import { formatMoment } from "Utils/Moment.Util";
import { notification } from "Utils/Notification.Util";

import classes from "./HeaderPageOrder.module.scss";

const HeaderPageOrder = () => {
  const data = useStoreState((state) => state.orders.detailOrder.data);
  const loadingChangeStatus = useStoreState(
    (state) => state.orders.detailOrder.loadingChangeStatus
  );

  const changeStatusOrder = useStoreActions(
    (action) => action.orders.detailOrder.changeStatusOrder
  );
  const { id, created_at, status } = data;

  const handleCancelOrder = async () => {
    const dataOrder = _cloneDeep(data);
    dataOrder.status = "cancelled";
    const response = await changeStatusOrder({ dataOrder });
    if (response.success) {
      location.reload();
    } else {
      notification.error({
        message: "",
        description: i18NextText("error.notification_error"),
      });
    }
  };
  return (
    <div
      className="flex justify-between"
      style={{ padding: "var(--sp_lv_4) var(--sp_lv_6)" }}
    >
      <div>
        <div className="flex items-center gap-5 text-4xl">
          <div>
            {i18NextText("order.content_number")} {id}{" "}
          </div>
          <TagComponent
            color={`${
              status == "cancelled"
                ? "gold"
                : status == "completed"
                ? "#87d068"
                : "#2db7f5"
            }`}
          >
            {_upperFirst(status)}
          </TagComponent>
        </div>
        <div>{formatMoment(created_at, "MMMM Do YYYY, h:mm a")}</div>
      </div>
      <div className={classes["btn_cancel"]}>
        <ButtonComponent
          className={`${
            (status == "cancelled" || status == "completed") && "opacity-50"
          }`}
          style={{ color: "var(--red_5)" }}
          disabled={
            status == "cancelled" || status == "completed" ? true : false
          }
          onClick={handleCancelOrder}
          loading={loadingChangeStatus}
        >
          {i18NextText("order.btn_cancel")}
        </ButtonComponent>
      </div>
    </div>
  );
};

export default HeaderPageOrder;
