import list from "./List";
import detail from "./Detail";
import { ListProductModel } from "./List/Type";
import { ProductDetailModel } from "./Detail/Type";

export interface ProductsModel {
  list: ListProductModel;
  detail: ProductDetailModel;
}

const products = { list, detail };

export default products;
