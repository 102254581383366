import { Input, InputProps, InputRef } from "antd";
import React from "react";

const InputField = React.forwardRef<InputRef, InputProps>(
  ({ ...rest }, ref) => {
    return <Input {...rest} ref={ref} />;
  }
);

export const { TextArea } = Input;
export default InputField;
