import React, { useState } from "react";

import Popover from "CoreUI/Components/Common/Popover";
import { i18NextText } from "Utils/I18Next.Util";
import { Icons } from "Components/Lib";
import ButtonComponent from "CoreUI/Components/Common/Button";
import FilterContentComponent from "./FilterContent.Component";

const FilterButton = () => {
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = () => {
    setVisible(!visible);
  };

  return (
    <Popover
      content={<FilterContentComponent />}
      trigger="click"
      placement="bottom"
      open={visible}
      onOpenChange={handleVisibleChange}
      // getPopupContainer={(trigger) => trigger.parentNode}
    >
      <ButtonComponent icon={<Icons.FilterOutlined />} type="primary">
        {i18NextText("product.btn_filter")}
      </ButtonComponent>
    </Popover>
  );
};

export default FilterButton;
