import { useNavigate } from "react-router-dom";

import { Button, Icons } from "Components/Lib";
import { i18NextText } from "Utils/I18Next.Util";
import { generatePath } from "Utils/GeneratePath.Util";
import { PATH_NAME } from "Constants/PathName.Constant";

import classes from "./EditButton.module.scss";

type EditButtonProps = {
  id: string;
};

const EditButton = ({ id }: EditButtonProps) => {
  const navigate = useNavigate();

  const onEdit = () => {
    navigate(
      generatePath(PATH_NAME.BRANDS_DETAIL, {
        brand_id: id,
      })
    );
  };
  return (
    <div className={classes["button_edit"]}>
      <Button onClick={onEdit} size="small" style={{ color: "var(--blue_5)" }}>
        <Icons.EditOutlined /> <p>{i18NextText("option.btn_edit")}</p>
      </Button>
    </div>
  );
};

export default EditButton;
