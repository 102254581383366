import { Empty } from "antd";

import rocketIcon from "Assets/Images/Icons/rocket.png";

import classes from "./EmptyData.module.scss";
import { IEmptyDataProps } from "../types";
import IconCircle from "../IconCircle";

const defaultIconCircleProps = {
  iconSrc: rocketIcon,
  size: 80,
};

const EmptyData: React.FC<IEmptyDataProps> = ({
  iconCircleProps = defaultIconCircleProps,
  description = "No data",
  ...rest
}) => {
  return (
    <Empty
      description={
        description ? (
          <p className={classes["empty-data-title"]}>{description}</p>
        ) : (
          false
        )
      }
      image={<IconCircle {...iconCircleProps} />}
      {...rest}
    />
  );
};

export default EmptyData;
