import { useState, useEffect, memo } from "react";
import { useNavigate } from "react-router-dom";
import type { MenuProps } from "antd";
import classNames from "classnames";

import { useStoreActions } from "Store";
import { Menu, Layout, Icons } from "Components/Lib";
import { useModalContext } from "Context/Modal";
import { SIDE_BAR_SIZE } from "Constants/Common.Constant";
import MENU_CONSTANT from "Constants/Menu.Constant";
import { i18NextText } from "Utils/I18Next.Util";
import { PATH_NAME } from "Constants/PathName.Constant";
import ModalConfirm from "Components/Own/ModelConfirm";

import classes from "./Sider.module.scss";

const SideBarLeft = () => {
  const { updateModal, hideModal } = useModalContext();

  const logout = useStoreActions((action) => action.auth.logout);

  const [activeKey, setActiveKey] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const key = location.pathname.split("/")[1];
    const item = MENU_CONSTANT.find((menu) => menu.key === key);
    if (item) {
      setActiveKey(item.key.toString());
    } else {
      setActiveKey(MENU_CONSTANT[0].key.toString());
    }
  }, []);

  const onClick: MenuProps["onClick"] = (e) => {
    const item = MENU_CONSTANT.find((menu) => menu.key === e.key);
    if (item) {
      setActiveKey(item.key.toString());
      navigate(item.path);
    }
  };

  const handleLogout = () => {
    logout();
    navigate(PATH_NAME.SIGN_IN);
    hideModal();
  };

  const showConfirm = () => {
    updateModal(
      <ModalConfirm
        title={i18NextText("auth.logout_confirm_title")}
        onClick={handleLogout}
      />,
      {
        showTitle: true,
        width: 400,
        height: "auto",
      }
    );
  };

  return (
    <Layout.Sider
      className={classes["sider-container"]}
      width={`${SIDE_BAR_SIZE}`}
    >
      <div className="h-full flex flex-col ">
        <div className="flex flex-col items-center">
          <Icons.TeamOutlined
            className="border border-[#332828] border-solid rounded-[50%] p-2 bg-white"
            style={{
              fontSize: "var(--fh_2xl)",
              marginBottom: "var(--sp_lv_1)",
            }}
          />

          <h3>SofaXinh</h3>
          <span>Admin</span>
          <a onClick={showConfirm} className="flex items-center">
            {i18NextText("auth.logout")}
            <Icons.LogoutOutlined
              style={{ fontSize: "1.6rem", marginLeft: "var(--sp_lv_1)" }}
            />
          </a>
        </div>

        <div
          className={classNames(
            "flex-1 overflow-y-auto w-full",
            classes["menu-wrapper"]
          )}
        >
          <Menu
            selectedKeys={[activeKey]}
            mode="vertical"
            items={MENU_CONSTANT}
            onClick={onClick}
          />
        </div>

        <h2 className="text-center">AdminSite</h2>
        <h6 className="text-center">
          {i18NextText("common.copyright", { year: new Date().getFullYear() })}
        </h6>
      </div>
    </Layout.Sider>
  );
};

export default memo(SideBarLeft);
