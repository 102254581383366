import { memo } from "react";

import { i18NextText } from "Utils/I18Next.Util";
import { useStoreActions, useStoreState } from "Store";
import InputSearchComponent from "Components/Own/InputSearchComponent";

const TitleComponent = () => {
  const pagination = useStoreState(
    (state) => state.brands.listBrands.pagination
  );

  const setKeyword = useStoreActions(
    (action) => action.brands.listBrands.setKeyword
  );
  const getListBrands = useStoreActions(
    (action) => action.brands.listBrands.getBrandsList
  );
  return (
    <div className="flex justify-between items-center">
      <h4>{i18NextText("brand.title_table")}</h4>

      <InputSearchComponent
        getDataList={getListBrands}
        setKeyword={setKeyword}
        value={pagination.keyword}
        placeholder="Search brands here..."
      />
    </div>
  );
};

export default memo(TitleComponent);
