import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useStoreActions } from "Store";
import { Icons } from "Components/Lib";
import { PATH_NAME } from "Constants/PathName.Constant";
import { i18NextText } from "Utils/I18Next.Util";
import ButtonComponent from "CoreUI/Components/Common/Button";
import { notification } from "Utils/Notification.Util";
import { generatePath } from "Utils/GeneratePath.Util";
import TableCategoriesList from "./TableCategoriesList";

const CategoriesPage = () => {
  const navigate = useNavigate();

  const getCategoriesList = useStoreActions(
    (action) => action.categories.listCategories.getCategoriesList
  );

  useEffect(() => {
    const fetchdata = async () => {
      const response = await getCategoriesList({});
      if (!response.success) {
        notification.error({
          message: "",
          description: i18NextText("error.notification_error"),
        });
      }
    };
    fetchdata();
  }, [getCategoriesList]);

  const handleOnClickAddCategory = () => {
    navigate(generatePath(PATH_NAME.CATEGORIES_DETAIL, { category_id: "new" }));
  };

  return (
    <>
      <div
        className="flex justify-between"
        style={{ padding: "var(--sp_lv_3) var(--sp_lv_3) 0" }}
      >
        <h1 className="font-semibold">{i18NextText("category.categories")}</h1>
        <div className="flex gap-4">
          <ButtonComponent
            type="primary"
            icon={<Icons.PlusOutlined />}
            onClick={handleOnClickAddCategory}
          >
            {i18NextText("category.btn_add_category")}
          </ButtonComponent>
        </div>
      </div>
      <div>
        <TableCategoriesList />
      </div>
    </>
  );
};

export default CategoriesPage;
