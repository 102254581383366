import { Button, ButtonProps } from "antd";
import classnames from "classnames";

const ButtonComponent: React.FC<ButtonProps> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <Button {...rest} className={classnames(className)}>
      {children}
    </Button>
  );
};

export default ButtonComponent;
