import api from "Configs/Axios.Config";

import { SERVICES } from "Constants/Service.Constant";
import normalizeError from "Utils/NomalizeError.Util";

interface ILogin {
  email: string;
  password: string;
}

export const getTokenAdmin = async (params: ILogin) => {
  try {
    const response = await api.post(SERVICES.SIGN_IN, params);
    return {
      success: true,
      token: response.data.token,
      error: null,
    };
  } catch (errors) {
    return {
      success: false,
      token: "",
      error: normalizeError(errors),
    };
  }
};

export const getNewPassword = async ({ email }: { email: string }) => {
  try {
    const response = await api.post(SERVICES.FORGOT_PASSWORD, { email });
    return {
      success: true,
      error: null,
      message: response.data.message,
    };
  } catch (errors) {
    return {
      success: false,
      error: normalizeError(errors),
    };
  }
};
