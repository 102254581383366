const review = {
  title_table: "Review List",
  column_customer_name: "CUSTOMER NAME",
  column_product: "PRODUCT",
  column_date: "REVIEW DATE",
  column_rate: "RATE",
  column_active: "ACTIVE",
  column_display_home: "DISPLAY HOME",
};

export default review;
