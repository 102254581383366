import { useStoreState } from "Store";
import DataTable from "CoreUI/Components/Common/DataTable";
import { i18NextText } from "Utils/I18Next.Util";
import { formatCurrencyVN } from "Utils/Common.Util";
import { IOrderDetails } from "Types/Order";
import ImageComponent from "CoreUI/Components/Common/Image";

const TableInfoProduct = () => {
  const data = useStoreState(
    (state) => state.orders.detailOrder.data.order_details_attributes
  );

  const loading = useStoreState((state) => state.orders.detailOrder.loading);

  const columns = [
    {
      title: i18NextText("order.column_product_name"),
      dataIndex: "images_attributes",
      width: 250,
      fixed: "left",
      render: (text, record: IOrderDetails) => {
        return (
          <div className="flex justify-start items-center gap-5">
            <ImageComponent
              src={record.images_attributes[0]?.public_url || ""}
              style={{
                borderRadius: "var(--br_DEFAULT)",
                width: "var(--sp_lv_10)",
                height: "var(--sp_lv_10)",
                minWidth: "var(--sp_lv_10)",
                marginRight: 20,
              }}
              preview={false}
            />
            <p>{record.product_name}</p>
          </div>
        );
      },
    },
    {
      title: `${i18NextText("order.column_variant")}`,
      dataIndex: "variant_name",
      width: 100,
      render: (text: any) => text,
    },
    {
      title: `${i18NextText("order.column_sku")}`,
      dataIndex: "sku",
      width: 100,
      render: (text: any) => text,
    },
    {
      title: `${i18NextText("order.column_quantity")}`,
      dataIndex: "quantity",
      width: 80,
      render: (text: any) => text,
      align: "center",
    },
    {
      title: `${i18NextText("order.column_unit_price")}`,
      dataIndex: "price",
      width: 100,
      render: (text: any) => formatCurrencyVN(text),
      align: "right",
    },

    {
      title: `${i18NextText("order.column_discount")}`,
      dataIndex: "discount",
      width: 100,
      render: (text: any) => formatCurrencyVN(text),
      align: "right",
    },
    {
      title: `${i18NextText("order.column_order_total")}`,
      dataIndex: "",
      width: 120,
      render: (text: IOrderDetails) =>
        formatCurrencyVN(text.price * text.quantity),
      align: "right",
    },
  ];

  return (
    <DataTable
      data={data}
      columns={columns}
      rowKey={(record: any) => {
        return record.id;
      }}
      loading={loading}
      pagination={{
        current: 0,
        total: 0,
        pageSize: 0,
      }}
    />
  );
};

export default TableInfoProduct;
