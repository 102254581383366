import Image from "CoreUI/Components/Common/Image";
import Tag from "CoreUI/Components/Common/Tag";
import { PRODUCT_STATUS_ENUM } from "Types/Product";
import { formatCurrency } from "Utils/Common.Util";
import { i18NextText } from "Utils/I18Next.Util";
import { _toLower, _upperFirst } from "Utils/Lodash.Util";
import OptionComponent from "../Option";

export const columns = [
  {
    title: "ID",
    dataIndex: "id",
    width: 80,
    render: (text: any) => text,
    align: "center",
  },
  {
    title: i18NextText("product.column_name"),
    dataIndex: "images",
    width: 250,
    render: (text, record) => {
      return (
        <div className="flex justify-start items-center gap-5">
          <Image
            src={record.images_attributes[0]?.public_url || ""}
            style={{
              borderRadius: "var(--br_DEFAULT)",
              width: "var(--sp_lv_14)",
              height: "var(--sp_lv_14)",
              minWidth: "var(--sp_lv_14)",
            }}
            preview={false}
          />
          <span>{record.title}</span>
        </div>
      );
    },
  },

  {
    title: i18NextText("product.column_category"),
    dataIndex: "category",
    width: 120,
    render: (text: any) => _upperFirst(_toLower(text?.name)),
  },

  {
    title: i18NextText("product.column_quantity"),
    dataIndex: "quantity",
    width: 100,
    align: "center",
  },
  {
    title: i18NextText("product.column_price"),
    dataIndex: "price",
    render: (text: any) => formatCurrency(text, "VND", "vi-VN"),
    width: 120,
    align: "right",
  },
  {
    title: i18NextText("product.column_status"),
    dataIndex: "status",
    render: (status: PRODUCT_STATUS_ENUM) => (
      <Tag
        color={
          status === "active"
            ? "var(--blue_4)"
            : status === "draft"
            ? "var(--yellow_4)"
            : "var(--red_4)"
        }
      >
        {_upperFirst(status)}
      </Tag>
    ),
    width: 120,
    align: "center",
  },
  {
    title: "",
    dataIndex: "",
    render: (_text, record) => {
      return <OptionComponent id={record.id} />;
    },
    width: 50,
  },
];
