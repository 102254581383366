export const PATH_NAME = {
  // PUBLIC
  SIGN_IN: "/sign-in",
  FORGOT_PASSWORD: "/forgot-pass",
  FORBIDDEN: "/forbidden",

  // PRIVATE
  HOME: "/",
  CUSTOMERS: "/customers",
  UPDATE_CUSTOMERS_INFO: ":customer_id/edit",

  // PRODUCT
  PRODUCTS: "/products",
  PRODUCT_DETAIL: ":product_id",
  VARIANT_DETAIL: ":variant_id",

  //MERCHANT
  MERCHANT: "/merchants",
  CREATE_MERCHANT: "create",
  EDIT_MERCHANT: ":merchant_id/edit",

  // CATEGORY
  CATEGORIES: "/categories",
  CATEGORIES_DETAIL: ":category_id",

  // BRAND
  BRANDS: "/brands",
  BRANDS_DETAIL: ":brand_id",

  // ORDER
  ORDERS: "/orders",
  ORDER_DETAIL: ":order_id",

  //
  REVIEWS: "/reviews",
};
