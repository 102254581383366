import { useStoreState, useStoreActions } from "Store";
import DataTable from "CoreUI/Components/Common/DataTable";
import { i18NextText } from "Utils/I18Next.Util";
import { notification } from "Utils/Notification.Util";

import TableTitle from "./TableTitle";
import classes from "./TableReviews.module.scss";
import columnsReview from "./ColumnReviewTable";

const TableReviews = () => {
  const data = useStoreState((state) => state.reviews.reviewsList.data);
  const pagination = useStoreState(
    (state) => state.reviews.reviewsList.pagination
  );
  const loading = useStoreState((state) => state.reviews.reviewsList.loading);

  const getReviewsList = useStoreActions(
    (action) => action.reviews.reviewsList.getReviewsList
  );

  const onPageChange = async (tablePagination) => {
    const response = await getReviewsList({ page: tablePagination.current });
    if (!response.success) {
      notification.error({
        message: "",
        description: i18NextText("error.notification_error"),
      });
    }
  };

  return (
    <div style={{ padding: "var(--sp_lv_2) var(--sp_lv_3) var(--sp_lv_8)" }}>
      <DataTable
        data={data}
        columns={columnsReview}
        rowKey={(record: any) => {
          return record.id;
        }}
        loading={loading}
        pagination={{
          current: pagination.page,
          pageSize: pagination.per_page,
          total: pagination.total_records,
        }}
        onChange={onPageChange}
        title={() => <TableTitle />}
        customStyle={classes["table-container"]}
      />
    </div>
  );
};

export default TableReviews;
