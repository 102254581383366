const auth = {
  //SIGN IN
  sign_In: "Sign In",
  signIn_label_email: "Email",
  signIn_label_pass: "Password",
  btn_login: "Log In",
  signIn_validator_invalid_email: "Invalid email format.",
  signIn_validator_email: "Email is required",
  signIn_validator_password: "Password is required",
  signIn_validator_min_password:
    "Password is too short - should be 8 chars minimum",
  signIn_place_holder_email: "Enter your email",
  signIn_place_holder_password: "Enter your password",

  //FORGOT PASSWORD
  getPass: "Forgot Password",
  getPass_label_form: "Enter your email to get new password",
  btn_submit: "Submit",
  getPass_place_holder_email: "Enter your email",
  getPass_validator_invalid_email: "Invalid email format.",
  getPass_validator_email: "Email is required",
  getPass_success:
    "A new password was sent to your email address. Please check your email.",

  //LOGOUT
  logout_confirm_title: "Are you sure to log out?",
  logout: "Logout",
  logout_confirm_cancel: "Cancel",
  logout_confirm_yes: "Yes",
};

export default auth;
