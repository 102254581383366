import InputSearchComponent from "Components/Own/InputSearchComponent";
import { useStoreActions, useStoreState } from "Store";
import { i18NextText } from "Utils/I18Next.Util";
import { memo } from "react";

const TitleComponent = () => {
  const pagination = useStoreState(
    (state) => state.categories.listCategories.pagination
  );

  const setKeyword = useStoreActions(
    (action) => action.categories.listCategories.setKeyword
  );
  const getCategoriesList = useStoreActions(
    (action) => action.categories.listCategories.getCategoriesList
  );

  return (
    <div className="flex justify-between items-center">
      <h4>{i18NextText("category.title_table")}</h4>

      <InputSearchComponent
        getDataList={getCategoriesList}
        setKeyword={setKeyword}
        value={pagination.keyword}
        placeholder="Search categories here...."
      />
    </div>
  );
};

export default memo(TitleComponent);
