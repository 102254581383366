import { useState } from "react";

import SelectComponent from "CoreUI/Components/Common/Select";
import { IRecordCategory } from "Types/Category";
import { notification } from "Utils/Notification.Util";
import { useStoreActions } from "Store";
import { i18NextText } from "Utils/I18Next.Util";
import { _toString } from "Utils/Lodash.Util";

const SelectPriority = ({
  detailCategory,
}: {
  detailCategory: IRecordCategory;
}) => {
  const [value, setValue] = useState(detailCategory.priority);

  const updateCategory = useStoreActions(
    (action) => action.categories.detailCategory.updateCategory
  );

  const options = [{ label: "None set", value: null }];
  for (let i = 1; i <= 20; i++) {
    options.push({ label: _toString(i), value: i });
  }

  const handleChange = async (value) => {
    const newValue = { ...detailCategory, priority: value };
    const response = await updateCategory({
      categoryId: detailCategory.id,
      values: newValue,
    });
    if (!response.success) {
      notification.error({
        message: "",
        description: i18NextText("error.notification_error"),
      });
    } else {
      setValue(value);
    }
  };
  return (
    <SelectComponent
      disabled={detailCategory.status === "deactivate"}
      value={value}
      options={options}
      onChange={handleChange}
    />
  );
};

export default SelectPriority;
