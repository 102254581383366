import { Navigate, Outlet, useLocation } from "react-router-dom";

import { PATH_NAME } from "Constants/PathName.Constant";
import { clearItems, getItem } from "Utils/LocalStorage.Util";
import { PrivateLayout } from "Components/Layouts";
import { LOCAL_STORAGE } from "Constants/LocalStorage.Constant";

const PrivateRoute: React.FC = () => {
  const token = getItem(LOCAL_STORAGE.TOKEN);
  const location = useLocation();

  if (!token) {
    clearItems();
    return <Navigate to={PATH_NAME.SIGN_IN} replace />;
  }

  if (location.pathname === PATH_NAME.HOME) {
    return <Navigate to={PATH_NAME.PRODUCTS} replace />;
  }

  return (
    <PrivateLayout>
      <Outlet />
    </PrivateLayout>
  );
};

export default PrivateRoute;
