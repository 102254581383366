import React from "react";
import { ClearOutlined } from "@ant-design/icons";

import { i18NextText } from "Utils/I18Next.Util";
import ButtonComponent from "CoreUI/Components/Common/Button";
import { useStoreActions } from "Store";
import {
  DEFAULT_FILTER_PARAMS,
  DEFAULT_PAGE_PARAMS,
} from "Store/Models/Order/OrdersList";

const ClearFilterButton = () => {
  const getOrdersList = useStoreActions(
    (state) => state.orders.ordersList.getOrdersList
  );

  const handleOnClickClearFilter = () => {
    getOrdersList({
      page: DEFAULT_PAGE_PARAMS.page,
      filter: { ...DEFAULT_FILTER_PARAMS },
    });
  };
  return (
    <ButtonComponent
      icon={<ClearOutlined />}
      onClick={handleOnClickClearFilter}
      type="default"
    >
      {i18NextText("product.btn_filter")}
    </ButtonComponent>
  );
};

export default ClearFilterButton;
