import { Checkbox, CheckboxProps } from "antd";
import classNames from "classnames";

import classes from "./index.module.scss";

const CheckboxComponent: React.FC<CheckboxProps> = ({
  children,
  className,
  ...restprops
}) => {
  return (
    <Checkbox
      className={classNames(classes["custom-checkbox"], className)}
      {...restprops}
    >
      {children}
    </Checkbox>
  );
};

export default CheckboxComponent;
