import { action, thunk } from "easy-peasy";
import {
  createMerchantData,
  deleteOneMerchant,
  getOneMerchantData,
  updateInforMerchant,
} from "Services/Merchants.Services";

import { MerchantDetailModel, IMerchantState } from "./Type";

export const INITIAL_AUTH_STATE: IMerchantState = {
  loading: true,
  data: {
    id: undefined,
    name: "",
    email: "",
    domain: "",
    limit_rate: "",
    active: null,
    plan: "",
  },
};

const detail: MerchantDetailModel = {
  ...INITIAL_AUTH_STATE,

  setLoading: action((state, payload: boolean): void => {
    state.loading = payload;
  }),

  saveData: action((state, { data }): void => {
    state.data = data;
    state.loading = false;
  }),

  getOneMerchant: thunk(async (actions, { merchantId }, { getState }) => {
    const state = getState();
    if (!state.loading) actions.setLoading(true);
    const { data, success } = await getOneMerchantData(merchantId);
    if (success) {
      actions.saveData({
        data: data,
      });
    } else {
      actions.setLoading(false);
    }
    return { success };
  }),

  createMerchant: thunk(async (actions, _payload, { getState }) => {
    const state = getState();
    const newMerchant = _payload;
    if (!state.loading) actions.setLoading(true);
    const { data, success } = await createMerchantData(newMerchant);
    if (success) {
      actions.saveData({ data: { ...state.data, ...data } });
    } else {
      actions.setLoading(false);
    }
    return { success };
  }),

  updateInforMerchant: thunk(
    async (actions, { merchantId, values }, { getState }) => {
      const state = getState();
      if (!state.loading) actions.setLoading(true);
      const { data, success } = await updateInforMerchant(merchantId, values);
      if (success) {
        actions.saveData({
          data: data,
        });
      } else {
        actions.setLoading(false);
      }
      return { success };
    }
  ),

  deleteMerchant: thunk(async (actions, { merchantId }, { getState }) => {
    const state = getState();
    if (!state.loading) actions.setLoading(true);
    const { data, success } = await deleteOneMerchant(merchantId);
    if (success) {
      actions.saveData({
        data: data,
      });
    } else {
      actions.setLoading(false);
    }
    return { success };
  }),
};

export default detail;
