import { FormikHelpers } from "formik";
import { IImageAttributes } from "Types/Category";
import { IDetailValues } from "Types/Product";
import { i18NextText } from "Utils/I18Next.Util";
import UploadImagesComponent from "./UploadImagesComponent";

interface IVariantImages {
  images_attributes: IImageAttributes[];
  setFieldValue: FormikHelpers<IDetailValues>["setFieldValue"];
}

const VariantImages = ({
  images_attributes,
  setFieldValue,
}: IVariantImages) => {
  return (
    <div
      className="flex flex-col gap-5"
      style={{
        background: "var(--gray_1)",
        borderRadius: "var(--br_lg)",
      }}
    >
      <h2>{i18NextText("product.images_product")}</h2>
      <div
        style={{
          minHeight: "150px",
          border: "1px dashed var(--gray_7)",
          borderRadius: "1rem",
          padding: "var(--sp_lv_2)",
        }}
        className="flex flex-col justify-center items-center gap-2"
      >
        <UploadImagesComponent
          images_attributes={images_attributes}
          setFieldValue={setFieldValue}
        />
        <div>
          <p>{i18NextText("product.images_description")}</p>
        </div>
      </div>
    </div>
  );
};

export default VariantImages;
