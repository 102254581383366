import React, { useEffect } from "react";

import { useStoreActions } from "Store";
import { Icons } from "Components/Lib";
import { i18NextText } from "Utils/I18Next.Util";
import ButtonComponent from "CoreUI/Components/Common/Button";
import { notification } from "Utils/Notification.Util";

import TableCustomersListComponent from "./TableCustomersList.Component";

const CustomersPage = () => {
  const getData = useStoreActions(
    (state) => state.customers.list.getCustomersList
  );

  useEffect(() => {
    const fetchdata = async () => {
      const response = await getData({});
      if (!response.success) {
        notification.error({
          message: "",
          description: i18NextText("error.notification_error"),
        });
      }
    };
    fetchdata();
  }, [getData]);

  return (
    <>
      <div
        className="flex justify-between"
        style={{ padding: "var(--sp_lv_8) var(--sp_lv_8) 0" }}
      >
        <h1 className="font-semibold">{i18NextText("customers.customers")}</h1>
        <div className="flex gap-4">
          <ButtonComponent icon={<Icons.FilterOutlined />}>
            {i18NextText("customers.btn_filter")}
          </ButtonComponent>
        </div>
      </div>
      <div>
        <TableCustomersListComponent />
      </div>
    </>
  );
};

export default CustomersPage;
